import backgroundImage from "../../assets/schemes/image-9.png";


const TPS9 = {
	id: 9,
	size: {width: 2891, height: 1774, minHeight: 800},
	image: backgroundImage,
	isExist: false,
	lines: [],
	switches: [],
	activeLines: []
}

export default TPS9
