import {TextOnScheme} from "../TextOnScheme/TextOnScheme"


export class AngledTextOnScheme extends TextOnScheme {
	#angle

	constructor(text, left, top, fontSize, angle = 0) {
		super(text, left, top, fontSize)
		this.angle = angle
	}

	generate() {
		super.generate()
		const container = this.DOMContainer
		container.style.transformOrigin = 'top right'
		container.style.transform = `rotate(${this.#angle}deg)`
	}

	set angle(value) {
		if (-360 < value < 360) {
			return this.#angle = value
		}
		throw new Error('Incorrect angle!')
	}
}
