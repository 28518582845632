import React, {useEffect} from 'react';
import classes from './App.module.css';
import Sidebar from './components/sidebar/Sidebar';
import Navbar from './components/navbar/Navbar';
import Loader from './components/UI/loader/Loader';
import {useFunctionInterval} from './hooks/useFunctionInterval';
import {useActions} from './hooks/useActions';
import {useRoutes} from './routes/AppRouter';
import {useAuth} from './hooks/useAuth';
import {useHttp} from './hooks/useHttp';
import {useSelector} from 'react-redux';
import {background} from "./config/default";
import {useAlarm} from "./hooks/useAlarm";


function App() {
	const {get} = useHttp()
	const {login, logout, userData, ready, isAdmin} = useAuth()
	const {getAlarmInfo} = useAlarm()
	const isAuthorization = !!userData
	const routes = useRoutes(isAuthorization)
	const {show} = useSelector(store => store.sidebar)
	const {fetchTpsList, setAuthStore} = useActions()

	useEffect(() => {
		setAuthStore({login, logout, userData, isAdmin})
		// eslint-disable-next-line
	}, [login, logout, userData, isAdmin])

	useFunctionInterval(async () => {
		if (isAuthorization) {
			await get('/api/user/set-online')
		}
	}, 4000, [isAuthorization])

	useFunctionInterval(async () => {
		if (isAuthorization) {
			await getAlarmInfo()
		}
	}, 1000, [isAuthorization])

	useEffect(() => {
		if (isAuthorization) {
			fetchTpsList()
		}
		// eslint-disable-next-line
	}, [isAuthorization])

	if (!ready) return <Loader/>
	if (!isAuthorization) return routes

	return (
		<div className={classes.container}>
			<Sidebar show={show}/>
			<div className={classes.wrapper}>
				<Navbar show={show}/>
				<div className={classes.content} style={{background}}>
					{routes}
				</div>
			</div>
		</div>
	)
}

export default App;
