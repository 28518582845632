import {Route, Routes, Navigate} from 'react-router-dom'
import {routes, defaultPage} from './routes'
import LoginPage from "../pages/login/LoginPage";
import ErrorPage from "../pages/error/ErrorPage";


export const useRoutes = (isAuth) => {
	if (isAuth) {
		return (
			<Routes>
				<Route path={'/'} element={defaultPage} />
				{
					routes.map((item) =>
						<Route key={item.id} path={item.path} element={item.element} />
					)
				}
				<Route path={'*'} element={<Navigate to='/' />} />
			</Routes>
		)
	} else {
		return (
			<Routes>
				<Route path={'/error/:error'} element={<ErrorPage/>} />
				<Route path={'*'} element={<LoginPage/>} />
			</Routes>
		)
	}
}
