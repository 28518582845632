import {useNavigate} from 'react-router-dom';
import {useActions} from './useActions';


export const useOpenTps = () => {
	const {setActiveTps} = useActions()
	const navigate = useNavigate()
	return {
		openTps: (id) => {
			setActiveTps(id)
			navigate(`/tps/${id}`)
		}
	}
}
