import {time} from "../config/default";
import {isInteger, isString} from "./utils";


const TIME_FORMAT = time.format
const TIME_ZONE = time.zone

function setFormat(dateTime) {
	let newDateTime = ''
	for (let i = 0; i < TIME_FORMAT.length; i++) {
		let letter = TIME_FORMAT[i]
		switch (letter) {
			case 'D':
				letter = dateTime.getUTCDate()
				break
			case 'M':
				letter = dateTime.getUTCMonth() + 1
				break
			case 'Y':
				letter = dateTime.getUTCFullYear()
				break
			case 'H':
				letter = dateTime.getUTCHours()
				break
			case 'm':
				letter = dateTime.getUTCMinutes()
				break
			case 's':
				letter = dateTime.getUTCSeconds()
				break
			case 'S':
				letter = dateTime.getUTCMilliseconds()
				if (letter < 100) {
					letter = '0' + letter
				}
				if (letter < 10) {
					letter = '0' + letter
				}
				break
		}
		if (typeof letter === 'number' && letter < 10) {
			letter = '0' + letter
		}
		newDateTime += letter
	}
	return newDateTime
}

function setZone(dateTime) {
	const zone = Number(TIME_ZONE.slice(1))
	if (isInteger(zone)) {
		if (TIME_ZONE[0] === '+') {
			dateTime.setUTCHours(dateTime.getUTCHours() + zone)
		} else if (TIME_ZONE[0] === '-') {
			dateTime.setUTCHours(dateTime.getUTCHours() - zone)
		}
	}
	return dateTime
}

const dateTime = {
	now() {
		const time = new Date()
		return setFormat(setZone(time))
	},

	create(value) {
		const time = new Date(value)
		if (isString(time)) {
			console.error('Invalid time format: ' + time)
			return ''
		}
		return setFormat(setZone(time))
	}
}

export default dateTime
