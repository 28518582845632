import React, {useEffect, useRef} from 'react';
import classes from './TpsPage.module.css';
import Logbar from '../../components/logbar/Logbar'
import {useActions} from '../../hooks/useActions';
import {useSelector} from 'react-redux';
import {useScadaLoading} from '../../hooks/scada/useScadaLoading';
import Iconbar from '../../components/TPS/iconbar/Iconbar';
import Scada from '../../components/scada/Scada';
import {useNavigate, useParams} from "react-router-dom";
import {useFunctionInterval} from "../../hooks/useFunctionInterval";
import {type} from "../../utils/utils";


const PAGE_HEIGHT_WITHOUT_SCADA = 110

function TpsPage() {
	const navigate = useNavigate()
	const {id} = useParams()
	const tpsId = parseInt(id)
	const {setActiveTps, fetchProtectionModeState} = useActions()
	const {activeTps} = useSelector(store => store.tps)
	const {activeConfig} = useSelector(store => store.scada)
	const isExist = !!activeConfig?.isExist
	const {scadaLoader, loadingHandler, startLoading} = useScadaLoading('tps-load')
	const iconbarCont = useRef()
	const scadaCont = useRef()

	useFunctionInterval(fetchProtectionModeState.bind(undefined, tpsId), 10000, [tpsId])

	useEffect(() => {
		if (type(tpsId) === 'number') {
			setActiveTps(tpsId)
			fetchProtectionModeState(tpsId)
		}
		// eslint-disable-next-line
	}, [tpsId, activeTps])

	useEffect(() => {
		if (activeConfig && iconbarCont.current) {
			let schemasHeight = Number(activeConfig?.size?.minHeight)
			if (!schemasHeight) return
			schemasHeight += PAGE_HEIGHT_WITHOUT_SCADA
			const mediaQuery = window.matchMedia(`(max-height: ${schemasHeight}px`)
			const handleTabletChange = (media) => {
				try {
					iconbarCont.current.style.boxShadow = media.matches ? '3px 3px 5px 5px rgba(0, 0, 0, 0.1)' : ''
				} catch {}
			}
			mediaQuery.addListener(handleTabletChange)
			handleTabletChange(mediaQuery)
		}
	}, [activeConfig, iconbarCont])

	useEffect(() => {
		return () => {
			setActiveTps(null)
		}
		// eslint-disable-next-line
	}, [])

	if (!activeConfig || !Number.isInteger(tpsId)) {
		return navigate(`/`)
	}

	return (
		<div className={classes.content}>
			<div
				ref={iconbarCont}
				className={classes['iconbar-container']}
			>
				<Iconbar isExist={isExist}/>
			</div>
			<div
				id='scada-container'
				ref={scadaCont}
				className={classes['scada-container']}
			>
				<Scada
					tpsID={activeTps}
					loadingHandler={loadingHandler}
					startLoading={startLoading}
				/>
			</div>
			<Logbar isExist={isExist}/>
			{scadaLoader}
		</div>
	)
}

export default TpsPage;
