import React from 'react';
import Typography from '@mui/material/Typography';


function IconCounter(
	{
		count = 0,
		color = 'black',
		isSmall = false
	}) {
	return (
		<span className='my-count' style={{backgroundColor: color}}>
			<Typography
				sx={isSmall ? {lineHeight: '1rem !important'} : {}}
				variant='subtitle2'
			>
				{count}
			</Typography>
		</span>
	)
}

export default IconCounter;
