import React, {useState} from 'react';
import {useFunctionInterval} from "../../../../hooks/useFunctionInterval";
import TpsIcon from "../icon/TpsIcon";

function AlarmTpsIcon({title, isActive, onIcon1, onIcon2, offIcon, onClick = undefined}) {
	const [onIcon, setOnIcon] = useState({key: 'icon1', value: onIcon1})

	useFunctionInterval(() => {
		if (!isActive) {
			return
		}
		setOnIcon(prev => {
			if (prev.key === 'icon1') {
				return {key: 'icon2', value: onIcon2}
			} else if (prev.key === 'icon2') {
				return {key: 'icon1', value: onIcon1}
			}
		})
	}, 300, [onIcon1, onIcon2, isActive])

	return (
		<TpsIcon title={title} isActive={isActive} onIcon={onIcon.value} offIcon={offIcon} onClick={onClick}/>
	)
}

export default AlarmTpsIcon;
