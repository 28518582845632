import classes from "./DoubleAGRLine.module.css";
import {lineWidth} from "../../../../config/default";
import {ImageLine} from "../ImageLine";
import {AGRLine} from "../AGRLine/AGRLine";
import {TriangleLine} from "../TriangleLine/TriangleLine";
import {round} from "../../../../utils/utils";


export class DoubleAGRLine extends ImageLine {
	#DOM = {}
	#size = {}
	#triangles = []

	constructor(left, top, height, width, position, name, nameCorrection) {
		height = height ?? 165
		width = width ?? 165
		super(left, top, height, width, position, name, nameCorrection)
		this.#size = {height, width}
	}

	#createExtremeElement(height, width) {
		const extreme = document.createElement('div')
		extreme.classList.add(classes.extreme)
		const triangle = new TriangleLine(0, 0, height + (lineWidth * 2), width)
		triangle.generation()
		triangle.container.firstChild.style.borderWidth = 0
		extreme.insertAdjacentElement('beforeend', triangle.container)
		return {extreme, triangle}
	}

	__createElement() {
		const container = document.createElement('div')
		container.classList.add(classes.container)
		const extremeHeight = round(this.#size.height * 0.19, 3)
		const extremeWidth = round(this.#size.width * 0.6, 3)

		const {extreme: extremeUp, triangle: triangleUp} = this.#createExtremeElement(extremeHeight, extremeWidth)
		extremeUp.style.transform = 'scale(1, -1)'

		const center = document.createElement('div')
		center.classList.add(classes.center)
		const agrLeft = new AGRLine()
		const agrRight = new AGRLine()
		agrLeft.generation()
		agrRight.generation()
		agrLeft.container.classList.add(classes['agr-space'])
		center.insertAdjacentElement('beforeend', agrLeft.container)
		center.insertAdjacentElement('beforeend', agrRight.container)

		const {extreme: extremeDown, triangle: triangleDown} = this.#createExtremeElement(extremeHeight, extremeWidth)

		container.insertAdjacentElement('beforeend', extremeUp)
		container.insertAdjacentElement('beforeend', center)
		container.insertAdjacentElement('beforeend', extremeDown)

		this.#DOM = {container, aggregates: [agrLeft, agrRight]}
		this.#triangles = [triangleUp, triangleDown]
		return container
	}

	__setOnState() {
		this.#DOM.aggregates.forEach(item => item.__setOnState())
		this.#triangles.forEach(item => item.__setOnState())
	}

	__setOffState() {
		this.#DOM.aggregates.forEach(item => item.__setOffState())
		this.#triangles.forEach(item => item.__setOffState())
	}

	__setRepairState() {
		this.#DOM.aggregates.forEach(item => item.__setRepairState())
		this.#triangles.forEach(item => item.__setRepairState())
	}
}
