import React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {columns} from './UsersTableColumns';


function UsersTable({rows}) {
	return (
		<DataGrid
			rows={rows}
			columns={columns}
			pageSize={50}
			autoHeight
			disableSelectionOnClick
			sx={{background: 'white'}}
		/>
	)
}

export default UsersTable;
