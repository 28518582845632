import React from 'react';
import classes from './ErrorPage.module.css';
import {Typography, Button} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';


function ErrorPage({text = '', onClick}) {
	const navigate = useNavigate()
	let {error} = useParams()
	error ??= ''
	text = typeof text === 'string' ? text : ''


	function toHomePage() {
		if (typeof onClick === 'function') {
			onClick()
		}
		navigate(`/`)
	}

	return (
		<div className={classes.container}>
			<div className={classes.wrapper}>
				<div className={classes.card}>
					<Typography
						variant='h4'
						className='danger'
					>
						Помилка
					</Typography>
					<Typography
						variant='h6'
						className='danger'
						sx={{marginTop: "1rem"}}
					>
						{text || error}
					</Typography>
					<Button
						className={classes.button + ' btn-primary'}
						variant='contained'
						size='large'
						onClick={toHomePage}
					>
						Повернутися на головну
					</Button>
				</div>
			</div>
		</div>
	)
}

export default ErrorPage;
