import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useOpenTps} from '../../../hooks/useOpenTps';
import TpsButton from '../list/button/TpsButton';


function TpsButtonList({list, activeTps}) {
	const {menuHandler} = useSelector(store => store.sidebar)
	const {alarmsArr} = useSelector(store => store.tps)
	const {openTps} = useOpenTps()

	const toTps = useCallback((id) => {
		openTps(id)
		menuHandler()
	}, [menuHandler, openTps])

	return list.map(item => {
		const isAlarm = alarmsArr.hasOwnProperty(item.id)

		return <TpsButton
			key={item.id}
			name={item.name}
			onClick={toTps.bind(undefined, item.id)}
			active={activeTps === item.id}
			alarm={isAlarm}
		/>
	})
}

export default TpsButtonList;
