import React, {useEffect, useRef} from 'react';
import classes from './Navbar.module.css';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import TpsList from '../TPS/list/TpsList';
import Time from '../time/Time';
import UserMenu from '../dropdowns/user-menu/UserMenu';
import {useActions} from '../../hooks/useActions';


function Navbar({show}) {
	const {showSidebar, hideSidebar, setMenuHandler} = useActions()
	const container = useRef(null)
	const menuIcon = useRef(null)

	function hideNavbar() {
		menuIcon.current.classList.remove(classes['menu-show'])
		menuIcon.current.classList.add(classes['menu-hide'])
		container.current.classList.remove(classes['cont-show'])
		container.current.classList.add(classes['cont-hide'])
	}

	function showNavbar() {
		menuIcon.current.classList.add(classes['menu-show'])
		menuIcon.current.classList.remove(classes['menu-hide'])
		container.current.classList.add(classes['cont-show'])
		container.current.classList.remove(classes['cont-hide'])
	}

	const menuHandler = (isActive) => {
		if (isActive) {
			hideSidebar()
			hideNavbar()
		} else {
			showSidebar()
			showNavbar()
		}
	}

	useEffect(() => {
		if (show) {
			setMenuHandler(() => {
				hideSidebar()
				hideNavbar()
			})
		}
		// eslint-disable-next-line
	}, [show])

	return (
		<div ref={container} className={classes.container}>
			<div ref={menuIcon} className={classes['menu-init']}>
				<IconButton sx={{height: '100%'}} onClick={() => menuHandler(show)}>
					<MenuIcon style={{color: 'black'}} />
				</IconButton>
			</div>
			<TpsList />
			<Time />
			<UserMenu />
		</div>
	)
}

export default Navbar;
