import backgroundImage from "../../assets/schemes/image-7.png";


const TPS7 = {
	id: 7,
	size: {width: 2891, height: 1774, minHeight: 800},
	image: backgroundImage,
	isExist: false,
	lines: [],
	switches: [],
	activeLines: []
}

export default TPS7
