import {useEffect, useRef} from 'react';
import {useFunctionInterval} from '../useFunctionInterval';
import {useSelector} from 'react-redux';
import {useActions} from '../useActions';
import {Switch} from '../../components/scada-elements/switches/Switch';
import {useHttp} from '../useHttp';
import _ from 'lodash';


export const useUpdateScadaData = (startLoading = () => {}) => {
	const {controlIcons, activeTps} = useSelector(store => store.tps)
	const {isGenerated} = useSelector(store => store.scada)
	const {setIconData, setLogData, enableRefresh, disableRefresh} = useActions()
	const {get} = useHttp()
	const lastChanges = useRef()
	const lastActiveTps = useRef()

	function setLogbarData(activeTps, value) {
		if (!Array.isArray(value)) {
			value = []
		}
		value = value.reduce((acc, item) => {
			try {
				let {type, switchId, text, state} = item
				if (Switch.ALL.hasOwnProperty(activeTps)
					&& Switch.ALL[activeTps].hasOwnProperty(switchId)
				) {
					text = text.replace('switchId', Switch.ALL[activeTps][switchId]?.logName ?? Switch.ALL[activeTps][switchId]?.name)
				} else if (controlIcons.hasOwnProperty(switchId)) {
					text = text.replace('switchId', controlIcons[switchId].name)
				} else {
					text = text.replace('switchId', '')
				}
				acc.push({type, text, state})
			} catch {}
			return acc
		}, [])
		setLogData(activeTps, value)
	}

	const updateSwitches = (activeTps, data) => {
		if (!Switch.ALL.hasOwnProperty(activeTps)) {
			return
		}
		const {switchData, iconData} = _.cloneDeep(data)
		for (const switchId in switchData) {
			try {
				const state = Number(switchData[switchId])
				switch (state) {
					case 0:
						Switch.ALL[activeTps][switchId].off()
						break
					case 1:
						Switch.ALL[activeTps][switchId].on()
						break
					case 2:
						Switch.ALL[activeTps][switchId].repair()
						break
				}
			} catch {}
		}
		for (const key in Switch.ALL[activeTps]) {
			Switch.ALL[activeTps][key].resetWarning()
			Switch.ALL[activeTps][key].resetError()
		}
		for (const key in iconData.warning) {
			const {switchId, message} = iconData.warning[key]
			Switch.ALL[activeTps][switchId]?.addWarning(message)
		}
		for (const key in iconData.error) {
			const {switchId, message} = iconData.error[key]
			Switch.ALL[activeTps][switchId]?.addError(message)
		}
		setIconData(activeTps, iconData)
	}

	const checkChanges = (oldData, newData) => !_.isEqual(oldData, newData)

	const refreshIconHandler = () => {
		enableRefresh()
		setTimeout(disableRefresh, 750)
	}

	const updateScadaData = async () => {
		if (!isGenerated) return
		const {response} = await get(`/api/tps/all-devices/${activeTps}`)

		if (response && checkChanges(lastChanges.current, response)) {
			const {iconData, logData, switchData} = response
			if (iconData && checkChanges(lastChanges.current?.iconData, iconData)) {
				updateSwitches(activeTps, response)
				refreshIconHandler()
			}
			if (logData && checkChanges(lastChanges.current?.logData, logData)) {
				setLogbarData(activeTps, response.logData)
				refreshIconHandler()
			}
			if (switchData && checkChanges(lastChanges.current?.switchData, switchData)) {
				updateSwitches(activeTps, response)
				startLoading()
				refreshIconHandler()
			}
			lastChanges.current = response
		}
	}

	useFunctionInterval(updateScadaData, 1000, [activeTps, isGenerated])

	useEffect(() => {
		if (lastActiveTps.current !== activeTps) {
			lastActiveTps.current = activeTps
			lastChanges.current = {}
		}
	}, [lastActiveTps, activeTps])
}
