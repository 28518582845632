import classes from "./TriangleLine.module.css";
import {lineWidth, colors} from "../../../../config/default";
import {ImageLine} from "../ImageLine";
import {radiansToDegrees, round} from "../../../../utils/utils";


export class TriangleLine extends ImageLine {
	#DOM = {}
	#size = {}
	#isBlue = false

	constructor(left, top, height, width, position, name, nameCorrection, isBlue) {
		height = height ?? 18
		width = width ?? 18
		super(left, top, height, width, position, name, nameCorrection)
		this.#size = {height, width}
		this.#isBlue = !!isBlue
	}

	__createElement() {
		const container = document.createElement('div')
		container.classList.add(classes.container)

		const up = document.createElement('div')
		up.classList.add(classes.up)
		up.style.borderWidth = lineWidth + 'px'

		const left = document.createElement('div')
		left.classList.add(classes.left)
		left.style.borderWidth = lineWidth + 'px'
		left.style.height = this.#size.width + 'px'

		const right = document.createElement('div')
		right.classList.add(classes.right)
		right.style.borderWidth = lineWidth + 'px'
		right.style.height = this.#size.width + 'px'

		const hypotenuse = round(Math.sqrt((this.#size.width / 2) ** 2 + (this.#size.height - lineWidth) ** 2), 3)
		const angleSin = round(this.#size.width / 2 / hypotenuse, 3)
		const angle = radiansToDegrees(Math.asin(angleSin))
		left.style.transform = `rotate(${-angle}deg)`
		right.style.transform = `rotate(${angle}deg)`

		container.insertAdjacentElement('beforeend', up)
		container.insertAdjacentElement('beforeend', left)
		container.insertAdjacentElement('beforeend', right)
		this.#DOM = {container, lines: {up, left, right}}

		return container
	}

	__setOnState() {
		Object.values(this.#DOM.lines).forEach(item => item.style.borderColor = this.#isBlue ? colors.blue : colors.red)
	}

	__setOffState() {
		Object.values(this.#DOM.lines).forEach(item => item.style.borderColor = colors.green)
	}

	__setRepairState() {
		Object.values(this.#DOM.lines).forEach(item => item.style.borderColor = colors.black)
	}

	get container () {
		return this.#DOM.container
	}
}
