import {useState} from 'react'
import {Typography} from '@mui/material';
import Loader from '../components/UI/loader/Loader';


export const useLoading = (color = 'white', text = '') => {
	const [loading, setLoading] = useState(true)
	const style = {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '100vh',
		color,
	}
	const loader = loading
		? <div style={style}>
			<Loader color={color}/>
			<Typography variant={"h6"}>{text}</Typography>
		</div>
		: false
	return {loading, setLoading, loader}
}
