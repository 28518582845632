import {round} from "../../../../utils/utils";


export class Container {
	static CONTAINER = null
	static SIZE = null
	#internalElements = []
	#DOMContainer
	#size = {}
	#position
	#height
	#width
	#left
	#top
	#id

	constructor(id, left, top, height, width, position) {
		if (!id || (typeof id !== 'number' && typeof id !== 'string') || id === '') {
			throw new Error(`ID is not a number or string!`)
		}
		this.#id = id
		this.#left = left ?? 0
		this.#top = top ?? 0
		this.#height = height ?? 0
		this.#width = width ?? 0
		this.position = position ?? 'v'
	}

	generate() {
		if (!Container.SIZE || !Container.CONTAINER) {
			throw new Error('Class has no size or container!')
		}
		const indentLeft = round(this.#left * 100 / Container.SIZE.width, 3)
		const indentTop = round(this.#top * 100 / Container.SIZE.height, 3)
		const height = round(this.#height * 100 / Container.SIZE.height, 3)
		const width = round(this.#width * 100 / Container.SIZE.width, 3)
		this.#size = {heightInPercent: height, widthInPercent: width}
		const container = document.createElement('div')
		container.classList.add('scada-container')
		container.style.left = indentLeft + '%'
		container.style.top = indentTop + '%'
		container.style.height = height + '%'
		container.style.width = width + '%'
		if (this.#position === 'h') {
			container.classList.add('scada-cont-hor')
		}
		this.#DOMContainer = container
		this.#internalElements.forEach(item =>
			item.addToContainer(this.#DOMContainer, height, width)
		)
		Container.CONTAINER.insertAdjacentElement('beforeend', this.#DOMContainer)
	}

	addElement(value) {
		this.#internalElements.push(value)
	}

	addToContainer(container, height, width) {
		if (typeof height !== 'number' || typeof width !== 'number') {
			throw new Error('Invalid height or width!')
		}
		if (!this.#DOMContainer?.style) {
			return
		}
		const itemHeight = 100 / height * this.#size.heightInPercent
		const itemWidth = 100 / width * this.#size.widthInPercent
		this.#DOMContainer.style.height = itemHeight + '%'
		this.#DOMContainer.style.width = itemWidth + '%'
		this.#DOMContainer.style.position = 'relative'
		this.#DOMContainer.style.left = 0
		this.#DOMContainer.style.top = 0
		container.insertAdjacentElement("beforeend", this.#DOMContainer)
	}

	set position(value) {
		if (value !== 'v' && value !== 'h') {
			throw new Error(`Invalid position: ${value}!`)
		}
		this.#position = value
	}

	set DOMContainer(value) {
		const isNode = !!value.style
		if (!isNode) {
			return console.error('Invalid container node!')
		}
		this.#DOMContainer = value
	}

	get DOMContainer() {
		return this.#DOMContainer
	}

	get position() {
		return this.#position
	}

	get height() {
		return this.#height
	}

	get width() {
		return this.#width
	}

	get left() {
		return this.#left
	}

	get top() {
		return this.#top
	}

	set size(value) {
		this.#size = {...value}
	}

	get id() {
		return this.#id
	}

	get isContainer() {
		return true
	}
}
