import classes from "./TireLine.module.css";
import {lineWidth, colors, background} from "../../../../config/default";
import {ImageLine} from "../ImageLine";


export class TireLine extends ImageLine {
	#DOM = {}
	#size = {}
	#points
	#isBlue = false

	constructor(left, top, height, width, position, name, nameCorrection, points, isBlue) {
		height = height ?? 50
		width = width ?? 50
		super(left, top, height, width, position, name, nameCorrection)
		this.#size = {height, width}
		this.#points = points ?? []
		this.#isBlue = !!isBlue
	}

	__createElement() {
		const container = document.createElement('div')
		container.classList.add(classes.container)
		container.style.borderWidth = lineWidth + 'px'

		const circle = document.createElement('div')
		circle.classList.add(classes.circle)
		circle.style.background = background

		this.#DOM.container = container
		this.#DOM.points = this.#points.map(item => {
			const point = circle.cloneNode()
			point.style.left = (item * 100 / this.#size.width) + '%'
			container.insertAdjacentElement('beforeend', point)
			return point
		})
		return container
	}

	__setOnState() {
		this.#DOM.container.style.background = this.#isBlue ? colors.blue : colors.red
	}

	__setOffState() {
		this.#DOM.container.style.background = colors.green
	}

	__setRepairState() {
		this.#DOM.container.style.background = colors.red
	}
}
