import React from 'react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import {Menu, MenuItem} from '@mui/material';
import Button from '@mui/material/Button';
import {useAuth} from '../../../hooks/useAuth';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {get} from "../../../utils/http";


function UserMenu() {
	const {isAdmin} = useSelector(store => store.auth)
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const navigate = useNavigate()
	const {logout} = useAuth()

	const openMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const closeMenu = () => {
		setAnchorEl(null)
	}

	const toManageAccounts = () => {
		closeMenu()
		navigate('/account-management')
	}

	const userLogout = async () => {
		await get('/api/auth/logout')
		logout()
	}

	const toUserPage = () => {
		closeMenu()
		navigate('/user-setting')
	}

	return (
		<div>
			<Button
				id="basic-button"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={openMenu}
			>
				<AccountCircleOutlinedIcon style={{color: 'black'}}/>
				{
					anchorEl
						? <ArrowDropUpOutlinedIcon style={{color: 'black'}}/>
						: <ArrowDropDownOutlinedIcon style={{color: 'black'}}/>
				}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={closeMenu}
				MenuListProps={{'aria-labelledby': 'basic-button'}}
			>
				{
					isAdmin
						? <MenuItem onClick={toManageAccounts}>Управління користувачами</MenuItem>
						: ''
				}
				<MenuItem onClick={toUserPage}>Налаштування</MenuItem>
				<MenuItem onClick={userLogout}>Вихід</MenuItem>
			</Menu>
		</div>
	)
}

export default UserMenu;
