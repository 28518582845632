import {ProtectionModeActionTypes} from "../../reduces/other/protectionModeReducer";
import {get, post} from "../../../utils/http";


async function getProtectionModeState(dispatch, tpsId) {
	if (!tpsId) return
	const response = await get(`/api/tps/protection-mode/info/${tpsId}`)
	if (response.data.state) {
		dispatch({type: ProtectionModeActionTypes.SET_ACTIVE_STATE, payload: tpsId})
	} else {
		dispatch({type: ProtectionModeActionTypes.SET_INACTIVE_STATE, payload: tpsId})
	}
	if (response.data.alarmState) {
		dispatch({type: ProtectionModeActionTypes.SET_ACTIVE_ALARM_STATE, payload: tpsId})
	} else {
		dispatch({type: ProtectionModeActionTypes.SET_INACTIVE_ALARM_STATE, payload: tpsId})
	}
}

export const fetchProtectionModeState = (tpsId) => {
	return async (dispatch) => {
		dispatch({type: ProtectionModeActionTypes.SET_LOADING_ERROR, payload: {tpsId, value: false}})
		try {
			await getProtectionModeState(dispatch, tpsId)
		} catch {
			dispatch({type: ProtectionModeActionTypes.SET_LOADING_ERROR, payload: {tpsId, value: 'Помилка на сервері!'}})
		}
	}
}

const setProtectionModeState = (tpsId, state) => {
	return async (dispatch) => {
		dispatch({type: ProtectionModeActionTypes.SET_LOADING_ERROR, payload: {tpsId, value: false}})
		try {
			await post(`/api/tps/protection-mode/state`, {tpsId, state})
				.then(() => {
					getProtectionModeState(dispatch, tpsId)
				})
		} catch {
			dispatch({type: ProtectionModeActionTypes.SET_LOADING_ERROR, payload: {tpsId, value: 'Помилка на сервері!'}})
		}
	}
}

export const setProtectionModeStateToActive = (tpsId) => {
	return setProtectionModeState(tpsId, true)
}

export const setProtectionModeStateToInactive = (tpsId) => {
	return setProtectionModeState(tpsId, false)
}
