import React, {useRef} from 'react';
import {FormControl, TextField} from '@mui/material';


function Input({
    value, onChange, name, label,
    placeholder, defaultValue,
    type = 'text',
    variant = 'outlined',
    color = 'rgb(25, 216, 149)',
    size = 'medium',
    sx = {},
  }) {

  const inputStyle = useRef({
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {borderColor: color}
    },
    '& .MuiFormLabel-root.Mui-focused': {color: color}
  })

  return (
    <FormControl fullWidth size={size}>
      <TextField
        required
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={placeholder}
        label={label}
        variant={variant}
        sx={{...inputStyle.current, ...sx}}
      />
    </FormControl>
  )
}

export default Input;