import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import EngineeringIcon from '@mui/icons-material/Engineering';
import VideocamIcon from '@mui/icons-material/Videocam';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import ErrorIcon from '@mui/icons-material/Warning';
import WarningIcon from '@mui/icons-material/Error';
import LockIcon from '@mui/icons-material/Lock';
import WifiIcon from '@mui/icons-material/Wifi';
import RefreshIcon from '@mui/icons-material/Refresh';
import classes from './Iconbar.module.css';
import tpsIconClasses from './icon/TpsIcon.module.css';
import React, {useEffect, useRef, useState} from 'react';
import {useWarning} from '../../../hooks/useWarning';
import {useModal} from '../../../hooks/useModal';
import {useSelector} from 'react-redux';
import {useLogs} from '../../../hooks/useLogs';
import {Tooltip, Typography} from '@mui/material';
import TpsIcon from './icon/TpsIcon';
import IconCounter from "../../UI/icon-counter/IconCounter";
import AlarmTpsIcon from "./alarm-icon/AlarmTpsIcon";
import {useHttp} from "../../../hooks/useHttp";
import {useProtectionMode} from "../../../hooks/useProtectionMode";
import {noop} from "../../../utils/utils";
import dateTime from "../../../utils/dateTime";
import {useActions} from "../../../hooks/useActions";


function Iconbar({isExist}) {
	const {iconDataList, controlIcons, activeTps, iconStyle, isRefreshState, alarmsArr} = useSelector(store => store.tps)
	const {isOperator, isAdmin} = useSelector(store => store.auth)
	const iconData = iconDataList[activeTps] ?? {}
	const warnModal = useModal('md', 'Ок')
	const logModal = useModal('lg', 'Ок')
	const changeModal = useModal('xs')
	const {errorHandler, warningHandler} = useWarning(warnModal)
	const {logHandler} = useLogs(logModal)
	const {fetchProtectionModeState} = useActions()
	const {protModeModal, protModeHandler, protModeState, protModeAlarmState} = useProtectionMode()
	const {post, get} = useHttp()
	const warningLength = iconData?.warning ? Object.keys(iconData.warning).length : 0
	const errorLength = iconData?.error ? Object.keys(iconData.error).length : 0
	const wrapper = useRef()
	const iconsEvents = useRef()
	const refreshModal = useModal('sm', 'ОНОВИТИ')
	const [lastRefreshTime, setLastRefreshTime] = useState()

	const changeHandler = isExist ? ((type, state) => {
		if (controlIcons[type] === undefined || (!isOperator && !isAdmin)) return
		const title = controlIcons[type].name
		changeModal.title = title[0].toUpperCase() + title.slice(1)
		changeModal.content = `${state ? 'Вимкнути' : 'Увімкнути'} ${title}?`
		const body = {tpsId: activeTps, devId: type, devState: state ? 0 : 1, devMes: ''}
		changeModal.confirm = () => post('/api/tps/set-device-y', body)
		changeModal.open()
	}) : noop

	const warningOnClick = isExist ? (() => {
		if (warningLength) {
			warningHandler('Попередження', iconData.warning)
		}
	}) : noop

	const errorOnClick = isExist ? (() => {
		if (errorLength) {
			errorHandler('Аварії', iconData.error)
		}
	}) : noop

	async function alarmOff() {
		await post(`/api/tps/alarm/disable`, {tpsId: activeTps})
		await fetchProtectionModeState()
	}

	async function showAlarmModal() {
		warnModal.confirm = alarmOff
		warnModal.deny = alarmOff
		warnModal.isRedTitle = true
		warnModal.title = 'Аварії'
		warnModal.content = alarmsArr[activeTps].map(item =>
			<Typography key={item} variant='h6'>{item}</Typography>
		)
		warnModal.open()
	}

	async function refreshTps() {
		await get('/api/tps/update-tps')
	}

	async function createRefreshModal() {
		refreshModal.title = 'Оновлення даних'
		refreshModal.content = (
			<>
				<Typography variant='body1' sx={{fontSize: '1.2rem'}}>
					Дата та час останнього оновлення даних:
				</Typography>
				<Typography variant='body1' sx={{fontSize: '1.2rem'}}>
					{lastRefreshTime}
				</Typography>
			</>
		)
		refreshModal.confirm = refreshTps
		refreshModal.open()
	}

	useEffect(() => {
		if (isRefreshState) {
			const currentTime = dateTime.now()
			setLastRefreshTime(currentTime)
		}
	}, [isRefreshState])

	useEffect(() => {
		iconsEvents.current = wrapper.current.querySelectorAll(`.${tpsIconClasses['on-click']}`)
	}, [])

	return (
		<div className={classes.container}>
			<div
				ref={wrapper}
				data-cy='ib-wr'
				className={classes.wrapper + (!isExist ? ' ' + classes['not-active'] : '')}
			>
				<TpsIcon
					title={controlIcons['temp'].title}
					isActive={true}
					onIcon={
						<Typography variant={'h6'} sx={iconStyle.blue}>
							{(iconData['temp'] ?? 0) + '°C'}
						</Typography>
					}
					offIcon={false}
				/>
				<TpsIcon
					title={controlIcons['light'].title}
					isActive={iconData['light']}
					onIcon={<EmojiObjectsIcon style={iconStyle.red}/>}
					offIcon={<EmojiObjectsOutlinedIcon style={iconStyle.blue}/>}
					onClick={changeHandler.bind(undefined, 'light', iconData['light'])}
				/>
				<TpsIcon
					title={controlIcons['ty'].title}
					isActive={iconData['ty']}
					onIcon={<Typography variant={'h6'} sx={iconStyle.red}>ТУ</Typography>}
					offIcon={<Typography variant={'h6'} sx={iconStyle.blue}>ТУ</Typography>}
				/>
				<TpsIcon
					title={controlIcons['heating'].title}
					isActive={iconData['heating']}
					onIcon={<DeviceThermostatIcon style={iconStyle.red}/>}
					offIcon={<DeviceThermostatIcon style={iconStyle.blue}/>}
					onClick={changeHandler.bind(undefined, 'heating', iconData['heating'])}
				/>
				<TpsIcon
					title={controlIcons['wifi'].title}
					isActive={iconData['wifi']}
					onIcon={<WifiIcon style={iconStyle.blue}/>}
					offIcon={<WifiOffIcon style={iconStyle.red}/>}
				/>
				<div onClick={isExist ? protModeHandler : noop}>
					{protModeState
						? <TpsIcon
							title={controlIcons['person'].title}
							isActive={protModeAlarmState}
							onIcon={<EngineeringIcon style={iconStyle.red}/>}
							offIcon={<EngineeringIcon style={iconStyle.blue}/>}
						/>
						: <TpsIcon
							title={controlIcons['person'].title}
							isActive={false}
							offIcon={<EngineeringIcon/>}
						/>
					}
				</div>
				<AlarmTpsIcon
					title={controlIcons['fire'].title}
					isActive={iconData['fire']}
					onIcon1={<LocalFireDepartmentIcon style={iconStyle.red}/>}
					onIcon2={<LocalFireDepartmentIcon/>}
					offIcon={<LocalFireDepartmentIcon style={iconStyle.blue}/>}
				/>
				<div onClick={warningOnClick} data-cy='ib-icon'>
					<Tooltip title='Попередження' arrow>
						<div className={classes.warn}>
							<WarningIcon style={warningLength ? iconStyle.blue : {}}/>
							<IconCounter
								color={warningLength ? iconStyle.blue.color : 'black'}
								count={warningLength}
								isSmall={true}
							/>
						</div>
					</Tooltip>
				</div>
				<div onClick={errorOnClick} data-cy='ib-icon'>
					<Tooltip title='Аварії' arrow>
						<div className={classes.warn}>
							<ErrorIcon style={errorLength ? iconStyle.red : {}}/>
							<IconCounter
								color={errorLength ? iconStyle.red.color : 'black'}
								count={errorLength}
								isSmall={true}
							/>
						</div>
					</Tooltip>
				</div>
				<TpsIcon
					title={controlIcons['lock'].title}
					isActive={iconData['lock']}
					onIcon={<LockIcon style={iconStyle.blue}/>}
					offIcon={<LockIcon style={iconStyle.red}/>}
				/>
				<TpsIcon
					title='Відеоспостереження'
					isActive={true}
					onIcon={<VideocamIcon style={iconStyle.blue}/>}
					offIcon={false}
				/>
				<TpsIcon
					title='Історія змін (10 останніх)'
					isActive={true}
					onIcon={<MenuBookIcon style={iconStyle.blue}/>}
					offIcon={false}
					onClick={isExist ? logHandler : noop}
					dataCy='ib-arch-icon'
				/>
				{/*<TpsIcon*/}
				{/*	title='Оновити дані'*/}
				{/*	isActive={isRefreshState}*/}
				{/*	onIcon={<RefreshIcon style={iconStyle.red}/>}*/}
				{/*	offIcon={<RefreshIcon style={iconStyle.blue}/>}*/}
				{/*	onClick={createRefreshModal}*/}
				{/*/>*/}
				{alarmsArr[activeTps] &&
					<AlarmTpsIcon
						title='тривога'
						isActive={true}
						onIcon1={<NotificationsActiveIcon style={iconStyle.red}/>}
						onIcon2={<NotificationsIcon/>}
						onClick={showAlarmModal}
					/>
				}
			</div>
			{logModal.window}
			{warnModal.window}
			{changeModal.window}
			{protModeModal.window}
			{refreshModal.window}
		</div>
	)
}

export default Iconbar;
