import React from 'react';
import {CircularProgress} from '@mui/material';

function Loader({color = 'primary'}) {
  return (
    <div style={{color}}>
      <CircularProgress sx={{color}}/>
    </div>
  )
}

export default Loader;

