import classes from "./TripleLine.module.css";
import {lineWidth, colors} from "../../../../config/default";
import {ImageLine} from "../ImageLine";


export class TripleLine extends ImageLine {
	#DOM = {}

	constructor(left, top, height, width, position, name, nameCorrection) {
		super(left, top, height ?? (lineWidth * 11), width ?? (lineWidth * 22), position, name, nameCorrection)
	}

	__createElement() {
		const container = document.createElement('div')
		container.classList.add(classes.container)

		const up = document.createElement('div')
		up.style.height = lineWidth + 'px'
		up.classList.add(classes.up)
		const center = up.cloneNode()
		center.className = classes.center
		const down = up.cloneNode()
		down.className = classes.down

		container.insertAdjacentElement('beforeend', up)
		container.insertAdjacentElement('beforeend', center)
		container.insertAdjacentElement('beforeend', down)
		this.#DOM = {up, center, down}
		return container
	}

	__setOnState() {
		Object.values(this.#DOM).forEach(item => item.style.background = colors.red)
	}

	__setOffState() {
		Object.values(this.#DOM).forEach(item => item.style.background = colors.green)
	}

	__setRepairState() {
		Object.values(this.#DOM).forEach(item => item.style.background = colors.black)
	}
}
