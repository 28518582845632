export function noop() {}

export function round(number, n = 0) {
	return parseInt(number * 10 ** n) / 10 ** n
}

export function error(text = 'Method does not exist!') {
	throw new Error(text)
}

export function radiansToDegrees(radians) {
	return radians * (180 / Math.PI)
}

export function type(value) {
	const regex = /^\[object (\S+?)\]$/;
	const matches = Object.prototype.toString.call(value).match(regex) || []
	return (matches[1] || 'undefined').toLowerCase()
}

export function isObject(value) {
	return typeof value === 'object' && value !== null && !Array.isArray(value) && value !== undefined
}

export function isInteger(value) {
	return Number.isInteger(Number(value))
}

export function isString(value) {
	return typeof value === 'string' && value.trim !== ''
}
