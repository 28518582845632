const testWarning = {
  1: {message: 'Тестове попередження 1'},
  2: {message: 'Тестове попередження 2'},
  3: {message: 'Тестове попередження 3'},
}
const testError = {
  1: {message: 'Тестова помилка 1'},
  2: {message: 'Тестова помилка 2'},
  3: {message: 'Тестова помилка 3'},
}

const tpsList = [
  {
    id: 1, name: 'ТПС-1',
    warning: {}, error: testError,
    data: {vm3: 0, vm1: 1, vm5: 0, vm6: 0, vm9: 1,}
  },
  {
    id: 2, name: 'ТПС-2',
    warning: testWarning, error: testError,
    data: {vm3: 1, vm1: 0, vm5: 1, vm6: 0, vm9: 0,}
  },
  {
    id: 3, name: 'ТПС-3',
    warning: testWarning, error: {},
    data: {vm3: 0, vm1: 0, vm5: 0, vm6: 0, vm9: 0,}
  },
  {
    id: 4, name: 'ТПС-4',
    warning: testWarning, error: {},
    data: {vm3: 0, vm1: 0, vm5: 0, vm6: 0, vm9: 0,}
  },
  {
    id: 5, name: 'ТПС-5',
    warning: {}, error: {},
    data: {vm3: 0, vm1: 0, vm5: 0, vm6: 0, vm9: 0,}
  },
  {
    id: 6, name: 'ТПС-6',
    warning: {}, error: {},
    data: {vm3: 0, vm1: 0, vm5: 0, vm6: 0, vm9: 0,}
  },
  {
    id: 7, name: 'ТПС-7',
    warning: {}, error: {},
    data: {vm3: 0, vm1: 0, vm5: 0, vm6: 0, vm9: 0,}
  },
  {
    id: 8, name: 'ТПС-8',
    warning: {}, error: {},
    data: {vm3: 0, vm1: 0, vm5: 0, vm6: 0, vm9: 0,}
  },
  {
    id: 9, name: 'ТПС-9',
    warning: {}, error: {},
    data: {vm3: 0, vm1: 0, vm5: 0, vm6: 0, vm9: 0,}
  }
]

export default tpsList
