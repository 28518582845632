import React from 'react';
import classes from './Warning.module.css'
import IconCounter from '../../UI/icon-counter/IconCounter';


function Warning({count, onClick, icon, color = 'primary'}) {
	return !!count && (
		<div
			className={classes.container}
			onClick={onClick}
		>
			{icon}
			<IconCounter count={count} color={color} />
		</div>
	)
}

export default Warning;