import React, {useEffect, useRef, useState} from 'react';
import classes from './LogsTable.module.css';
import {configureLogsArr} from '../../../utils/configureLogsArray';
import {columns} from './LogsTableColumns';
import {DataGrid} from '@mui/x-data-grid';
import {useSelector} from 'react-redux';
import {useHttp} from '../../../hooks/useHttp';
import {Switch} from '../../scada-elements/switches/Switch';
import {isObject} from "../../../utils/utils";


function LogsTable() {
	const {get} = useHttp()
	const {controlIcons} = useSelector(store => store.tps)
	const [pageSize, setPageSize] = useState(15)
	const [rows, setRows] = useState([])
	const allSwitchesName = useRef(null)

	function onPageSizeChange(newPageSize) {
		setPageSize(newPageSize)
	}

	async function getAllLogs() {
		if (!allSwitchesName.current) return
		const {response, error} = await get('/api/tps/all-logs')
		if (error || !Array.isArray(response)) return

		let logs = response
			.filter(item => isObject(item))
			.map(item => {
				if (!item.hasOwnProperty('data')) {
					return undefined
				}
				const tpsId = item.data[0]
				const switchId = item.data[3]
				if (allSwitchesName.current[tpsId][switchId] === undefined) {
					item.data[3] = controlIcons[switchId]?.name || ''
				} else {
					item.data[3] = allSwitchesName.current[tpsId][switchId]
				}
				item.data[8] = item.type
				item.data[9] = item.state
				return item.data
			})
			.filter(item => !!item)
		logs = configureLogsArr(logs)
		setRows(logs)
	}

	function getRowClassName(params) {
		let className = ''
		const row = params.row
		const type = parseInt(row.type)
		const state = parseInt(row.state)
		if (state === 1) {
			switch (type) {
				case 2:
					className = classes.on
					break
				case 3:
				case 5:
					className = classes.warning
					break
				case 4:
				case 6:
					className = classes.error
					break
			}
		} else if (state === 0) {
			className = classes.off
		}
		return className
	}

	useEffect(() => {
		Switch.generateSwitchNames()
			.then(res => allSwitchesName.current = res)
			.then(getAllLogs)
		// eslint-disable-next-line
	}, [])

	return (
		<DataGrid
			rows={rows}
			columns={columns}

			pageSize={pageSize}
			rowsPerPageOptions={[10, 15, 20, 50, 100]}
			onPageSizeChange={onPageSizeChange}
			getRowClassName={getRowClassName}

			autoHeight
			disableSelectionOnClick
			sx={{background: 'white'}}
		/>
	)
}

export default LogsTable;
