export const columns = [
	{
		field: 'id',
		headerName: 'ID',
		width: 30,
		renderCell: (param) => param.row.id - 1
	},
	{
		field: 'name',
		headerName: "Повне ім'я",
		minWidth: 100,
		flex: 1.5,
	},
	{
		field: 'roleTitle',
		headerName: 'Роль',
		width: 120,
	},
	{
		field: 'email',
		headerName: 'Електрона пошта',
		minWidth: 120,
		flex: 1.5,
	},
	{
		field: 'state',
		headerName: 'Стан',
		minWidth: 60,
		flex: 0.5,
		renderCell: (param) => param.row.state
	},
	{
		field: 'dateCreate',
		headerName: 'Дата створення',
		minWidth: 123,
		flex: 1,
	},
	{
		field: 'lastLoginDate',
		headerName: 'Дата останньої авторизації',
		minWidth: 123,
		flex: 1,
	},
	{
		field: 'lastIp',
		headerName: 'Адреса останньої авторизації',
		minWidth: 125,
		flex: 1,
	},
	{
		field: 'statusIcon',
		headerName: 'Доступ',
		width: 80,
		renderCell: (param) => param.row.statusIcon
	},
	{
		field: 'action',
		headerName: 'Редагування',
		width: 100,
		renderCell: (param) => param.row.action
	}
]
