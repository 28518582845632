import ReactDOM from 'react-dom/client';
import React from 'react';
import App from './App';
import './index.css';
import {BrowserRouter} from 'react-router-dom';
import {store} from './store/index';
import {Provider} from 'react-redux';


const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
)