import React, {useState} from 'react';
import classes from "../login/LoginPage.module.css";
import {Button, Typography} from "@mui/material";
import Input from "../../components/UI/input/Input";
import {useHttp} from "../../hooks/useHttp";
import {useSelector} from "react-redux";


function TwoFactorPage({userForm}) {
	const {post} = useHttp()
	const {login} = useSelector(store => store.auth)
	const [form, setForm] = useState({...userForm, twoFactorPin: ''})
	const [errors, setErrors] = useState('')
	const [activeButton, setActiveButton] = useState(true)

	const changeInput = (event) => {
		setForm({...form, [event.target.name]: event.target.value})
	}

	const loginHandler = async () => {
		setErrors(null)
		setActiveButton(false)
		setTimeout(async () => {
			const {response, error} = await post('/api/auth/login', form)
			if (!error) {
				login(response)
			} else if (error && response) {
				setErrors(response)
			} else {
				setErrors(error.toString() || 'Помилка сервера!')
			}
			setActiveButton(true)
		}, 200)
	}

	return (
		<div className={classes.container}>
			<div className={classes.wrapper}>
				<div className={classes.card}>
					<Typography variant={"h6"}>
						Підтверження аккаунту
					</Typography>
					<Typography variant={"subtitle1"}>
						Введіть код підтверження з мобільного додатку
					</Typography>
					<Typography className={classes.error2} variant={"subtitle1"}>
						{errors}
					</Typography>
					<Input
						name='twoFactorPin'
						value={form.twoFactorPin}
						onChange={changeInput}
						label='Код підтверження'
						variant='outlined'
						size='small'
						sx={{marginTop: '1.7rem'}}
					/>
					<Button
						disabled={!activeButton}
						className={classes.button + (activeButton ? ' btn-success' : '')}
						variant='contained'
						size='large'
						onClick={loginHandler}
					>Підтвердити</Button>
				</div>
			</div>
		</div>
	)
}

export default TwoFactorPage;
