import {Switch} from "../Switch";
import {lineWidth, colors} from '../../../../config/default'


export class MVSwitch extends Switch {
	#DOM = {}

	constructor(className, tpsId, id, name, left, top, head, tail, height, width) {
		super(className, tpsId, id, name, left, top, head, tail)
		this.__setSize(height ?? 50, width ?? 50)
	}

	__createElement() {
		const container = document.createElement('div')
		container.style.height = `calc(100% - ${lineWidth * 2}px)`
		container.style.width = `calc(100% - ${lineWidth * 2}px)`
		container.style.borderWidth = lineWidth + 'px'
		container.style.borderStyle = 'solid'
		this.#DOM.container = container
		return container
	}

	__setOnState() {
		this.#DOM.container.style.borderColor = colors.red
	}

	__setOffState() {
		this.#DOM.container.style.borderColor = colors.green
	}

	__setRepairState() {
		this.#DOM.container.style.borderColor = colors.black
	}
}
