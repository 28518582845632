import React, {useRef} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';


function MySelect(
	{
		name, value, label,
		defaultValue, onChange,
		color = 'rgb(33, 150, 243)',
		disabled = false,
		options = [],
		size = 'medium',
		sx = {}
	}) {
	const selectStyle = useRef({
		'& .MuiOutlinedInput-root.Mui-focused': {
			'& > fieldset': {borderColor: color}
		},
		'& .MuiFormLabel-root.Mui-focused': {color},
	})
	return (
		<FormControl fullWidth size={size} disabled={disabled} sx={sx}>
			<InputLabel id='select-label'>{label}</InputLabel>
			<Select
				name={name}
				labelId='select-label'
				value={value}
				label={label}
				defaultValue={defaultValue}
				onChange={onChange}
				displayEmpty
				inputProps={{'aria-label': 'Without label'}}
				sx={{...selectStyle.current}}
			>
				{
					options.map(item =>
						<MenuItem key={item.value} value={item.value}>{item.title}</MenuItem>
					)
				}
			</Select>
		</FormControl>
	)
}

export default MySelect;
