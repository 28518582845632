import React, {useRef} from 'react';
import classes from '../../components/scada/Scada.module.css';
import ErrorIcon from '@mui/icons-material/Warning';
import WarningIcon from '@mui/icons-material/Error';
import MySelect from '../../components/UI/select/MySelect';
import {TextField, Typography} from '@mui/material';
import {useHttp} from "../useHttp";
import {useSelector} from "react-redux";
import {SwitchType} from "../../components/scada-elements/configGenerator";


const noop = () => {
	console.log('loadingHandler empty')
}

export const useOnSwitchClick = (tpsId, loadingHandler = noop, modal) => {
	const {isOperator, isAdmin} = useSelector(store => store.auth)
	const {get} = useHttp()
	const comment = useRef()
	const selectState = useRef()
	const defaultState = useRef()
	const defaultOptions = [
		{value: 1, title: 'Включити'},
		{value: 0, title: 'Відключити'},
		{value: 2, title: 'В ремонті'},
	]
	const repairOptions = [
		{value: 0, title: 'В робочому стані'},
		{value: 1, title: 'В ремонті'},
	]
	const specialSwitches = [SwitchType.PZHSwitch]
	const specialOptions = {
		[SwitchType.PZHSwitch]: [
			{value: 0, title: 'Включити на РШ'},
			{value: 1, title: 'Включити на ЗШ'},
			{value: 2, title: 'В ремонті'},
		]
	}

	function selectHandler(event) {
		selectState.current = event.target.value
	}

	function textHandler(event) {
		comment.current = event.target.value
	}

	async function confirmState() {
		if (defaultState.current === selectState.current && selectState.current !== 0) {
			return
		}
		const name = this.name
		if (selectState.current === 1) {
			await this.requestToServer('on', loadingHandler.bind(this, `Перемикач ${name} вмикається...`), comment.current)
		} else if (selectState.current === 0) {
			await this.requestToServer('off', loadingHandler.bind(this, `Перемикач ${name} вимикається...`), comment.current)
		} else if (selectState.current === 2) {
			if (this.isOnlySchemas()) {
				await this.requestToServer(
					'repair',
					loadingHandler.bind(this, `Перемикач ${name} вимикається...`),
					comment.current
				)
			} else {
				this.type = 'x'
				const setY = () => {
					this.type = 'y'
				}
				await this.requestToServer(
					'repair',
					loadingHandler.bind(this, `Перемикач ${name} вимикається...`),
					comment.current
				).then(setY.bind(this))
			}
		}
		comment.current = ''
	}

	async function confirmRepair() {
		if (defaultState.current === selectState.current) {
			return
		}
		const name = this.name
		if (selectState.current === 1) {
			await this.requestToServer('repair', loadingHandler.bind(this, `Перемикач ${name} вимикається...`), '')
		} else if (selectState.current === 0) {
			if (tpsId) {
				loadingHandler(`Перемикач ${name} включається...`)
				await get(`/api/tps/update/device/${tpsId}/${this.id}`)
			}
		}
	}

	function onSwitchClick() {
		if (!isOperator && !isAdmin) {
			return
		}
		const isInteractive = this.isOnlySchemas() || (!this.isStateRepair() && !this.isOnlySchemas() && this.isInteractive())
		modal.title = this.name + (this.isOnlySchemas() ? ' (тільки на схемі)' : '')
		defaultState.current = isInteractive ? this.state : this.isStateRepair() ? 1 : 0
		selectState.current = defaultState.current

		let defOptions = defaultOptions.map(item => item)
		let repOptions = repairOptions.map(item => item)
		if (this.isStateOn() && !this.isOnlySchemas()) {
			if (isInteractive) {
				defOptions = defOptions.filter(item => item.value !== 2)
			} else {
				repOptions = repOptions.filter(item => item.value !== 1)
			}
		}
		const switchClassName = this.className
		let isSpecial = false
		if (specialSwitches.findIndex(item => item === switchClassName) >= 0) {
			isSpecial = specialOptions[switchClassName]
		}

		modal.content = (<>
			{!!this.errorList.length &&
				<div className={'danger'}>
					{this.errorList.map(item =>
						<div
							key={item.slice(0, 20)}
							className={classes.warning}
						>
							<ErrorIcon/>
							<Typography variant={'subtitle1'}>{item}</Typography>
						</div>
					)}
				</div>
			}
			{!!this.warningList.length &&
				<div className={'primary'}>
					{this.warningList.map(item =>
						<div
							key={item.slice(0, 20)}
							className={classes.warning}
						>
							<WarningIcon/>
							<Typography variant={'subtitle1'}>{item}</Typography>
						</div>
					)}
				</div>
			}
			{isSpecial
				? <>
					<Typography variant={"h6"} className={classes.text}>Статус:</Typography>
					<MySelect
						className={classes.select}
						defaultValue={this.state}
						options={isSpecial}
						onChange={selectHandler}
					/>
				</>
				: isInteractive
					? <>
						<Typography variant={"h6"} className={classes.text}>Статус:</Typography>
						<MySelect
							className={classes.select}
							defaultValue={this.state}
							options={defOptions}
							onChange={selectHandler}
						/>
					</>
					: <>
						<Typography variant={"h6"} className={classes.text}>Статус:</Typography>
						<MySelect
							className={classes.select}
							defaultValue={defaultState.current}
							options={repOptions}
							onChange={selectHandler}
						/>
					</>
			}
			<Typography variant={"h6"} className={classes.text}>Коментар:</Typography>
			<TextField
				multiline
				maxRows={7}
				onChange={textHandler}
				sx={{width: '100%', minHeight: '5rem'}}
			/>
		</>)
		if (isInteractive) {
			modal.confirm = confirmState.bind(this)
		} else {
			modal.confirm = confirmRepair.bind(this)
		}
		modal.open()
	}

	return {onSwitchClick}
}
