import backgroundImage from "../../assets/schemes/image-187.png";


const TP187 = {
	id: 187,
	size: {width: 2891, height: 1774, minHeight: 800},
	image: backgroundImage,
	isExist: false,
	lines: [],
	switches: [],
	activeLines: []
}

export default TP187
