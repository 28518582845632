import React, {useRef, useState} from 'react';
import {
	FormControl, InputLabel, MenuItem, Select,
	Checkbox, ListItemText, Box, Chip,
} from '@mui/material';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		}
	}
}

function MultipleSelect(
	{
		name, label, sx = {},
		color = 'rgb(33, 150, 243)',
		size = 'medium',
		onChange = () => {
		},
		selectedValues = [],
		options = [],
	}) {
	const [list, setList] = useState(selectedValues)
	const selectStyle = useRef({
		'& .MuiOutlinedInput-root.Mui-focused': {
			'& > fieldset': {borderColor: color}
		},
		'& .MuiFormLabel-root.Mui-focused': {color: color}
	})

	const handleChange = (event) => {
		onChange(event)
		const {target: {value}} = event
		setList(value)
	}

	return (
		<FormControl fullWidth size={size} sx={sx}>
			<InputLabel id='multiple-checkbox-label'>{label}</InputLabel>
			<Select
				name={name}
				labelId='select-label'
				value={list}
				label={label}
				onChange={handleChange}
				displayEmpty
				inputProps={{'aria-label': 'Without label'}}
				sx={{...selectStyle.current}}

				multiple
				MenuProps={MenuProps}
				renderValue={(selected) => (
					<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
						{
							selected.map(tpsId =>
								<Chip
									key={tpsId}
									label={options.filter(subItem => subItem.id === tpsId)[0].name}
								/>
							)
						}
					</Box>
				)}
			>
				{
					options.map(item =>
						<MenuItem key={item.id} value={item.id}>
							<Checkbox checked={
								!!list.find(tpsId => tpsId === item.id)
							}/>
							<ListItemText primary={item.name}/>
						</MenuItem>
					)
				}
			</Select>
		</FormControl>
	)
}

export default MultipleSelect;
