import React from 'react';
import classes from './Card.module.css'
import TpsIndicator from "../indicator/TpsIndicator";
import CardContent from "./card-content/CardContent";


function Card({element}) {
	const inputs = element?.data?.inputs
	const aggregates = element?.data?.aggregates

	return (
		<div className={classes.container} data-cy='home-card'>
			<div className={classes.wrapper}>
				<div className={classes.indicators}>
					{
						Object.keys(inputs).map(item =>
							<TpsIndicator
								key={item}
								data={inputs[item]}
								type={'inputs'}
							/>
						)
					}
				</div>

				<CardContent element={element}/>

				<div className={classes.indicators}>
					{
						Object.keys(aggregates).map(item =>
							<TpsIndicator
								key={item}
								data={aggregates[item]}
								type={'aggregates'}
							/>
						)
					}
				</div>
			</div>
			{
				!!element.state || <div className={classes.offline}/>
			}
		</div>
	)
}

export default Card;
