import React, {useCallback, useRef} from 'react';
import classes from './Sidebar.module.css';
import {routes} from '../../routes/routes';
import {useNavigate} from 'react-router-dom';
import {Typography} from '@mui/material';
import {useSelector} from 'react-redux';


function Sidebar({show}) {
	const {menuHandler} = useSelector(store => store.sidebar)
	const container = useRef()
	const navigate = useNavigate()

	const toPage = useCallback((path) => {
		menuHandler()
		navigate(path)
		// eslint-disable-next-line
	}, [menuHandler])

	return (
		<div ref={container} className={classes.container + (show ? '' : ' ' + classes.hide)}>
			{
				routes.filter(item => item.title).map(route => (
					<div
						key={route.id}
						className={classes.route}
						onClick={toPage.bind(undefined, route.path)}
					>
						{route.icon}
						<div>
							<Typography variant='subtitle2'>
								{route.title}
							</Typography>
						</div>
					</div>
				))
			}
		</div>
	)
}

export default Sidebar;
