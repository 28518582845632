export function DuplexSideHeadSwitch(instance, rightHead) {
	instance.rHead = rightHead
	instance.rHead.addSwitch(instance)
	instance.lHead = instance.head
	instance.lActiveState = 1
	instance.rActiveState = 0
	instance.update = update.bind(instance)
	return instance
}

function update() {
	if (this.state !== 2) {
		const direction = this.state === this.lActiveState
		let currHead = direction ? this.lHead : this.rHead
		if (currHead.state === 1) {
			this.updateDepend('on')
			this.tail.on()
		} else if (currHead.state === 0) {
			this.tail.off()
			this.updateDepend('off')
		} else if (currHead.state === 2) {
			this.tail.repair()
			this.updateDepend('repair')
		}
		if (direction) {
			this.__setOnState()
		} else {
			this.__setOffState()
		}
	} else if (this.state === 2) {
		this.updateDepend('repair')
		this.tail.repair()
	}
}
