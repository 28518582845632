export const configureUserState = (allUsers, userStatus) => {
	return allUsers.map(item => {
		if (userStatus.find(id => item.id.toString() === id)) {
			item.state = <label className='state-online bg-success'>online</label>
		} else {
			item.state = <label className='state-offline bg-danger'>offline</label>
		}
		return item
	})
}
