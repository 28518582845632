import {Switch} from "../Switch";
import classes from "./PZHSwitch.module.css";
import {colors, lineWidth} from "../../../../config/default";


export class PZHSwitch extends Switch {
	#DOM = {lines: [], left: {}, right: {}, container: {}}

	constructor(className, tpsId, id, name, left, top, head, tail, height, width) {
		super(className, tpsId, id, name, left, top, head, tail)
		this.__setSize(height ?? 68, width ?? 140)
	}

	__createElement() {
		const container = document.createElement('div')
		container.classList.add(classes.container)

		const up = document.createElement('div')
		up.classList.add(classes.up)
		up.style.height = `calc(100% - ${lineWidth}px)`

		const lSide = document.createElement('div')
		lSide.classList.add(classes.side)
		const lHor = document.createElement('div')
		lHor.classList.add(classes.horizontal)
		lHor.style.height = lineWidth + 'px'
		const lVert = document.createElement('div')
		lVert.classList.add(classes.vertical)
		lVert.style.width = lineWidth + 'px'
		const lLine = document.createElement('div')
		lLine.style.width = lineWidth + 'px'
		lLine.style.height = `calc(40.3% - ${lineWidth}px)`
		lSide.insertAdjacentElement('beforeend', lHor)
		lSide.insertAdjacentElement('beforeend', lVert)
		lSide.insertAdjacentElement('beforeend', lLine)

		const rSide = lSide.cloneNode()
		const rHor = lHor.cloneNode()
		const rVert = lVert.cloneNode()
		const rLine = lLine.cloneNode()
		rSide.insertAdjacentElement('beforeend', rHor)
		rSide.insertAdjacentElement('beforeend', rVert)
		rSide.insertAdjacentElement('beforeend', rLine)

		up.insertAdjacentElement('beforeend', lSide)
		up.insertAdjacentElement('beforeend', rSide)

		const downLine = document.createElement('div')
		downLine.classList.add(classes.down)
		downLine.style.height = lineWidth + 'px'

		container.insertAdjacentElement('beforeend', up)
		container.insertAdjacentElement('beforeend', downLine)

		this.#DOM = {container, lines: [lLine, rLine, downLine], left: {lVert, lHor}, right: {rVert, rHor}}
		return container
	}

	__setOnState() {
		const activeColor = this.tail.state ? colors.red : colors.green
		Object.values(this.#DOM.right).forEach(item => item.style.background = colors.green)
		Object.values(this.#DOM.left).forEach(item => item.style.background = activeColor)
		this.#DOM.lines.forEach(item => item.style.background = activeColor)
		this.#DOM.left.lVert.style.transform = `rotate(0)`
		this.#DOM.right.rVert.style.transform = `rotate(-35deg)`
	}

	__setOffState() {
		const activeColor = this.tail.state ? colors.red : colors.green
		Object.values(this.#DOM.right).forEach(item => item.style.background = activeColor)
		Object.values(this.#DOM.left).forEach(item => item.style.background = colors.green)
		this.#DOM.lines.forEach(item => item.style.background = activeColor)
		this.#DOM.left.lVert.style.transform = `rotate(-35deg)`
		this.#DOM.right.rVert.style.transform = `rotate(0)`
	}

	__setRepairState() {
		Object.values(this.#DOM.right).forEach(item => item.style.background = colors.black)
		Object.values(this.#DOM.left).forEach(item => item.style.background = colors.black)
		this.#DOM.lines.forEach(item => item.style.background = colors.black)
		this.#DOM.left.lVert.style.transform = `rotate(-35deg)`
		this.#DOM.right.rVert.style.transform = `rotate(-35deg)`
	}
}
