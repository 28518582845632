import {colors, lineWidth} from "../../../../config/default";
import {ImageLine} from "../ImageLine";
import classes from "./DoubleLine.module.css";


export class DoubleLine extends ImageLine {
	#DOM = {lines: []}

	constructor(left, top, height, width, position, name, nameCorrection) {
		super(left, top, height, width ?? 140, position, name, nameCorrection)
	}

	__createElement() {
		const container = document.createElement('div')
		container.classList.add(classes.container)

		const lSide = document.createElement('div')
		lSide.classList.add(classes.side)
		const rSide = lSide.cloneNode()

		const left = document.createElement('div')
		left.classList.add(classes.stick)
		left.style.width = lineWidth + 'px'
		const right = left.cloneNode()

		lSide.insertAdjacentElement('beforeend', left)
		rSide.insertAdjacentElement('beforeend', right)

		container.insertAdjacentElement('beforeend', lSide)
		container.insertAdjacentElement('beforeend', rSide)

		this.#DOM.lines = [left, right]
		this.#DOM.container = container
		return container
	}

	__setOnState() {
		this.#DOM.lines.forEach(item => item.style.backgroundColor = colors.red)
	}

	__setOffState() {
		this.#DOM.lines.forEach(item => item.style.backgroundColor = colors.green)
	}

	__setRepairState() {
		this.#DOM.lines.forEach(item => item.style.backgroundColor = colors.black)
	}
}
