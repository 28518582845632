import {Switch} from "../Switch";
import classes from "./KASwitch.module.css";
import {lineWidth, colors} from "../../../../config/default";


export class KASwitch extends Switch {
	#DOM = {}

	constructor(className, tpsId, id, name, left, top, head, tail, height, width) {
		super(className, tpsId, id, name, left, top, head, tail)
		this.__setSize(height ?? 55, width ?? 55)
	}

	__createElement() {
		const container = document.createElement('div')
		container.classList.add(classes.container)

		const horLine = document.createElement('div')
		horLine.classList.add(classes['hor-line'])
		horLine.style.height = lineWidth + 'px'
		container.insertAdjacentElement('beforeend', horLine)
		this.#DOM.horLine = horLine

		const main = document.createElement('div')
		main.classList.add(classes.main)
		main.style.left = -(lineWidth * 1.5) + 'px'
		container.insertAdjacentElement('beforeend', main)
		this.#DOM.main = main

		const rect = document.createElement('div')
		rect.classList.add(classes.rect)
		rect.style.width = (lineWidth * 2) + 'px'
		rect.style.borderWidth = lineWidth + 'px'
		main.insertAdjacentElement('beforeend', rect)
		this.#DOM.rect = rect

		const vertLine = document.createElement('div')
		vertLine.classList.add(classes['vert-line'])
		vertLine.style.width = lineWidth + 'px'
		main.insertAdjacentElement('beforeend', vertLine)
		this.#DOM.vertLine = vertLine

		return container
	}

	__setOnState() {
		[this.#DOM.vertLine, this.#DOM.horLine].forEach(item => item.style.background = colors.red)
		this.#DOM.rect.style.borderColor = colors.red
		this.#DOM.main.style.transform = `rotate(0)`
	}

	__setOffState() {
		[this.#DOM.vertLine, this.#DOM.horLine].forEach(item => item.style.background = colors.green)
		this.#DOM.rect.style.borderColor = colors.green
		this.#DOM.main.style.transform = `rotate(-35deg)`
	}

	__setRepairState() {
		[this.#DOM.vertLine, this.#DOM.horLine].forEach(item => item.style.background = colors.black)
		this.#DOM.rect.style.borderColor = colors.black
		this.#DOM.main.style.transform = `rotate(0)`
	}
}
