import React, {useEffect, useRef} from 'react';
import {useModal} from '../../hooks/useModal';
import {useHttp} from '../../hooks/useHttp';
import classes from './Scada.module.css';
import {Line} from '../scada-elements/lines/Line';
import {TextOnScheme} from "../scada-elements/other/TextOnScheme/TextOnScheme";
import {Switch} from '../scada-elements/switches/Switch';
import {useSelector} from 'react-redux';
import {useActions} from '../../hooks/useActions';
import {useOnSwitchClick} from '../../hooks/scada/useOnSwitchClick';
import {useUpdateScadaData} from '../../hooks/scada/useUpdateScadaData';
import {useAggregatesValue} from "../../hooks/scada/useAggregatesValue";
import {Container} from "../scada-elements/other/Container/Container";


function Scada({loadingHandler, startLoading}) {
	const {activeConfig} = useSelector(store => store.scada)
	const {activeTps: tpsId} = useSelector(store => store.tps)
	const {setAllSwitches, setGenerateTrue, setGenerateFalse} = useActions()
	const {get} = useHttp()
	const modal = useModal()
	const container = useRef()
	let {onSwitchClick} = useOnSwitchClick(tpsId, loadingHandler, modal)
	useUpdateScadaData(startLoading)
	useAggregatesValue(container)

	function stopLoading() {
		loadingHandler()
		container.current.style.display = 'block'
		setGenerateTrue()
		loadingHandler('Завантаження данних...')
	}

	function onSwitchError(value, confirm = () => {}, deny = () => {}) {
		modal.title = this.name + (this.isOnlySchemas() ? ' (тільки на схемі)' : '')
		modal.content = value
		modal.confirm = confirm.bind(this)
		modal.deny = deny.bind(this)
		setTimeout(modal.open, 10)
	}

	function generationSwitches(params) {
		const {width, height, options} = params
		container.current.style.aspectRatio = width / height
		container.current.style.minHeight = options.size.minHeight + 'px'
		Line.CONTAINER = Switch.CONTAINER = TextOnScheme.CONTAINER = Container.CONTAINER = container.current
		Line.SIZE = Switch.SIZE = TextOnScheme.SIZE = Container.SIZE = {width, height}
		Switch.ERROR = onSwitchError
		Switch.FUNC = onSwitchClick
		Switch.TPSID = tpsId
		const {text, lines, activeLines, switches, containers, image} = options
		if (!image) {
			const subItemGeneration = (item) => {
				if (Array.isArray(item)) {
					try {
						item.forEach(subItem => subItem.generation())
					} catch (err) {
						console.error('Scada loading error:', err)
					}
				}
			}
			if (Array.isArray(text)) {
				text.forEach(item => item.generate())
			}
			if (Array.isArray(lines)) {
				lines.forEach(subItemGeneration)
			}
			if (Array.isArray(activeLines)) {
				try {
					activeLines.forEach(item => item.on())
				} catch (err) {
					console.error('Scada loading error:', err)
				}
			}
			if (Array.isArray(switches)) {
				switches.forEach(subItemGeneration)
			}
			if (Array.isArray(containers)) {
				try {
					containers.forEach(item => item.generate())
				} catch (err) {
					console.error('Scada loading error:', err)
				}
			}
		}
		setAllSwitches(Switch.ALL)
		setTimeout(stopLoading, 10)
	}

	const generationContainer = () => {
		const options = activeConfig
		container.current.style.display = 'none'
		container.current.innerHTML = ''
		if (options) {
			if (options.hasOwnProperty('image')) {
				const image = document.createElement('img')
				image.src = options.image
				image.style.display = 'none'
				image.onload = function() {
					generationSwitches({width: this.width, height: this.height, options})
					this.style.width = '100%'
					this.style.display = 'block'
				}
				container.current.insertAdjacentElement('beforeend', image)
			} else if (options.hasOwnProperty('size')) {
				const {size: {width, height}} = options
				generationSwitches({width, height, options})
			}
		}
		else {
			container.current.innerHTML = 'Схема не знайдена'
		}
	}

	useEffect(() => {
		loadingHandler('', true)
		if (activeConfig?.isExist) {
			get(`/api/tps/check/${tpsId}`).then(() => {
				loadingHandler('Завантаження схеми...')
				setGenerateFalse()
				generationContainer()
			})
		} else {
			generationContainer()
		}
		// eslint-disable-next-line
	}, [activeConfig])

	return (
		<>
			<div ref={container} className={classes.wrapper} data-cy='scada-cont'/>
			{modal.window}
		</>
	)
}

export default Scada
