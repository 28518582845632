import {round} from "../../../utils/utils";
import {lineWidth, colors} from '../../../config/default'


export class Line {
	static CONTAINER = null
	static SIZE = null
	#dep = {switches: [], lines: []}
	#isInContainer = false
	#DOMContainer
	#position
	#length
	#state
	#left
	#top
	#isBlue = false

	constructor(left, top, length, position, isBlue) {
		this.#left = left ?? 0
		this.#top = top ?? 0
		this.length = length
		this.position = position ?? 'v'
		this.#isBlue = !!isBlue
	}

	generation() {
		if (!Line.SIZE || !Line.CONTAINER) {
			throw new Error('Class has no size or container!')
		}
		const element = this.__createLineContainer()
		if (!this.#isInContainer) {
			Line.CONTAINER.insertAdjacentElement('beforeend', element)
		}
		this.#DOMContainer = element
		this.#state = 0
		this.__setOffState()
		this.#dep.lines.forEach(item => item.generation())
	}

	__createLineContainer() {
		const line = document.createElement('div')
		line.dataset.cy = 'ln-cont'
		line.classList.add('line')
		line.style.left = round(this.#left * 100 / Line.SIZE.width, 3) + '%'
		line.style.top = round(this.#top * 100 / Line.SIZE.height, 3) + '%'

		if (this.#position === 'v') {
			this.lengthInPercent = round(this.#length * 100 / Line.SIZE.height, 3)
			line.style.height = this.lengthInPercent + '%'
			line.style.width = lineWidth + 'px'
		} else {
			this.lengthInPercent = round(this.#length * 100 / Line.SIZE.width, 3)
			line.style.height = lineWidth + 'px'
			line.style.width = this.lengthInPercent + '%'
		}
		return line
	}

	on() {
		if (this.#state === 1) return
		this.state = 1
		this.__setOnState()
		this.#updateDep(item => item.on())
	}

	off() {
		if (this.#state === 0) return
		this.state = 0
		this.__setOffState()
		this.#updateDep(item => item.off())
	}

	repair() {
		if (this.#state === 2) return
		this.state = 2
		this.__setRepairState()
		this.#updateDep(item => item.repair())
	}

	__setOnState() {
		this.#DOMContainer.style.background = this.#isBlue ? colors.blue : colors.red
	}

	__setOffState() {
		this.#DOMContainer.style.background = colors.green
	}

	__setRepairState() {
		this.#DOMContainer.style.background = colors.black
	}

	#updateDep(func) {
		this.#dep.lines.forEach(func)
		this.#dep.switches.forEach(item => item.update())
	}

	addToContainer(container, height, width) {
		if (typeof height !== 'number' || typeof width !== 'number') {
			throw new Error('Invalid height or width!')
		}
		if (!this.#DOMContainer?.style) {
			return
		}
		if (this.#position === 'v') {
			const newLength = (100 / height * this.lengthInPercent)
			this.#DOMContainer.style.height = newLength + '%'
		} else {
			const newLength = (100 / width * this.lengthInPercent)
			this.#DOMContainer.style.width = newLength + '%'
		}
		this.#DOMContainer.style.position = 'relative'
		this.#DOMContainer.style.left = 0
		this.#DOMContainer.style.top = 0
		this.isInContainer = true
		container.insertAdjacentElement("beforeend", this.#DOMContainer)
	}

	addLine(value) {
		if (typeof value !== 'object' || Array.isArray(value) || value === null) {
			throw new Error('Value is not object!')
		}
		this.#dep.lines.push(value)
	}

	addSwitch(value) {
		if (typeof value !== 'object' || Array.isArray(value) || value === null) {
			throw new Error('Value is not object!')
		}
		this.#dep.switches.push(value)
	}

	get DOMContainer() {
		return this.#DOMContainer
	}

	set isInContainer(value) {
		if (typeof value !== 'boolean') {
			throw new Error('Incorrect value for isInContainer!')
		}
		this.#isInContainer = value
	}

	set left(value) {
		if (value < 0) {
			throw new Error('Invalid left position in line! Line coordinate: ' + this.#left ?? 0 + 'x' + this.#top ?? 0)
		}
		this.#left = value
	}

	get left() {
		return this.#left
	}

	set top(value) {
		if (value < 0) {
			throw new Error('Invalid top position in line! Line coordinate: ' + this.#left ?? 0 + 'x' + this.#top ?? 0)
		}
		this.#top = value
	}

	get top() {
		return this.#top
	}

	set position(value) {
		if (value !== 'v' && value !== 'h') {
			throw new Error('Invalid position type! Line coordinate: ' + this.#left + 'x' + this.#top)
		}
		this.#position = value
	}

	get position() {
		return this.#position
	}

	set length(value) {
		if (value < 0) {
			throw new Error('Invalid length line! Line coordinate: ' + this.#left + 'x' + this.#top)
		}
		this.#length = value
	}

	set state(value) {
		if (typeof value !== 'number' || value < 0) {
			throw new Error('Invalid state value! Line coordinate: ' + this.#left + 'x' + this.#top)
		}
		this.#state = value
	}

	get state() {
		return this.#state
	}

	get isLine() {
		return true
	}
}
