import React, {useState} from 'react';
import classes from './LoginPage.module.css'
import {Typography, Button} from '@mui/material';
import {useSelector} from 'react-redux';
import {useHttp} from '../../hooks/useHttp';
import Input from '../../components/UI/input/Input';
import TwoFactorPage from "../two-factor/TwoFactorPage";


function LoginPage() {
	const {post} = useHttp()
	const {login} = useSelector(store => store.auth)
	const [form, setForm] = useState({email: '', password: ''})
	const [errors, setErrors] = useState('')
	const [twoFactor, setTwoFactor] = useState(false)
	const [activeButton, setActiveButton] = useState(true)

	const changeInput = (event) => {
		setForm({...form, [event.target.name]: event.target.value})
	}

	const loginHandler = async () => {
		setErrors(null)
		setActiveButton(false)
		setTimeout(async () => {
			const {response, error} = await post('/api/auth/login', form)
			if (!error) {
				if (response.hasOwnProperty('twoFactor')) {
					setTwoFactor(true)
				}
				else {
					login(response)
				}
			}
			else if (error && response) {
				setActiveButton(true)
				if (Array.isArray(response)) {
					setErrors(response[0].msg)
				} else {
					setErrors(response)
				}
			}
			else {
				setActiveButton(true)
				setErrors('Помилка сервера!')
			}
		}, 200)
	}

	if (twoFactor) {
		return <TwoFactorPage userForm={form} />
	}

	return (
		<div className={classes.container}>
			<div className={classes.wrapper}>
				<div className={classes.card}>
					<Typography variant={"h6"}>
						Авторизація
					</Typography>
					<Typography variant={"subtitle1"}>
						Увійдіть для продовження
					</Typography>
					<Typography className={classes.error} variant={"subtitle1"} >
						{errors}
					</Typography>
					<Input
						name='email'
						value={form.email}
						onChange={changeInput}
						label='Електрона адреса'
						variant='outlined'
						size='small'
					/>
					<Input
						name='password'
						type='password'
						value={form.password}
						onChange={changeInput}
						label='Пароль'
						variant='outlined'
						size='small'
						sx={{marginTop: '1.5rem'}}
					/>
					<Button
						disabled={!activeButton}
						className={classes.button + (activeButton ? ' btn-success' : '')}
						variant='contained'
						size='large'
						onClick={loginHandler}
						data-cy='login-but'
					>Увійти</Button>
				</div>
			</div>
		</div>
	)
}

export default LoginPage;
