export const columns = [
	{
		field: 'station',
		headerName: 'Станція',
		width: 70,
		renderCell: (param) => 'ТПС-' + param.row.station
	},
	{
		field: 'timeCreate',
		headerName: 'Час створення',
		minWidth: 115,
		flex: 1,
	},
	{
		field: 'user',
		headerName: 'Користувач',
		minWidth: 95,
		flex: 1,
	},
	{
		field: 'switch',
		headerName: 'Перемикач',
		width: 120,
	},
	{
		field: 'action',
		headerName: 'Дія',
		width: 100,
	},
	{
		field: 'comment',
		headerName: 'Коментар',
		minWidth: 100,
		flex: 2,
		renderCell: (param) => param.row.comment
	},
	{
		field: 'status',
		headerName: 'Статус',
		minWidth: 110,
		flex: 1,
	},
]
