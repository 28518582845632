import {Switch} from "../Switch";
import {lineWidth, colors} from "../../../../config/default";
import classes from './HR2Switch.module.css'


export class HR2Switch extends Switch {
	#distance
	#DOM = {}

	constructor(className, tpsId, id, name, left, top, head, tail, height, width, distance) {
		if (height && height < lineWidth * 11) {
			throw new Error('Small height in HR2Switch!')
		}
		super(className, tpsId, id, name, left, top, head, tail)
		this.__setSize(height ?? 55, width ?? 40)
		this.distance = distance
	}

	__createElement() {
		const container = document.createElement('div')
		container.classList.add(classes.container)

		const elementWidth = (this.#distance * 100 / this.size.width) + '%'
		const extremeLinesTop = document.createElement('div')
		extremeLinesTop.classList.add(classes.top)
		extremeLinesTop.style.width = `calc(${elementWidth} - ${lineWidth}px)`
		extremeLinesTop.style.borderLeftWidth = lineWidth + 'px'
		extremeLinesTop.style.borderRightWidth = lineWidth + 'px'
		container.insertAdjacentElement('beforeend', extremeLinesTop)
		this.#DOM.extremeLinesTop = extremeLinesTop

		const vertLines = document.createElement('div')
		vertLines.classList.add(classes['vert-line'])
		vertLines.style.width = `calc(${elementWidth} - ${lineWidth}px)`
		vertLines.style.borderLeftWidth = lineWidth + 'px'
		vertLines.style.borderRightWidth = lineWidth + 'px'
		container.insertAdjacentElement('beforeend', vertLines)
		this.#DOM.vertLines = vertLines

		const horLines = document.createElement('div')
		horLines.classList.add(classes['hor-line'])
		horLines.style.height = lineWidth * 1.5 + 'px'
		horLines.style.borderTopWidth = lineWidth + 'px'
		horLines.style.borderBottomWidth = lineWidth + 'px'
		vertLines.insertAdjacentElement('beforeend', horLines)
		this.#DOM.horLines = horLines

		const sticks = extremeLinesTop.cloneNode(true)
		sticks.style.height = lineWidth + 'px'
		sticks.style.width = `calc(${sticks.style.width} - ${lineWidth * 2}px)`
		sticks.style.borderLeftWidth = (lineWidth * 3) + 'px'
		sticks.style.borderRightWidth = (lineWidth * 3) + 'px'
		container.insertAdjacentElement('beforeend', sticks)
		this.#DOM.sticks = sticks

		this.#DOM.extremeLinesBottom = extremeLinesTop.cloneNode(true)
		container.insertAdjacentElement('beforeend', this.#DOM.extremeLinesBottom)

		return container
	}

	__setOnState() {
		Object.values(this.#DOM).forEach(item => item.style.borderColor = colors.red)
		this.#DOM.vertLines.style.transform = `skewX(0deg)`
	}

	__setOffState() {
		Object.values(this.#DOM).forEach(item => item.style.borderColor = colors.green)
		this.#DOM.vertLines.style.transform = `skewX(28deg)`
	}

	__setRepairState() {
		Object.values(this.#DOM).forEach(item => item.style.borderColor = colors.black)
		this.#DOM.vertLines.style.transform = `skewX(28deg)`
	}

	set distance(value) {
		if (typeof value !== 'number' || !Number.isInteger(value)) {
			throw new Error('Distance is not a number in HR2Switch!')
		}
		if (value + (lineWidth * 2) > this.size.width) {
			throw new Error('Large distance in HR2Switch!')
		}
		if (value < (lineWidth * 3)) {
			throw new Error('Small distance in HR2Switch!')
		}
		this.#distance = value
	}
}
