import {ImageLine} from "../ImageLine";
import {lineWidth, colors} from "../../../../config/default";


export class MVLine extends ImageLine {
	#DOM = {}

	constructor(left, top, height, width, position, name, nameCorrection) {
		super(left, top, height ?? 55, width ?? 55, position, name, nameCorrection)
	}

	__createElement() {
		const container = document.createElement('div')
		container.style.height = `calc(100% - ${lineWidth * 2}px)`
		container.style.width = `calc(100% - ${lineWidth * 2}px)`
		container.style.borderWidth = lineWidth + 'px'
		container.style.borderStyle = 'solid'
		this.#DOM.container = container
		return container
	}

	__setOnState() {
		this.#DOM.container.style.borderColor = colors.red
	}

	__setOffState() {
		this.#DOM.container.style.borderColor = colors.green
	}

	__setRepairState() {
		this.#DOM.container.style.borderColor = colors.black
	}
}
