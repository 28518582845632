import React from 'react';
import classes from './CardContent.module.css'
import {useOpenTps} from '../../../../hooks/useOpenTps';
import WarningIcon from '@mui/icons-material/Error'
import ErrorIcon from '@mui/icons-material/Warning'
import Warning from '../../warning-icon/Warning';
import {useSelector} from 'react-redux';
import {Typography} from '@mui/material';

function CardContent({element}) {
	const {openTps} = useOpenTps()
	const {warningHandler, errorHandler} = useSelector(store => store.homePage)
	const {iconStyle} = useSelector(store => store.tps)

	return (
		<div className={classes.container}>
			<div
				className={classes.title}
				onClick={() => openTps(element.id)}
			>
				<Typography variant='h5'>
					{element.name}
				</Typography>
			</div>
			{
				Object.keys(element.error).length + Object.keys(element.warning).length === 0
				|| <div className={classes.warning}>
					<Warning
						count={Object.keys(element.warning).length}
						onClick={warningHandler.bind(undefined, 'Попередження', element.warning)}
						icon={<WarningIcon fontSize='large' style={iconStyle.blue}/>}
						color={iconStyle.blue.color}
					/>
					<Warning
						count={Object.keys(element.error).length}
						onClick={errorHandler.bind(undefined, 'Помилки', element.error)}
						icon={<ErrorIcon fontSize='large' style={iconStyle.red}/>}
						color={iconStyle.red.color}
					/>
				</div>
			}
		</div>
	)
}

export default CardContent;
