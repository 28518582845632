export function DuplexSwitch(instance) {
	instance.tail.addSwitch(instance)
	instance.direction = null
	instance.active = false
	instance.lastHeadState = false
	instance.lastTailState = false
	instance.on = on.bind(instance)
	instance.off = off.bind(instance)
	instance.repair = repair.bind(instance)
	instance.update = update.bind(instance)
	return instance
}

function on() {
	if (this.state === 1) return
	this.active = true
	this.state = 1
	this.__setOnState()
	this.update()
}

function off() {
	if (this.state === 0) return
	this.active = true
	this.state = 0
	this.__setOffState()
	this.update()
}

function repair() {
	if (this.state === 2) return
	this.active = true
	this.state = 2
	this.__setRepairState()
	this.update()
}

function update() {
	if (this.state === 1) {
		// ON

		if (this.active) {
			this.active = false
			// BUTTON

			if (this.head.state === 1 && this.tail.state === 1) {
				// ON HEAD -><- TAIL
			} else if (this.head.state === 1 && this.tail.state === 0) {
				// ON HEAD -> TAIL
				this.direction = true
				this.tail.on()
			} else if (this.head.state === 0 && this.tail.state === 1) {
				// ON TAIL -> HEAD
				this.direction = false
				this.head.on()
			}
		} else {
			// WITHOUT BUTTON

			if (this.head.state === 1 && this.tail.state === 1) {
				// ON HEAD -><- TAIL
			} else if ((this.head.state === 1 && this.lastHeadState === 0) && this.tail.state === 0) {
				// ON HEAD -> TAIL
				this.direction = true
				this.tail.on()
			} else if (this.head.state === 0 && (this.tail.state === 1 && this.lastTailState === 0)) {
				// ON HEAD <- TAIL
				this.direction = false
				this.head.on()
			} else if ((this.head.state === 0 && this.lastHeadState === 1) && this.tail.state === 1) {
				// OFF HEAD -> TAIL
				if (this.direction) {
					this.direction = null
					this.tail.off()
				} else {
					this.direction = false
					this.head.on()
				}
			} else if (this.head.state === 1 && (this.tail.state === 0 && this.lastTailState === 1)) {
				// OFF HEAD <- TAIL
				if (this.direction === false) {
					this.direction = null
					this.head.off()
				} else {
					this.direction = true
					this.tail.on()
				}
			}
		}

		this.updateDepend('on')
	} else if (this.state === 0 || this.state === 2) {
		// OFF or REPAIR

		if (this.active) {
			this.active = false
			// BUTTON

			if (this.head.state === 1 && this.tail.state === 1) {

				if (this.direction) {
					// OFF HEAD <- TAIL
					this.direction = null
					this.tail.off()
				} else if (this.direction === false) {
					// OFF HEAD -> TAIL
					this.direction = null
					this.head.off()
				} else if (this.direction === null) {
					this.tail.off()
				}
			}
		}
		this.updateDepend('off')
	}
	this.active = false
	this.lastHeadState = this.head.state
	this.lastTailState = this.tail.state
}
