import {configGenerator, LineType, SwitchAddType, SwitchType} from "../../components/scada-elements/configGenerator";


const TPS_ID = 1

const other = [
	{length: 0}, // alwaysOn
	{left: 18, top: 67, imageLine: {type: LineType.TireLine, height: 30, width: 1247},
		points: [71, 287, 506, 720, 947, 1154]
	}, // SH1_10KV
	{left: 1317, top: 67, imageLine: {type: LineType.TireLine, height: 30, width: 1247},
		points: [73, 287, 505, 720, 947, 1150]
	}, // SH2_10KV
	{left: 18, top: 1324, imageLine: {type: LineType.TireLine, height: 30, width: 706},
		points: [70, 180, 288, 397, 505, 613], isBlue: true
	}, // -600V
	{left: 756, top: 1324, imageLine: {type: LineType.TireLine, height: 30, width: 2113},
		points: [255, 472, 688, 1121, 1338, 1554, 1771]
	}, // BACKUP_600V
	{left: 756, top: 1405, imageLine: {type: LineType.TireLine, height: 30, width: 2113},
		points: [71, 288, 505, 721, 937, 1154, 1371, 1587, 1804, 2020]
	}, // WORK_600V
	{left: 1866, top: 766, imageLine: {type: LineType.TireLine, height: 30, width: 273},
		points: [71, 180]
	}, // SH1_400V
	{left: 2191, top: 766, imageLine: {type: LineType.TireLine, height: 30, width: 273},
		points: [71, 180]
	}, // SH2_400V
]

const lines0 = [
	{length: 61, container: {id: 'branch-0.1', order: 3}},
	{imageLine: {type: LineType.MVLine, height: 90, width: 145}, container: {id: 'branch-0.1', order: 8},
		addLines: [
			{imageLine: {type: LineType.HRLine}, container: {id: 'branch-0.1', order: 6}},
			{length: 145, container: {id: 'branch-0.1', order: 5}},
			{length: 38, container: {id: 'branch-0.1', order: 7}},
		]
	},
	{length: 62, container: {id: 'branch-0.1', order: 1}}, // splice
]

const lines1 = [
	// TSN-2 part start
	{imageLine: {type: LineType.MVLine, height: 90, width: 145}, container: {id: 'branch-1.1', order: 8},
		addLines: [
			{length: 38, container: {id: 'branch-1.1', order: 7}},
			{imageLine: {type: LineType.HRLine}, container: {id: 'branch-1.1', order: 6}},
			{length: 145, container: {id: 'branch-1.1', order: 5}},
			{left: 2266, top: 399, length: 80, position: 'h'},
			{left: 2346, top: 149, length: 254},
			{left: 2346, top: 149, length: 136, position: 'h'},
			{length: 172, container: {id: 'branch-1.2', order: 1}},
			{length: 586, container: {id: 'branch-1.2', order: 3}},
			{left: 2381, top: 1097, length: 103, position: 'h'},
			{length: 139, container: {id: 'branch-1.3', order: 5}},
		]
	},
	{length: 48, container: {id: 'branch-1.3', order: 3}},
	{length: 28, container: {id: 'branch-1.3', order: 1}}, // splice
	// TSN-2 part end
	{length: 59, container: {id: 'branch-1.1', order: 3}},
	{length: 64, container: {id: 'branch-1.1', order: 1}}, // splice
]

const lines3 = [
	// TSN-1 part start
	{imageLine: {type: LineType.MVLine, height: 90, width: 145}, container: {id: 'branch-3.1', order: 8},
		addLines: [
			{length: 48, container: {id: 'branch-3.1', order: 7}},
			{imageLine: {type: LineType.HRLine}, container: {id: 'branch-3.1', order: 6}},
			{length: 145, container: {id: 'branch-3.1', order: 5}},
			{left: 101, top: 403, length: 80, position: 'h'},
			{left: 178, top: 149, length: 256},
			{left: 182, top: 149, length: 134, position: 'h'},
			{length: 172, container: {id: 'branch-3.2', order: 1}},
			{length: 53, container: {id: 'branch-3.2', order: 3}},
			{left: 316, top: 565, length: 1479, position: 'h'},
			{left: 1793, top: 565, length: 533},
			{left: 1793, top: 1096, length: 157, position: 'h'},
			{length: 95, container: {id: 'branch-3.3', order: 5}},
		]
	},
	{length: 70, container: {id: 'branch-3.3', order: 3}},
	{length: 45, container: {id: 'branch-3.3', order: 1}}, // splice
	// TSN-1 part end
	{length: 47, container: {id: 'branch-3.1', order: 3}},
	{length: 72, container: {id: 'branch-3.1', order: 1}}, // splice
]

const lines5 = [
	{length: 57, container: {id: 'branch-5.1', order: 3}},
	{length: 448, container: {id: 'branch-5.1', order: 5},
		addLines: [
			{imageLine: {type: LineType.TLine}, container: {id: 'branch-5.1', order: 6}},
			{length: 60, container: {id: 'additional-5', order: 3}, position: 'h'},
			{length: 35, container: {id: 'branch-5.1', order: 7}},
			{imageLine: {type: LineType.AGRLine, height: 90, width: 60}, container: {id: 'branch-5.1', order: 8}},
			{length: 100, container: {id: 'branch-5.1', order: 9}},
			{left: 534, top: 1179, length: 2218, position: 'h'},
			{left: 2752, top: 1178, length: 380},
		]
	},
	{length: 40, container: {id: 'down-left-5-agr1', order: 1}},
	{length: 47, container: {id: 'down-left-5-agr1', order: 3},
		addLines: [
			{length: 36, container: {id: 'branch-5-agr1', order: 4}, position: 'h'},
			{length: 121, container: {id: 'down-right-5-agr1', order: 1}},
		]
	},
	{length: 121, container: {id: 'up-right-5-agr1', order: 1}}, // splice
	{length: 72, container: {id: 'branch-5.1', order: 1}}, // splice
]

const lines6 = [
	{length: 57, container: {id: 'branch-6.1', order: 3}},
	{length: 361, container: {id: 'branch-6.1', order: 5},
		addLines: [
			{left: 749, top: 677, length: 116, position: 'h'},
			{length: 69, container: {id: 'branch-6.2', order: 1}},
			{imageLine: {type: LineType.TLine}, container: {id: 'branch-6.2', order: 2}},
			{length: 48, container: {id: 'additional-6', order: 3}, position: 'h'},
			{length: 35, container: {id: 'branch-6.2', order: 3}},
			{imageLine: {type: LineType.AGRLine, height: 90, width: 60}, container: {id: 'branch-6.2', order: 4}},
			{length: 174, container: {id: 'branch-6.2', order: 5}},
			{left: 867, top: 1233, length: 806, position: 'h'},
			{left: 1671, top: 1233, length: 330},
		]
	},
	{length: 40, container: {id: 'down-left-6-agr2', order: 1}},
	{length: 47, container: {id: 'down-left-6-agr2', order: 3},
		addLines: [
			{length: 36, container: {id: 'branch-6-agr2', order: 4}, position: 'h'},
			{length: 121, container: {id: 'down-right-6-agr2', order: 1}},
		]
	},
	{length: 121, container: {id: 'up-right-6-agr2', order: 1}}, // splice
	{length: 72, container: {id: 'branch-6.1', order: 1}}, // splice
]

const lines7 = [
	{length: 118, container: {id: 'branch-7', order: 3},
		addLines: [
			{imageLine: {type: LineType.TLine}, container: {id: 'branch-7', order: 4}},
		]
	},
	{length: 64, container: {id: 'branch-7', order: 1}}, // splice
]

const lines8 = [
	{length: 67, container: {id: 'branch-8', order: 3},
		addLines: [
			{left: 1182, top: 277, length: 220, position: 'h'},
			{left: 1399, top: 93, length: 184}
		]
	}, // splice
	{length: 72, container: {id: 'branch-8', order: 1}}, // splice
]

const lines9 = [
	{length: 56, container: {id: 'branch-9', order: 3}},
	{length: 428, container: {id: 'branch-9', order: 5},
		addLines: [
			{imageLine: {type: LineType.TLine}, container: {id: 'branch-9', order: 6}},
			{length: 50, container: {id: 'additional-9', order: 3}, position: 'h'},
			{length: 25, container: {id: 'branch-9', order: 7}},
			{imageLine: {type: LineType.AGRLine}, container: {id: 'branch-9', order: 8}},
			{length: 237, container: {id: 'branch-9', order: 9}},
			{left: 805, top: 1287, length: 810, position: 'h'},
			{left: 805, top: 1287, length: 277},
		]
	},
	{length: 40, container: {id: 'down-left-9-agr3', order: 1}},
	{length: 47, container: {id: 'down-left-9-agr3', order: 3},
		addLines:[
			{length: 36, container: {id: 'branch-9-agr3', order: 4}, position: 'h'},
			{length: 121, container: {id: 'down-right-9-agr3', order: 1}},
		]
	},
	{length: 121, container: {id: 'up-right-9-agr3', order: 1}}, // splice
	{length: 64, container: {id: 'branch-9', order: 1}}, // splice
]

const lines10 = [
	{length: 118, container: {id: 'branch-10', order: 3},
		addLines: [
			{imageLine: {type: LineType.TLine}, container: {id: 'branch-10', order: 4}},
		]
	},
	{length: 64, container: {id: 'branch-10', order: 1}}, // splice
]

const lines111 = [
	{length: 121, container: {id: 'down-right-111', order: 1},
		addLines: [
			{length: 36, container: {id: 'branch-111', order: 4}, position: 'h'},
			{length: 47, container: {id: 'down-left-111', order: 3}}
		]
	},
	{length: 45, container: {id: 'up-left-111', order: 3},
		addLines: [
			{length: 40, container: {id: 'down-left-111', order: 1}},
			{left: 883, top: 1541, length: 138, position: 'h'},
			{length: 178, container: {id: 'left-branch-111', order: 1}},
			{imageLine: {type: LineType.TriangleLine}, container: {id: 'left-branch-111', order: 2}}
		]
	},

	{length: 121, container: {id: 'up-right-111', order: 1}}, // splice
	{length: 117, container: {id: 'up-left-111', order: 1}}, // splice
]

const lines112 = [
	{length: 121, container: {id: 'down-right-112', order: 1},
		addLines: [
			{length: 36, container: {id: 'branch-112', order: 4}, position: 'h'},
			{length: 47, container: {id: 'down-left-112', order: 3}}
		]
	},
	{length: 45, container: {id: 'up-left-112', order: 3},
		addLines: [
			{length: 40, container: {id: 'down-left-112', order: 1}},
			{left: 1099, top: 1541, length: 138, position: 'h'},
			{length: 178, container: {id: 'left-branch-112', order: 1}},
			{imageLine: {type: LineType.TriangleLine}, container: {id: 'left-branch-112', order: 2}}
		]
	},

	{length: 121, container: {id: 'up-right-112', order: 1}}, // splice
	{length: 117, container: {id: 'up-left-112', order: 1}}, // splice
]

const lines113 = [
	{length: 121, container: {id: 'down-right-113', order: 1},
		addLines: [
			{length: 36, container: {id: 'branch-113', order: 4}, position: 'h'},
			{length: 47, container: {id: 'down-left-113', order: 3}}
		]
	},
	{length: 45, container: {id: 'up-left-113', order: 3},
		addLines: [
			{length: 40, container: {id: 'down-left-113', order: 1}},
			{left: 1315, top: 1541, length: 138, position: 'h'},
			{length: 178, container: {id: 'left-branch-113', order: 1}},
			{imageLine: {type: LineType.TriangleLine}, container: {id: 'left-branch-113', order: 2}}
		]
	},

	{length: 121, container: {id: 'up-right-113', order: 1}}, // splice
	{length: 117, container: {id: 'up-left-113', order: 1}}, // splice
]

const lines114 = [
	{length: 121, container: {id: 'down-right-114', order: 1},
		addLines: [
			{length: 36, container: {id: 'branch-114', order: 4}, position: 'h'},
			{length: 47, container: {id: 'down-left-114', order: 3}}
		]
	},
	{length: 45, container: {id: 'up-left-114', order: 3},
		addLines: [
			{length: 40, container: {id: 'down-left-114', order: 1}},
			{left: 1966, top: 1541, length: 138, position: 'h'},
			{length: 178, container: {id: 'left-branch-114', order: 1}},
			{imageLine: {type: LineType.TriangleLine}, container: {id: 'left-branch-114', order: 2}}
		]
	},

	{length: 121, container: {id: 'up-right-114', order: 1}}, // splice
	{length: 117, container: {id: 'up-left-114', order: 1}}, // splice
]

const lines115 = [
	{length: 121, container: {id: 'down-right-115', order: 1},
		addLines: [
			{length: 36, container: {id: 'branch-115', order: 4}, position: 'h'},
			{length: 47, container: {id: 'down-left-115', order: 3}}
		]
	},
	{length: 45, container: {id: 'up-left-115', order: 3},
		addLines: [
			{length: 40, container: {id: 'down-left-115', order: 1}},
			{left: 2182, top: 1541, length: 138, position: 'h'},
			{length: 178, container: {id: 'left-branch-115', order: 1}},
			{imageLine: {type: LineType.TriangleLine}, container: {id: 'left-branch-115', order: 2}}
		]
	},

	{length: 121, container: {id: 'up-right-115', order: 1}}, // splice
	{length: 117, container: {id: 'up-left-115', order: 1}}, // splice
]

const lines116 = [
	{length: 121, container: {id: 'down-right-116', order: 1},
		addLines: [
			{length: 36, container: {id: 'branch-116', order: 4}, position: 'h'},
			{length: 47, container: {id: 'down-left-116', order: 3}}
		]
	},
	{length: 45, container: {id: 'up-left-116', order: 3},
		addLines: [
			{length: 40, container: {id: 'down-left-116', order: 1}},
			{left: 2398, top: 1541, length: 138, position: 'h'},
			{length: 178, container: {id: 'left-branch-116', order: 1}},
			{imageLine: {type: LineType.TriangleLine}, container: {id: 'left-branch-116', order: 2}}
		]
	},

	{length: 121, container: {id: 'up-right-116', order: 1}}, // splice
	{length: 117, container: {id: 'up-left-116', order: 1}}, // splice
]

const linesBackup = [
	{length: 121, container: {id: 'down-right-backupBranch', order: 1},
		addLines: [
			{length: 36, container: {id: 'backupBranch', order: 4}, position: 'h'},
			{length: 47, container: {id: 'down-left-backupBranch', order: 3}},
		]
	},
	{length: 121, container: {id: 'up-right-backupBranch', order: 1}}, // splice
	{length: 210, container: {id: 'up-left-backupBranch', order: 1},
		addLines: [
			{length: 40, container: {id: 'down-left-backupBranch', order: 1}}
		]
	}, // splice
]

const linesSHSN = [
	{length: 39, container: {id: 'branch-SHSN', order: 3},
		addLines: [
			{left: 2056, top: 922, length: 218, position: 'h'},
			{left: 2274, top: 795, length: 132}
		]
	}, // splice
	{length: 47, container: {id: 'branch-SHSN', order: 1}},
]

const additionalLines = [
	// 5
	{length: 60, container: {id: 'additional-5', order: 1}, position: 'h', isBlue: true}, // splice
	// 6
	{length: 60, container: {id: 'additional-6', order: 1}, position: 'h', isBlue: true}, // splice
	// 9
	{length: 60, container: {id: 'additional-9', order: 1}, position: 'h', isBlue: true}, // splice
	// 111
	{length: 73, container: {id: 'additional-111', order: 1}, isBlue: true}, // splice
	{length: 47, container: {id: 'additional-111', order: 3},
		addLines: [{imageLine: {type: LineType.TriangleLine}, isBlue: true, container: {id: 'additional-111', order: 4}}], isBlue: true
	},
	// 112
	{length: 73, container: {id: 'additional-112', order: 1}, isBlue: true}, // splice
	{length: 47, container: {id: 'additional-112', order: 3},
		addLines: [{imageLine: {type: LineType.TriangleLine}, isBlue: true, container: {id: 'additional-112', order: 4}}], isBlue: true
	},
	// 113
	{length: 73, container: {id: 'additional-113', order: 1}, isBlue: true}, // splice
	{length: 47, container: {id: 'additional-113', order: 3},
		addLines: [	{imageLine: {type: LineType.TriangleLine}, isBlue: true, container: {id: 'additional-113', order: 4}}], isBlue: true
	},
	// 114
	{length: 73, container: {id: 'additional-114', order: 1}, isBlue: true}, // splice
	{length: 47, container: {id: 'additional-114', order: 3},
		addLines: [	{imageLine: {type: LineType.TriangleLine}, isBlue: true, container: {id: 'additional-114', order: 4}}], isBlue: true
	},
	// 115
	{length: 73, container: {id: 'additional-115', order: 1}, isBlue: true}, // splice
	{length: 47, container: {id: 'additional-115', order: 3},
		addLines: [	{imageLine: {type: LineType.TriangleLine},isBlue: true, container: {id: 'additional-115', order: 4}}], isBlue: true
	},
	// 116
	{length: 73, container: {id: 'additional-116', order: 1}, isBlue: true}, // splice
	{length: 47, container: {id: 'additional-116', order: 3},
		addLines: [	{imageLine: {type: LineType.TriangleLine}, isBlue: true, container: {id: 'additional-116', order: 4}}], isBlue: true
	},
]

other[1].addLines = [
	lines3.splice(-1, 1)[0],
	lines5.splice(-1, 1)[0],
	lines6.splice(-1, 1)[0],
	lines7.splice(-1, 1)[0],
	lines8.splice(-1, 1)[0],
]
other[2].addLines = [
	lines0.splice(-1, 1)[0],
	lines1.splice(-1, 1)[0],
	lines8.splice(-1, 1)[0],
	lines9.splice(-1, 1)[0],
	lines10.splice(-1, 1)[0],
]
other[3].addLines = [
	{left: 316, top: 626, length: 698, isBlue: true},
	{left: 316, top: 626, length: 1103, position: 'h', isBlue: true},
	{left: 667, top: 626, length: 277, isBlue: true},
	{left: 1418, top: 626, length: 277, isBlue: true},
	additionalLines.splice(13, 1)[0],
	additionalLines.splice(11, 1)[0],
	additionalLines.splice(9, 1)[0],
	additionalLines.splice(7, 1)[0],
	additionalLines.splice(5, 1)[0],
	additionalLines.splice(3, 1)[0],
	additionalLines.splice(2, 1)[0],
	additionalLines.splice(1, 1)[0],
	additionalLines.splice(0, 1)[0],
]
other[4].addLines = [
	lines111.splice(-1, 1)[0],
	lines112.splice(-1, 1)[0],
	lines113.splice(-1, 1)[0],
	lines114.splice(-1, 1)[0],
	lines115.splice(-1, 1)[0],
	lines116.splice(-1, 1)[0],
	linesBackup.splice(-1, 1)[0],
]
other[5].addLines = [
	lines111.splice(-1, 1)[0],
	lines112.splice(-1, 1)[0],
	lines113.splice(-1, 1)[0],
	lines114.splice(-1, 1)[0],
	lines115.splice(-1, 1)[0],
	lines116.splice(-1, 1)[0],
	lines5.splice(-1, 1)[0],
	lines6.splice(-1, 1)[0],
	lines9.splice(-1, 1)[0],
	linesBackup.splice(-1, 1)[0],
]
other[6].addLines = [
	lines3.splice(-2, 1)[0],
	linesSHSN.splice(-1, 1)[0],
]
other[7].addLines = [
	linesSHSN.splice(-1, 1)[0],
	lines1.splice(-2, 1)[0],

]

const branch0 = [
	{type: SwitchType.HRSwitch, id: 'hr0', name: 'ШР0', head: {group: other, number: 3},
		tail: {group: lines0, number: 1}, container: {id: 'branch-0.1', order: 2}, isOnlySchemas: true,
	},
	{type: SwitchType.MVSwitch, id: 'vm0', name: 'МВ0', head: {group: lines0, number: 1},
		tail: {group: lines0, number: 2}, container: {id: 'branch-0.1', order: 4}, isOnlySchemas: true,
	},
]

const branch1 = [
	{type: SwitchType.TSNSwitch, id: 'tsn2', name: 'ТСН-2', head: {group: other, number: 1},
		tail: {group: lines1, number: 1}, container: {id: 'branch-1.2', order: 2}, isNotInteractive: true
	},
	{type: SwitchType.HRSwitch, id: 'p2', name: 'П2', head: {group: lines1, number: 1},
		tail: {group: lines1, number: 2}, container: {id: 'branch-1.3', order: 4},
	},
	{type: SwitchType.HRSwitch, id: 'r32', name: 'Р32', head: {group: lines1, number: 2},
		tail: {group: other, number: 8}, container: {id: 'branch-1.3', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.OneWayDuplexSwitch,
	},
	{type: SwitchType.MVSwitch, id: 'vm1', name: 'МВ1', head: {group: lines1, number: 1},
		tail: {group: lines1, number: 3}, container: {id: 'branch-1.1', order: 4},
		addType: SwitchAddType.DuplexSwitch,
	},
	{type: SwitchType.HRSwitch, id: 'shr1', name: 'ШР1', head: {group: lines1, number: 3},
		tail: {group: other, number: 3}, container: {id: 'branch-1.1', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.OneWayDuplexSwitch,
	},
]

const branch3 = [
	// TSN-1 part start
	{type: SwitchType.TSNSwitch, id: 'tsn1', name: 'ТСН-1', head: {group: other, number: 1},
		tail: {group: lines3, number: 1}, container: {id: 'branch-3.2', order: 2}, isNotInteractive: true
	},
	{type: SwitchType.HRSwitch, id: 'p1', name: 'П1', head: {group: lines3, number: 1},
		tail: {group: lines3, number: 2}, container: {id: 'branch-3.3', order: 4},
	},
	{type: SwitchType.HRSwitch, id: 'r31', name: 'Р31', head: {group: lines3, number: 2},
		tail: {group: other, number: 7}, container: {id: 'branch-3.3', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.OneWayDuplexSwitch,
	},
	// TSN-1 part end
	{type: SwitchType.MVSwitch, id: 'vm3', name: 'МВ3', head: {group: lines3, number: 1},
		tail: {group: lines3, number: 3}, container: {id: 'branch-3.1', order: 4},
		addType: SwitchAddType.DuplexSwitch,
	},
	{type: SwitchType.HRSwitch, id: 'shr3', name: 'ШР3', head: {group: lines3, number: 3},
		tail: {group: other, number: 2}, container: {id: 'branch-3.1', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.OneWayDuplexSwitch,
	},
]

const branch5 = [
	{type: SwitchType.HRSwitch, id: 'shr5', name: 'ШР5', head: {group: other, number: 2},
		tail: {group: lines5, number: 1}, container: {id: 'branch-5.1', order: 2}, isOnlySchemas: true,
	},
	{type: SwitchType.MVSwitch, id: 'vm5', name: 'МВ5', head: {group: lines5, number: 1},
		tail: {group: lines5, number: 2}, container: {id: 'branch-5.1', order: 4},
	},
	{type: SwitchType.HR2Switch, id: 'shr_b5', name: 'ШР', head: {group: lines5, number: 2},
		tail: {group: lines5, number: 3}, container: {id: 'branch-5-agr1', order: 2}, isOnlySchemas: true,
		logName: 'ШР КА№1', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'ka1', name: 'KA №1', head: {group: lines5, number: 3},
		tail: {group: other, number: 6}, container: {id: 'down-left-5-agr1', order: 2}, isNotInteractive: true,
		addType: SwitchAddType.GroupSwitch, group: '2', depLine: {group: lines5, number: 4},
	},
]

const branch6 = [
	{type: SwitchType.HRSwitch, id: 'shr6', name: 'ШР6', head: {group: other, number: 2},
		tail: {group: lines6, number: 1}, container: {id: 'branch-6.1', order: 2}, isOnlySchemas: true,
	},
	{type: SwitchType.MVSwitch, id: 'vm6', name: 'МВ6', head: {group: lines6, number: 1},
		tail: {group: lines6, number: 2}, container: {id: 'branch-6.1', order: 4},
	},
	{type: SwitchType.HR2Switch, id: 'shr_b6', name: 'ШР', head: {group: lines6, number: 2},
		tail: {group: lines6, number: 3}, container: {id: 'branch-6-agr2', order: 2}, isOnlySchemas: true,
		logName: 'ШР КА№2', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'ka2', name: 'KA №2', head: {group: lines6, number: 3},
		tail: {group: other, number: 6}, container: {id: 'down-left-6-agr2', order: 2}, isNotInteractive: true,
		addType: SwitchAddType.GroupSwitch, group: '2', depLine: {group: lines6, number: 4},
	},
]

const branch7 = [
	{type: SwitchType.HRSwitch, id: 'shr7', name: 'ШР7', head: {group: other, number: 1},
		tail: {group: other, number: 2}, container: {id: 'branch-7', order: 2}, isNotInteractive: true,
		depLine: {group: lines7, number: 1}
	},
]

const branch8 = [
	{type: SwitchType.HRSwitch, id: 'shr8', name: 'ШР8', head: {group: other, number: 2},
		tail: {group: other, number: 3}, container: {id: 'branch-8', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.AlarmDuplexSwitch,
	},
]

const branch9 = [
	{type: SwitchType.HRSwitch, id: 'shr9', name: 'ШР9', head: {group: other, number: 3},
		tail: {group: lines9, number: 1}, container: {id: 'branch-9', order: 2}, isOnlySchemas: true,
	},
	{type: SwitchType.MVSwitch, id: 'vm9', name: 'МВ9', head: {group: lines9, number: 1},
		tail: {group: lines9, number: 2}, container: {id: 'branch-9', order: 4},
	},
	{type: SwitchType.HR2Switch, id: 'shr_b9', name: 'ШР', head: {group: lines9, number: 2},
		tail: {group: lines9, number: 3}, container: {id: 'branch-9-agr3', order: 2}, isOnlySchemas: true,
		logName: 'ШР КА№3', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'ka3', name: 'KA №3', head: {group: lines9, number: 3},
		tail: {group: other, number: 6}, container: {id: 'down-left-9-agr3', order: 2}, isNotInteractive: true,
		addType: SwitchAddType.GroupSwitch, group: '2', depLine: {group: lines9, number: 4}
	},
]

const branch10 = [
	{type: SwitchType.HRSwitch, id: 'shr10', name: 'ШР10', head: {group: other, number: 1},
		tail: {group: other, number: 3}, container: {id: 'branch-10', order: 2}, isNotInteractive: true,
		depLine: {group: lines10, number: 1}
	},
]

const branch111 = [
	{type: SwitchType.HR2Switch, id: 'r2-111', name: 'Р2', head: {group: other, number: 6},
		tail: {group: lines111, number: 1}, container: {id: 'branch-111', order: 2}, isOnlySchemas: true,
		logName: 'Р2 ЛВ111', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'lv111', name: 'ЛВ111', head: {group: lines111, number: 1},
		tail: {group: lines111, number: 2}, container: {id: 'down-left-111', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.GroupSwitch, group: '111'
	},
	{type: SwitchType.HRSwitch, id: 'r1-111', name: 'Р1', head: {group: other, number: 5},
		tail: {group: lines111, number: 2}, container: {id: 'up-left-111', order: 2}, isOnlySchemas: true,
		logName: 'Р1 ЛВ111', addType: SwitchAddType.GroupSwitch, group: '111'
	},
]

const branch112 = [
	{type: SwitchType.HR2Switch, id: 'r2-112', name: 'Р2', head: {group: other, number: 6},
		tail: {group: lines112, number: 1}, container: {id: 'branch-112', order: 2}, isNotInteractive: true,
		logName: 'Р2 ЛВ112', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'lv112', name: 'ЛВ112', head: {group: lines112, number: 1},
		tail: {group: lines112, number: 2}, container: {id: 'down-left-112', order: 2},
		addType: SwitchAddType.GroupSwitch, group: '112'
	},
	{type: SwitchType.HRSwitch, id: 'r1-112', name: 'Р1', head: {group: other, number: 5},
		tail: {group: lines112, number: 2}, container: {id: 'up-left-112', order: 2},
		logName: 'Р1 ЛВ112', addType: SwitchAddType.GroupSwitch, group: '112'
	},
]

const branch113 = [
	{type: SwitchType.HR2Switch, id: 'r2-113', name: 'Р2', head: {group: other, number: 6},
		tail: {group: lines113, number: 1}, container: {id: 'branch-113', order: 2}, isNotInteractive: true,
		logName: 'Р2 ЛВ113', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'lv113', name: 'ЛВ113', head: {group: lines113, number: 1},
		tail: {group: lines113, number: 2}, container: {id: 'down-left-113', order: 2},
		addType: SwitchAddType.GroupSwitch, group: '113'
	},
	{type: SwitchType.HRSwitch, id: 'r1-113', name: 'Р1', head: {group: other, number: 5},
		tail: {group: lines113, number: 2}, container: {id: 'up-left-113', order: 2},
		logName: 'Р1 ЛВ113', addType: SwitchAddType.GroupSwitch, group: '113'
	},
]

const branch114 = [
	{type: SwitchType.HR2Switch, id: 'r2-114', name: 'Р2', head: {group: other, number: 6},
		tail: {group: lines114, number: 1}, container: {id: 'branch-114', order: 2}, isNotInteractive: true,
		logName: 'Р2 ЛВ114', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'lv114', name: 'ЛВ114', head: {group: lines114, number: 1},
		tail: {group: lines114, number: 2}, container: {id: 'down-left-114', order: 2},
		addType: SwitchAddType.GroupSwitch, group: '114'
	},
	{type: SwitchType.HRSwitch, id: 'r1-114', name: 'Р1', head: {group: other, number: 5},
		tail: {group: lines114, number: 2}, container: {id: 'up-left-114', order: 2},
		logName: 'Р1 ЛВ114', addType: SwitchAddType.GroupSwitch, group: '114'
	},
]

const branch115 = [
	{type: SwitchType.HR2Switch, id: 'r2-115', name: 'Р2', head: {group: other, number: 6},
		tail: {group: lines115, number: 1}, container: {id: 'branch-115', order: 2}, isNotInteractive: true,
		logName: 'Р2 ЛВ115', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'lv115', name: 'ЛВ115', head: {group: lines115, number: 1},
		tail: {group: lines115, number: 2}, container: {id: 'down-left-115', order: 2},
		addType: SwitchAddType.GroupSwitch, group: '115'
	},
	{type: SwitchType.HRSwitch, id: 'r1-115', name: 'Р1', head: {group: other, number: 5},
		tail: {group: lines115, number: 2}, container: {id: 'up-left-115', order: 2},
		logName: 'Р1 ЛВ115', addType: SwitchAddType.GroupSwitch, group: '115'
	},
]

const branch116 = [
	{type: SwitchType.HR2Switch, id: 'r2-116', name: 'Р2', head: {group: other, number: 6},
		tail: {group: lines116, number: 1}, container: {id: 'branch-116', order: 2}, isNotInteractive: true,
		logName: 'Р2 ЛВ116', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'lv116', name: 'ЛВ116', head: {group: lines116, number: 1},
		tail: {group: lines116, number: 2}, container: {id: 'down-left-116', order: 2},
		addType: SwitchAddType.GroupSwitch, group: '116'
	},
	{type: SwitchType.HRSwitch, id: 'r1-116', name: 'Р1', head: {group: other, number: 5},
		tail: {group: lines116, number: 2}, container: {id: 'up-left-116', order: 2},
		logName: 'Р1 ЛВ116', addType: SwitchAddType.GroupSwitch, group: '116'
	},
]

const backupBranch = [
	{type: SwitchType.HR2Switch, id: 'shr-bu', name: 'ШР', head: {group: other, number: 6},
		tail: {group: linesBackup, number: 1}, container: {id: 'backupBranch', order: 2}, isNotInteractive: true,
		logName: 'Запасний ШР', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'zv-bu', name: 'ЗВ', head: {group: linesBackup, number: 1},
		tail: {group: other, number: 5}, container: {id: 'down-left-backupBranch', order: 2},
		logName: 'Запасний ЗВ'
	},
]

const branchSHSN = [
	{type: SwitchType.HRSwitch, id: 'r33', name: 'Р33', head: {group: other, number: 7},
		tail: {group: other, number: 8}, container: {id: 'branch-SHSN', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.AlarmDuplexSwitch,
	},
]

const additionalBranch = [
	// 5
	{type: SwitchType.HRSwitch, id: 'rlv01-5', name: 'РЛВ01', head: {group: other, number: 1},
		tail: {group: other, number: 4}, container: {id: 'additional-5', order: 2}, isOnlySchemas: true,
		logName: 'РЛВ01 КА№1', addType: SwitchAddType.GroupSwitch, group: '-600V', position: 'h', isBlue: true,
	},
	// 6
	{type: SwitchType.HRSwitch, id: 'rlv01-6', name: 'РЛВ01', head: {group: other, number: 1},
		tail: {group: other, number: 4}, container: {id: 'additional-6', order: 2}, isOnlySchemas: true,
		logName: 'РЛВ01 КА№2', addType: SwitchAddType.GroupSwitch, group: '-600V', position: 'h', isBlue: true,
	},
	// 9
	{type: SwitchType.HRSwitch, id: 'rlv01-9', name: 'РЛВ01', head: {group: other, number: 1},
		tail: {group: other, number: 4}, container: {id: 'additional-9', order: 2}, isOnlySchemas: true,
		logName: 'РЛВ01 КА№3', addType: SwitchAddType.GroupSwitch, group: '-600V', position: 'h', isBlue: true,
	},
	//
	{type: SwitchType.HRSwitch, id: 'r111', name: 'Р111', head: {group: other, number: 4},
		tail: {group: additionalLines, number: 1}, container: {id: 'additional-111', order: 2}, isOnlySchemas: true,
		isBlue: true,
	},
	{type: SwitchType.HRSwitch, id: 'r112', name: 'Р112', head: {group: other, number: 4},
		tail: {group: additionalLines, number: 2}, container: {id: 'additional-112', order: 2}, isOnlySchemas: true,
		isBlue: true,
	},
	{type: SwitchType.HRSwitch, id: 'r113', name: 'Р113', head: {group: other, number: 4},
		tail: {group: additionalLines, number: 3}, container: {id: 'additional-113', order: 2}, isOnlySchemas: true,
		isBlue: true,
	},
	{type: SwitchType.HRSwitch, id: 'r114', name: 'Р114', head: {group: other, number: 4},
		tail: {group: additionalLines, number: 4}, container: {id: 'additional-114', order: 2}, isOnlySchemas: true,
		isBlue: true,
	},
	{type: SwitchType.HRSwitch, id: 'r115', name: 'Р115', head: {group: other, number: 4},
		tail: {group: additionalLines, number: 5}, container: {id: 'additional-115', order: 2}, isOnlySchemas: true,
		isBlue: true,
	},
	{type: SwitchType.HRSwitch, id: 'r116', name: 'Р116', head: {group: other, number: 4},
		tail: {group: additionalLines, number: 6}, container: {id: 'additional-116', order: 2}, isOnlySchemas: true,
		isBlue: true,
	}
]

const containers = {
	'branch-0.1': {left: 2049, top: 96, height: 570, width: 1},

	'branch-1.1': {left: 2265, top: 96, height: 570, width: 1},
	'branch-1.2': {left: 2482, top: 149, height: 960, width: 1},
	'branch-1.3': {left: 2381, top: 796, height: 310, width: 1},

	'branch-SHSN': {left: 2056, top: 795, height: 150, width: 1},

	'branch-3.1': {left: 100, top: 96, height: 570, width: 1},
	'branch-3.2': {left: 317, top: 149, height: 450, width: 1},
	'branch-3.3': {left: 1948, top: 796, height: 320, width: 1},

	'branch-5.1': {left: 534, top: 96, height: 1100, width: 1},

	'branch-6.1': {left: 749, top: 96, height: 600, width: 1},
	'branch-6.2': {left: 865, top: 677, height: 580, width: 1},

	'branch-7': {left: 970, top: 96, height: 446, width: 1},

	'branch-8': {left: 1182, top: 96, height: 200, width: 1},

	'branch-9': {left: 1615, top: 96, height: 1230, width: 1},

	'branch-10': {left: 1832, top: 96, height: 446, width: 1},

	'additional-5': {left: 317, top: 903, height: 1, width: 185, position: 'h'},
	'additional-6': {left: 667, top: 903, height: 1, width: 185, position: 'h'},
	'additional-9': {left: 1418, top: 903, height: 1, width: 185, position: 'h'},
	'additional-111': {left: 99, top: 1354, height: 200, width: 1},
	'additional-112': {left: 208, top: 1354, height: 200, width: 1},
	'additional-113': {left: 316, top: 1354, height: 200, width: 1},
	'additional-114': {left: 424, top: 1354, height: 200, width: 1},
	'additional-115': {left: 532, top: 1354, height: 200, width: 1},
	'additional-116': {left: 641, top: 1354, height: 200, width: 1},


	'branch-111': {left: 1038, top: 1354, height: 390, width: 1},
	'up-double-111': {height: 203, width: 34,
		double: {leftCont: 'up-left-111', rightCont: 'up-right-111'}, container: {id: 'branch-111', order: 1}
	},
	'up-left-111': {height: 203, width: 1},
	'up-right-111': {height: 121, width: 1},
	'down-double-111': {height: 121, width: 34,
		double: {leftCont: 'down-left-111', rightCont: 'down-right-111'}, container: {id: 'branch-111', order: 3}
	},
	'down-left-111': {height: 121, width: 1},
	'down-right-111': {height: 121, width: 1},
	'left-branch-111': {left: 883, top: 1541, height: 210, width: 1},

	'branch-112': {left: 1254, top: 1354, height: 390, width: 1},
	'up-double-112': {height: 203, width: 34,
		double: {leftCont: 'up-left-112', rightCont: 'up-right-112'}, container: {id: 'branch-112', order: 1}
	},
	'up-left-112': {height: 203, width: 1},
	'up-right-112': {height: 121, width: 1},
	'down-double-112': {height: 121, width: 34,
		double: {leftCont: 'down-left-112', rightCont: 'down-right-112'}, container: {id: 'branch-112', order: 3}
	},
	'down-left-112': {height: 121, width: 1},
	'down-right-112': {height: 121, width: 1},
	'left-branch-112': {left: 1099, top: 1541, height: 210, width: 1},

	'branch-113': {left: 1470, top: 1354, height: 390, width: 1},
	'up-double-113': {height: 203, width: 34,
		double: {leftCont: 'up-left-113', rightCont: 'up-right-113'}, container: {id: 'branch-113', order: 1}
	},
	'up-left-113': {height: 203, width: 1},
	'up-right-113': {height: 121, width: 1},
	'down-double-113': {height: 121, width: 34,
		double: {leftCont: 'down-left-113', rightCont: 'down-right-113'}, container: {id: 'branch-113', order: 3}
	},
	'down-left-113': {height: 121, width: 1},
	'down-right-113': {height: 121, width: 1},
	'left-branch-113': {left: 1315, top: 1541, height: 210, width: 1},

	'branch-114': {left: 2120, top: 1354, height: 390, width: 1},
	'up-double-114': {height: 203, width: 34,
		double: {leftCont: 'up-left-114', rightCont: 'up-right-114'}, container: {id: 'branch-114', order: 1}
	},
	'up-left-114': {height: 203, width: 1},
	'up-right-114': {height: 121, width: 1},
	'down-double-114': {height: 121, width: 34,
		double: {leftCont: 'down-left-114', rightCont: 'down-right-114'}, container: {id: 'branch-114', order: 3}
	},
	'down-left-114': {height: 121, width: 1},
	'down-right-114': {height: 121, width: 1},
	'left-branch-114': {left: 1966, top: 1541, height: 210, width: 1},

	'branch-115': {left: 2336, top: 1354, height: 390, width: 1},
	'up-double-115': {height: 203, width: 34,
		double: {leftCont: 'up-left-115', rightCont: 'up-right-115'}, container: {id: 'branch-115', order: 1}
	},
	'up-left-115': {height: 203, width: 1},
	'up-right-115': {height: 121, width: 1},
	'down-double-115': {height: 121, width: 34,
		double: {leftCont: 'down-left-115', rightCont: 'down-right-115'}, container: {id: 'branch-115', order: 3}
	},
	'down-left-115': {height: 121, width: 1},
	'down-right-115': {height: 121, width: 1},
	'left-branch-115': {left: 2182, top: 1541, height: 210, width: 1},

	'branch-116': {left: 2552, top: 1354, height: 390, width: 1},
	'up-double-116': {height: 203, width: 34,
		double: {leftCont: 'up-left-116', rightCont: 'up-right-116'}, container: {id: 'branch-116', order: 1}
	},
	'up-left-116': {height: 203, width: 1},
	'up-right-116': {height: 121, width: 1},
	'down-double-116': {height: 121, width: 34,
		double: {leftCont: 'down-left-116', rightCont: 'down-right-116'}, container: {id: 'branch-116', order: 3}
	},
	'down-left-116': {height: 121, width: 1},
	'down-right-116': {height: 121, width: 1},
	'left-branch-116': {left: 2398, top: 1541, height: 210, width: 1},

	'branch-9-agr3': {left: 823, top: 1354, height: 390, width: 1},
	'up-double-9-agr3': {height: 203, width: 34,
		double: {leftCont: 'up-left-9-agr3', rightCont: 'up-right-9-agr3'}, container: {id: 'branch-9-agr3', order: 1}
	},
	'up-left-9-agr3': {height: 203, width: 1},
	'up-right-9-agr3': {height: 121, width: 1},
	'down-double-9-agr3': {height: 121, width: 34,
		double: {leftCont: 'down-left-9-agr3', rightCont: 'down-right-9-agr3'}, container: {id: 'branch-9-agr3', order: 3}
	},
	'down-left-9-agr3': {height: 121, width: 1},
	'down-right-9-agr3': {height: 121, width: 1},

	'branch-6-agr2': {left: 1689, top: 1354, height: 390, width: 1},
	'up-double-6-agr2': {height: 203, width: 34,
		double: {leftCont: 'up-left-6-agr2', rightCont: 'up-right-6-agr2'}, container: {id: 'branch-6-agr2', order: 1}
	},
	'up-left-6-agr2': {height: 203, width: 1},
	'up-right-6-agr2': {height: 121, width: 1},
	'down-double-6-agr2': {height: 121, width: 34,
		double: {leftCont: 'down-left-6-agr2', rightCont: 'down-right-6-agr2'}, container: {id: 'branch-6-agr2', order: 3}
	},
	'down-left-6-agr2': {height: 121, width: 1},
	'down-right-6-agr2': {height: 121, width: 1},

	'backupBranch': {left: 1905, top: 1354, height: 390, width: 1},
	'up-double-backupBranch': {height: 203, width: 34,
		double: {leftCont: 'up-left-backupBranch', rightCont: 'up-right-backupBranch'}, container: {id: 'backupBranch', order: 1}
	},
	'up-left-backupBranch': {height: 203, width: 1},
	'up-right-backupBranch': {height: 121, width: 1},
	'down-double-backupBranch': {height: 121, width: 34,
		double: {leftCont: 'down-left-backupBranch', rightCont: 'down-right-backupBranch'}, container: {id: 'backupBranch', order: 3}
	},
	'down-left-backupBranch': {height: 121, width: 1},
	'down-right-backupBranch': {height: 121, width: 1},

	'branch-5-agr1': {left: 2770, top: 1354, height: 390, width: 1},
	'up-double-5-agr1': {height: 203, width: 34,
		double: {leftCont: 'up-left-5-agr1', rightCont: 'up-right-5-agr1'}, container: {id: 'branch-5-agr1', order: 1}
	},
	'up-left-5-agr1': {height: 203, width: 1},
	'up-right-5-agr1': {height: 121, width: 1},
	'down-double-5-agr1': {height: 121, width: 34,
		double: {leftCont: 'down-left-5-agr1', rightCont: 'down-right-5-agr1'}, container: {id: 'branch-5-agr1', order: 3}
	},
	'down-left-5-agr1': {height: 121, width: 1},
	'down-right-5-agr1': {height: 121, width: 1},
}

const text = [
	{text: '10кВ', left: 25, top: 25, fontSize: 1},
	{text: 'РУ-10кВ СШ №1', left: 433, top: 10, fontSize: 1.2},
	{text: 'РУ-10кВ СШ №2', left: 1809, top: 10, fontSize: 1.2},
	{text: '10кВ', left: 2492, top: 25, fontSize: 1},
	{text: 3, left: 75, top: 100},
	{text: 4, left: 291, top: 100},
	{text: 5, left: 508, top: 100},
	{text: 6, left: 724, top: 100},
	{text: 7, left: 941, top: 100},
	{text: 8, left: 1157, top: 100},
	{text: 9, left: 1590, top: 100},
	{text: 10, left: 1798, top: 100},
	{text: 0, left: 2024, top: 100},
	{text: 1, left: 2244, top: 100},
	{text: 2, left: 2457, top: 100},
	{text: '- 600B', left: 590, top: 1270, fontSize: 1.2},
	{text: 111, left: 54, top: 1358},
	{text: 112, left: 159, top: 1358},
	{text: 113, left: 266, top: 1358},
	{text: 114, left: 376, top: 1358},
	{text: 115, left: 486, top: 1358},
	{text: 116, left: 592, top: 1358},
	{text: 1, left: 815, top: 1440},
	{text: 2, left: 1032, top: 1440},
	{text: 3, left: 1249, top: 1440},
	{text: 4, left: 1463, top: 1440},
	{text: 5, left: 1680, top: 1440},
	{text: 6, left: 1900, top: 1440},
	{text: 7, left: 2115, top: 1440},
	{text: 8, left: 2330, top: 1440},
	{text: 9, left: 2547, top: 1440},
	{text: 'Лінія 111', left: 37, top: 1540, fontSize: 0.8, angle: -15},
	{text: 'Лінія 112', left: 142, top: 1540, fontSize: 0.8, angle: -15},
	{text: 'Лінія 113', left: 250, top: 1540, fontSize: 0.8, angle: -15},
	{text: 'Лінія 114', left: 358, top: 1540, fontSize: 0.8, angle: -15},
	{text: 'Лінія 115', left: 468, top: 1540, fontSize: 0.8, angle: -15},
	{text: 'Лінія 116', left: 575, top: 1540, fontSize: 0.8, angle: -15},
	{text: 'Агрегат №3', left: 722, top: 1740},
	{text: 'Лінія що відходить №111', left: 830, top: 1740, fontSize: 0.8, angle: -15},
	{text: 'Лінія що відходить №112', left: 1054, top: 1740, fontSize: 0.8, angle: -15},
	{text: 'Лінія що відходить №113', left: 1282, top: 1740, fontSize: 0.8, angle: -15},
	{text: 'Агрегат №2', left: 1619, top: 1740},
	{text: 'Запасний БА', left: 1815, top: 1740},
	{text: 'Лінія що відходить №114', left: 1901, top: 1740, fontSize: 0.8, angle: -15},
	{text: 'Лінія що відходить №115', left: 2138, top: 1740, fontSize: 0.8, angle: -15},
	{text: 'Лінія що відходить №116', left: 2363, top: 1740, fontSize: 0.8, angle: -15},
	{text: 'Агрегат №1', left: 2706, top: 1740},
	{text: 'Запасна', left: 2590, top: 1276, fontSize: 1.2},
	{text: 'шина +600В', left: 2762, top: 1276, fontSize: 1.2},
	{text: 'Робоча', left: 2608, top: 1356, fontSize: 1.2},
	{text: 'шина +600В', left: 2762, top: 1356, fontSize: 1.2},
	{text: '0.4кВ СШ№1', left: 1868, top: 724, fontSize: 1},
	{text: 'ЩСН', left: 2124, top: 683, fontSize: 1.2},
	{text: 'СШ№2 0.4кВ', left: 2260, top: 724, fontSize: 1},
	{text: 'Ввід №1', left: 60, top: 560},
	{text: 'с ТП-1 РЕС', left: 45, top: 595},
	{text: 'ТП-175', left: 2010, top: 573, fontSize: 0.9},
	{text: 'Ввід №2', left: 2226, top: 558},
	{text: 'с РП-6 РЕС', left: 2210, top: 593},
]

let config = {
	switches: [
		branch0, branch1, branch3, branch5, branch6, branch7, branch8, branch9, branch10, branch111,
		branch112, branch113, branch114, branch115, branch116, backupBranch, branchSHSN, additionalBranch,
	],
	lines: {
		other, lines0, lines1, lines3, lines5, lines6, lines7, lines8, lines9, lines10, lines111,
		lines112, lines113, lines114, lines115, lines116, linesBackup,linesSHSN, additionalLines,
	},
	containers,
	text
}

config = configGenerator(TPS_ID, config)
// console.log(`config tps ${TPS_ID}`, config)

const TPS1 = {
	id: TPS_ID,
	size: {width: 3020, height: 1850, minHeight: 800},
	// image: backgroundImage,
	isExist: true,
	lines: Object.values(config.lines),
	switches: [config.switches],
	containers: config.containers,
	activeLines: [other[0]],
	text: config.text
}

export default TPS1
