import React from 'react';
import classes from './UserSettingPage.module.css';
import TwoFactorAuth from "./two-factor-auth/TwoFactorAuth";
import {Divider, Typography} from "@mui/material";
import UserChangePass from "./user-change-pass/UserChangePass";


function UserSettingPage() {
	return (
		<div className={classes.container}>
			<div className={classes.wrapper}>
				<Typography variant='h5'>Налаштування аккаунта</Typography>
				<Divider sx={{margin: '1.5rem', borderColor: 'rgb(100, 100, 100) !important'}}/>
				<TwoFactorAuth/>
				<div className={classes.pass}>
					<UserChangePass/>
				</div>

			</div>
		</div>
	)
}

export default UserSettingPage;

