import React from 'react';
import classes from './TpsIndicator.module.css';
import {Typography} from '@mui/material';
import {useSelector} from "react-redux";


function TpsIndicator({data, type}) {
	const {iconStyle} = useSelector(store => store.tps)
	const isActive = data.state
	const value = data.value
	const title = data.title

	return (
		<div
			className={classes.container }
			style={{background: (isActive ? iconStyle.danger.color : iconStyle.green.color)}}
		>
			<Typography variant='h6'>
				{
					type === 'inputs'
						? `${title}`
						: type === 'aggregates'
							? `${title}:  ${value}`
							: ''
				}
			</Typography>
		</div>
	)
}

export default TpsIndicator;
