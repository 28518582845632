import React, {useEffect, useRef, useState} from "react";
import {Stack, Typography} from "@mui/material";
import MultipleSelect from "../components/UI/multiple-select/MultipleSelect";
import Input from "../components/UI/input/Input";
import MySelect from "../components/UI/select/MySelect";
import {useHttp} from "./useHttp";
import {useSelector} from "react-redux";


export const useManageUsers = (modal = {}, rows = [], getAllUsers = () => {}) => {
	const {userData} = useSelector(store => store.auth)
	const form = useRef({})
	const {post} = useHttp()
	const [existingTps, setExistingTps] = useState([])
	const [roleOptions, setRoleOptions] = useState([])
	const statusOptions = [
		{value: 1, title: 'Дозволено'},
		{value: 0, title: 'Заборонено'}
	]

	const changeFormValue = (event) => {
		form.current = {...form.current, [event.target.name]: event.target.value}
	}

	const selectHandle = (event) => {
		const selectedId = event.currentTarget.parentElement.parentElement.dataset.id
		form.current = rows.find(item => item.id.toString() === selectedId.toString())
	}

	const clearForm = () => {
		form.current = {name: '', password: '', email: '', role: '', availableTps: []}
	}

	const editUserHandle = (event) => {
		selectHandle(event)
		modal.title = 'Редагування облікового запису'
		modal.content = <Stack spacing={4} sx={{marginTop: '0.5rem'}}>
			<MultipleSelect
				name='availableTps'
				label='Доступні ТПС'
				options={existingTps}
				selectedValues={form.current.availableTps}
				onChange={changeFormValue}
			/>
			<Input
				name='name'
				placeholder="Повне ім'я"
				label="Повне ім'я"
				onChange={changeFormValue}
				defaultValue={form.current.name}
				size='medium'
			/>
			<Input
				name='password'
				placeholder="Пароль"
				label="Пароль"
				onChange={changeFormValue}
				defaultValue={form.current.password}
				size='medium'
			/>
			<MySelect
				name='role'
				label='Роль'
				defaultValue={form.current.role}
				options={roleOptions}
				onChange={changeFormValue}
				sx={{marginBottom: '2rem'}}
			/>
		</Stack>
		modal.confirm = () => editConfirm({
			...form.current,
			availableTps: JSON.stringify(
				form.current.availableTps
					.map(item => Number(item))
					.filter(item => !isNaN(item))
			)
		})
		modal.open()
	}

	const createUserHandler = () => {
		clearForm()
		modal.title = 'Створення облікового запису'
		modal.content = <Stack spacing={4} sx={{marginTop: '0.5rem'}}>
			<MultipleSelect
				name='availableTps'
				label='Доступні ТПС'
				options={existingTps}
				selectedValues={form.current.availableTps}
				onChange={changeFormValue}
			/>
			<Input
				name='name'
				placeholder="Повне ім'я"
				label="Повне ім'я"
				onChange={changeFormValue}
				defaultValue={form.current.name}
				size='medium'
			/>
			<Input
				name='email'
				placeholder="Електрона адреса"
				label="Електрона адреса"
				onChange={changeFormValue}
				defaultValue={form.current.email}
				size='medium'
			/>
			<Input
				name='password'
				placeholder="Пароль"
				label="Пароль"
				onChange={changeFormValue}
				defaultValue={form.current.password}
				size='medium'
			/>
			<MySelect
				name='role'
				label='Роль'
				defaultValue={form.current.role}
				options={roleOptions}
				onChange={changeFormValue}
				sx={{marginBottom: '2rem'}}
			/>
		</Stack>
		modal.confirm = createConfirm
		modal.open()
	}

	const accessUserHandle = (event) => {
		selectHandle(event)
		modal.title = 'Зміна доступу користувача'
		modal.content = <div>
			<Typography variant='h6'>Доступ до сайту</Typography>
			<MySelect
				name='status'
				defaultValue={form.current.status}
				disabled={form.current.id === userData.id}
				options={statusOptions}
				onChange={changeFormValue}
				sx={{margin: '1rem 0'}}
			/>
		</div>
		modal.confirm = async () => {
			await editConfirm({status: form.current.status})
			if (form.current.status === 0) {
				await post('/api/user/kick', {id: form.current.id})
			}
		}
		modal.open()
	}

	const editConfirm = async (userData) => {
		const {id} = form.current
		await post('/api/user/edit', {id, fields: userData})
			.then(getAllUsers)
	}

	const createConfirm = async () => {
		const {id} = userData
		const {name, role, email, password, availableTps} = form.current
		const {response, error} = await post(
			'/api/auth/registration',
			{id, username: name, email, password, role, availableTps}
		)
			.then(getAllUsers)
		if (error) {
			console.log('error', response)
		}
	}

	function addEvents() {
		Array.from(document.querySelectorAll('.edit-btn'))
			.forEach(item => item.onclick = editUserHandle)
		Array.from(document.querySelectorAll('.access-btn'))
			.forEach(item => item.onclick = accessUserHandle)
	}

	// eslint-disable-next-line
	useEffect(addEvents, [rows])

	return {setRoleOptions, setExistingTps, createUserHandler}
}
