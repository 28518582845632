import React, {useEffect, useRef, useState} from 'react';
import classes from '../components/TPS/iconbar/Iconbar.module.css';
import {Typography} from '@mui/material';
import {useSelector} from 'react-redux';


export const useLogs = (modal) => {
	const {logDataList, activeTps} = useSelector(store => store.tps)
	const [logData, setLogData] = useState(logDataList[activeTps] ?? [])
	const logContainer = useRef()

	useEffect(() => {
		setLogData(logDataList[activeTps] ?? [])
	}, [logDataList, activeTps])

	function setLogDataInModalContent() {
		if (logData.length) {
			modal.content = (
				<div ref={logContainer} className={classes.logs} data-cy='ib-arch-logs'>
					{
						logData.map((item, ind) => {
							try {
								let className = ''
								const type = parseInt(item.type)
								const state = parseInt(item.state)
								if (state === 1) {
									switch (type) {
										case 2:
											className = classes.on
											break
										case 3:
											className = classes.warning
											break
										case 4:
											className = classes.error
											break
									}
								} else if (state === 0) {
									className = classes.off
								}
								return (
									<div key={ind + 'i'} className={className}>
										<Typography variant={"h6"}>{item.text}</Typography>
									</div>
								)
							} catch {
								return false
							}
						})
					}
				</div>
			)
		} else {
			modal.content = 'Немає записів'
		}
	}

	useEffect(setLogDataInModalContent, [logData, modal])

	useEffect(() => {
		modal.title = 'Архів змін'
	}, [modal])

	function logHandler() {
		logContainer.scrollTop = logContainer.scrollHeight
		modal.open()
	}

	return {logHandler}
}
