import React, {useEffect, useState} from 'react';
import classes from './Logbar.module.css';
import Typography from '@mui/material/Typography';
import {useSelector} from 'react-redux';


function Logbar({isExist}) {
	let {logDataList, activeTps} = useSelector(store => store.tps)
	let [logData, setLogData] = useState(logDataList[activeTps] ?? [])
	const [logs, setLogs] = useState([])

	useEffect(() => {
		setLogData(logDataList[activeTps] ?? [])
	}, [logDataList, activeTps])

	useEffect(() => {
		const newLogs = logData
			.reduce((acc, item) => {
				if (item?.text) {
					acc.push(item.text)
				}
				return acc
			}, [])
			.slice(-3)
		setLogs(newLogs)
	}, [logData])

	return !isExist || (
		<div className={classes.container}>
			<div className={classes.wrapper} data-cy='lb-cont'>
				{logs.map(text =>
					<Typography
						key={text}
						variant='subtitle1'
					>
						{text}
					</Typography>
				)}
			</div>
		</div>
	)
}

export default Logbar;
