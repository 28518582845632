function noop() {
}

const initialState = {
	login: noop,
	logout: noop,
	userData: null,
	isAdmin: false,
	isOperator: false,
	isAuthenticated: false
}

export const AuthActionTypes = {
	SET_AUTH_STORE: 'SET_AUTH_STORE_AuthActionTypes'
}

export const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case AuthActionTypes.SET_AUTH_STORE:
			return {
				...state,
				...action.payload,
				isOperator: action.payload?.userData?.role === 'operator'
			}
		default:
			return state
	}
}
