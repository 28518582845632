import React, {useEffect, useState} from 'react'
import classes from './TpsButton.module.css'
import {Typography} from '@mui/material'


function TpsButton({name, onClick, active, alarm = false}) {
	const [flashing, setFlashing] = useState(false)

	useEffect(() => {
		let interval
		if (alarm) {
			interval = setInterval(() => {
				setFlashing((prevFlashing) => !prevFlashing)
			}, 500)
		} else {
			clearInterval(interval)
			setFlashing(false)
		}

		return () => clearInterval(interval)
	}, [alarm])

	const classList = [
		classes.container,
		active ? classes.active : '',
		flashing ? classes.alarm : ''
	].join(' ')

	return (
		<Typography variant='subtitle1'>
			<span
				className={classList}
				onClick={onClick}
			>
				{name}
			</span>
		</Typography>
	)
}

export default TpsButton;
