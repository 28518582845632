import React, {useState} from 'react';
import {useFunctionInterval} from '../../hooks/useFunctionInterval';
import dateTime from "../../utils/dateTime";
import classes from './Time.module.css';


function Time() {
	const [time, setTime] = useState(dateTime.now().split(' '))

	useFunctionInterval(() => {
		setTime(dateTime.now().split(' '))
	}, 1000)

	return (
		<div className={classes.container}>
			<div className={classes.date}>
				{time[0]}
			</div>
			{time[1]}
		</div>
	)
}

export default Time;
