import {useFunctionInterval} from "../useFunctionInterval";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHttp} from "../useHttp";


export const useAggregatesValue = (container) => {
	const {isGenerated, aggregatesCoord} = useSelector(store => store.scada)
	const {activeTps: tpsId} = useSelector(store => store.tps)
	const [aggregates, setAggregates] = useState(null)
	const [aggrCont, setAggrCont] = useState(null)
	const {get} = useHttp()

	async function getAggregByTpsId() {
		const {response, error} = await get(`/api/tps/aggregates/${tpsId}`)
		if (!error) {
			setAggregates(response)
		}
	}

	useFunctionInterval(getAggregByTpsId, 2000, [tpsId])

	useEffect(() => {
		if (aggrCont && typeof aggregates === 'object' && !Array.isArray(aggregates) && aggregates !== null) {
			Object.entries(aggregates).forEach(item => {
				if (aggrCont.hasOwnProperty(item[0])) {
					aggrCont[item[0]].innerHTML = item[1] + ' A'
				}
			})
		}
	}, [aggregates, aggrCont])

	useEffect(() => {
		if (isGenerated && aggregatesCoord.hasOwnProperty(tpsId)) {
			const newCont = {}
			Object.entries(aggregatesCoord[tpsId])
				.forEach(item => {
					const itemCont = document.createElement('div')
					itemCont.classList.add('agr-text')
					itemCont.style.top = item[1].top
					itemCont.style.left = item[1].left
					container.current.insertAdjacentElement('afterbegin', itemCont)
					newCont[item[0]] = itemCont
				})
			setAggrCont(newCont)
		}
	}, [aggregatesCoord, isGenerated, tpsId, container])
}
