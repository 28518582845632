import {isString} from "../../utils/utils";
import {Container} from "./other/Container/Container";
import {DoubleContainer} from "./other/DoubleContainer/DoubleContainer";
import {HRSwitch} from "./switches/HRSwitch/HRSwitch";
import {MVSwitch} from "./switches/MVSwitch/MVSwitch";
import {HR2Switch} from "./switches/HR2Switch/HR2Switch";
import {KASwitch} from "./switches/KASwitch/KASwitch";
import {TSNSwitch} from "./switches/TSNSwitch/TSNSwitch";
import {PZHSwitch} from "./switches/PZHSwitch/PZHSwitch";
import {DuplexSwitch} from "./switches/DuplexSwitch/DuplexSwitch";
import {AlarmDuplexSwitch} from "./switches/AlarmDuplexSwitch/AlarmDuplexSwitch";
import {GroupSwitch} from "./switches/GroupSwitch/GroupSwitch";
import {OneWayDuplexSwitch} from "./switches/OneWayDuplexSwitch/OneWayDuplexSwitch";
import {DuplexSideHeadSwitch} from "./switches/DuplexSideHeadSwitch/DuplexSideHeadSwitch";
import {Line} from "./lines/Line";
import {HRLine} from "./lines/HRLine/HRLine";
import {MVLine} from "./lines/MVLine/MVLine";
import {TLine} from "./lines/TLine/TLine";
import {TripleLine} from "./lines/TripleLine/TripleLine";
import {TireLine} from "./lines/TireLine/TireLine";
import {AGRLine} from "./lines/AGRLine/AGRLine";
import {TriangleLine} from "./lines/TriangleLine/TriangleLine";
import {DoubleAGRLine} from "./lines/DoubleAGRLine/DoubleAGRLine";
import {DoubleLine} from "./lines/DoubleLine/DoubleLine";
import {TextOnScheme} from "./other/TextOnScheme/TextOnScheme";
import {AngledTextOnScheme} from "./other/AngledTextOnScheme/AngledTextOnScheme";


// function validateConfig(value) {
// 	let isValidConfig = !config &&
// 		config.switches &&
// 		config.lines &&
// 		config.containers &&
// 		config.switches.reduce((acc, item) => {
// 			if (!acc) return false
// 			return // todo
// 		}, true)
//
// 	return isValidConfig
// }

export const SwitchType = {
	HRSwitch: 'HRSwitch', HR2Switch: 'HR2Switch', MVSwitch: 'MVSwitch',
	KASwitch: 'KASwitch', TSNSwitch: 'TSNSwitch', PZHSwitch: 'PZHSwitch'
}

export const SwitchAddType = {
	DuplexSwitch: 'DuplexSwitch', AlarmDuplexSwitch: 'AlarmDuplexSwitch', GroupSwitch: 'GroupSwitch',
	OneWayDuplexSwitch: 'OneWayDuplexSwitch', DuplexSideHeadSwitch: 'DuplexSideHeadSwitch'
}

export const LineType = {
	Line: 'Line', HRLine: 'HRLine', MVLine: 'MVLine', TLine: 'TLine', TripleLine: 'TripleLine', TireLine: 'TireLine',
	AGRLine: 'AGRLine', TriangleLine: 'TriangleLine', DoubleAGRLine: 'DoubleAGRLine', DoubleLine: 'DoubleLine'
}

export function configGenerator(tpsId, config) {
	function checkContainerInObject(objConfig, object) {
		if (!objConfig || !objConfig.container || !object) return
		const {id, order, side} = objConfig.container
		if (typeof id !== 'string' || !config.containers[id]) {
			return console.error(`Invalid container ID - ${id}!`)
		}
		if (typeof order !== 'number' || order < 0) {
			return console.error(`Invalid order "${order}" in container!`)
		}
		if (!config.containers[id].queue) {
			config.containers[id].queue = []
		}
		if (config.containers[id].queue[order]) {
			return console.error(`'Not unique order "${order}" in container "${id}"!'`)
		}
		if (config.containers[id].hasOwnProperty('double')) {
			if (side !== 'left' && side !== 'right') {
				return console.error('Invalid container side!')
			}
			config.containers[id].queue[order] = {object, side}
		} else {
			config.containers[id].queue[order] = object
		}
		return true
	}

	function createSwitch(acc, item) {
		if (!isString(item.type)) {
			console.error(`Invalid switch type)!`, item)
			return acc
		}
		let object = false
		try {
			const {id, name, left, top, head, tail, height, width, type, addType, depLine, logName, isOnlySchemas, isNotInteractive, isBlue} = item
			const defOptions = [type, tpsId, id, name, left, top, head.group[head.number - 1], tail.group[tail.number - 1], height, width]
			switch (type) {
				case SwitchType.HRSwitch:
					object = new HRSwitch(...defOptions, item.position, isBlue)
					break
				case SwitchType.MVSwitch:
					object = new MVSwitch(...defOptions)
					break
				case SwitchType.HR2Switch:
					object = new HR2Switch(...defOptions, item.distance)
					break
				case SwitchType.KASwitch:
					object = new KASwitch(...defOptions)
					break
				case SwitchType.TSNSwitch:
					object = new TSNSwitch(...defOptions)
					break
				case SwitchType.PZHSwitch:
					object = new PZHSwitch(...defOptions)
					break
			}
			switch (addType) {
				case SwitchAddType.DuplexSwitch:
					object = DuplexSwitch(object)
					break
				case SwitchAddType.AlarmDuplexSwitch:
					object = AlarmDuplexSwitch(object)
					break
				case SwitchAddType.GroupSwitch:
					object = GroupSwitch(object, item.group)
					break
				case SwitchAddType.OneWayDuplexSwitch:
					object = OneWayDuplexSwitch(object)
					break
				case SwitchAddType.DuplexSideHeadSwitch:
					object = DuplexSideHeadSwitch(object, item.rightHead.group[item.rightHead.number - 1])
					break
			}
			if (depLine) {
				const {group, number} = depLine
				object.setDepLine(group[number - 1])
			}
			if (logName) {
				object.logName = logName
			}
			checkContainerInObject(item, object)
			if (isOnlySchemas) {
				object.setOnlyOnSchemasSwitch()
			}
			if (isNotInteractive) {
				object.setNotInteractiveSwitch()
			}
			acc.push(object)
		} catch (err) {
			console.error(err)
		} finally {
			return acc
		}
	}

	function createLine(item) {
		let line
		const {left, top, position, name, nameCorrection, isBlue} = item
		if (item.hasOwnProperty('imageLine')) {
			const {imageLine: {height, width}, isBlue} = item
			const defOptions = [left ?? 0, top ?? 0, height, width, position, name, nameCorrection]
			switch (item.imageLine.type) {
				case LineType.HRLine:
					line = new HRLine(...defOptions)
					break
				case LineType.MVLine:
					line = new MVLine(...defOptions)
					break
				case LineType.TLine:
					line = new TLine(...defOptions)
					break
				case LineType.TripleLine:
					line = new TripleLine(...defOptions)
					break
				case LineType.TireLine:
					line = new TireLine(...defOptions, item.points, isBlue)
					break
				case LineType.AGRLine:
					line = new AGRLine(...defOptions)
					break
				case LineType.TriangleLine:
					line = new TriangleLine(...defOptions, isBlue)
					break
				case LineType.DoubleAGRLine:
					line = new DoubleAGRLine(...defOptions)
					break
				case LineType.DoubleLine:
					line = new DoubleLine(...defOptions)
					break
			}
		} else {
			const {length} = item
			line = new Line(left, top, length, position, isBlue)
		}
		checkContainerInObject(item, line)
		if (item.hasOwnProperty('addLines')) {
			if (!Array.isArray(item.addLines)) {
				throw new Error('Invalid addLines value! Necessary array!')
			}
			item.addLines.forEach(item => {
				const depLine = createLine(item)
				line.addLine(depLine)
			})
		}
		return line
	}

	function createContainer(acc, contId) {
		const item = config.containers[contId]
		const {left, top, height, width, position, queue, double, container} = item
		let object
		if (double) {
			object = new DoubleContainer(contId, left, top, height, width, position, double.align)
		} else {
			object = new Container(contId, left, top, height, width, position)
			if (queue) {
				queue.forEach(item => object.addElement(item))
			}
		}
		if (container) {
			checkContainerInObject(item, object)
		}
		if (double) {
			const {leftCont, rightCont} = double
			doubleContainers.push({contId, leftCont, rightCont})
		}
		acc[contId] = object
		return acc
	}

	function createText(item) {
		const {text, left, top, fontSize, angle = 0} = item
		if(angle === 0) {
			return new TextOnScheme(text, left, top, fontSize)
		} else {
			return new AngledTextOnScheme(text, left, top, fontSize, angle)
		}

	}

	// text generation
	config.text = config.text.map(createText)

	// lines generation
	Object.keys(config.lines).forEach(key => {
		config.lines[key].forEach((item, ind) =>
			config.lines[key][ind] = createLine(item)
		)
	})

	// switches generation
	config.switches = config.switches
		.flat()
		.reduce(createSwitch, [])

	// containers generation
	const doubleContainers = []
	const containers = Object.keys(config.containers)
		.reverse()
		.reduce(createContainer, {})
	doubleContainers.forEach(({contId, leftCont, rightCont}) => {
		if (!containers.hasOwnProperty(leftCont) || !containers.hasOwnProperty(rightCont)) {
			return console.error(`Invalid container ID: ${leftCont} or ${rightCont}!`)
		}
		const currContainer = containers[contId]
		currContainer.addContainer(containers[leftCont], 'left')
		currContainer.addContainer(containers[rightCont], 'right')
	})
	config.containers = Object.values(containers)

	// return generated config
	return config
}
