import {Typography} from '@mui/material';


export const useWarning = (modal) => {
	function createWarningContent(title, data) {
		if (!data) data = {}
		modal.title = title
		const dataKeys = Object.keys(data)
		if (dataKeys.length) {
			modal.content = dataKeys.map(item =>
				<Typography key={item} variant={"h6"}>{data[item].message}</Typography>
			)
		} else {
			modal.content = 'Немає записів'
		}
		modal.open()
	}

	function warningHandler(title, data) {
		modal.isRedTitle = false
		createWarningContent(title, data)
	}

	function errorHandler(title, data) {
		modal.isRedTitle = true
		createWarningContent(title, data)
	}

	return {errorHandler, warningHandler}
}
