import backgroundImage from "../../assets/schemes/image-2.png";
import {configGenerator, LineType, SwitchAddType, SwitchType} from "../../components/scada-elements/configGenerator";


const TPS_ID = 2

const other = [
	{length: 0}, // alwaysOn
	{left: 78, top: 80, imageLine: {type: LineType.TireLine, height: 30, width: 1140},
		points: [83, 325, 565, 805, 1045]
	}, // SH1_10KV
	{left: 1277, top: 80, imageLine: {type: LineType.TireLine, height: 30, width: 1618},
		points: [83, 325, 565, 805, 1045, 1283, 1522]
	}, // SH2_10KV
	{left: 19, top: 1473, imageLine: {type: LineType.TireLine, height: 30, width: 781},
		points: [84, 203, 323, 443, 563, 683], isBlue: true
	}, // -600V
	{left: 836, top: 1473, imageLine: {type: LineType.TireLine, height: 30, width: 2339},
		points: [287, 527, 768, 1248, 1488, 1726, 1966]
	}, // BACKUP_600V
	{left: 836, top: 1563, imageLine: {type: LineType.TireLine, height: 30, width: 2339},
		points: [78, 322, 562, 803, 1040 ,1283, 1523, 1761, 2001, 2246]
	}, // WORK_600V
	{left: 2124, top: 854, imageLine: {type: LineType.TireLine, height: 30, width: 301},
		points: [71, 200]
	}, // SH1_400V
	{left: 2484, top: 854, imageLine: {type: LineType.TireLine, height: 30, width: 301},
		points: [71, 200]
	}, // SH2_400V
]

const lines0 = [
	{length: 53, container: {id: 'branch-0.1', order: 3}},
	{imageLine: {type: LineType.MVLine, height: 90, width: 145}, container: {id: 'branch-0.1', order: 8},
		addLines: [
			{imageLine: {type: LineType.HRLine}, container: {id: 'branch-0.1', order: 6}},
			{length: 172, container: {id: 'branch-0.1', order: 5}},
			{length: 53, container: {id: 'branch-0.1', order: 7}},
		]
	},
	{length: 82, container: {id: 'branch-0.1', order: 1}}, // splice
]

const lines1 = [
	{imageLine: {type: LineType.MVLine, height: 120, width: 145}, container: {id: 'branch-1.1', order: 8},
		addLines: [
			{length: 52, container: {id: 'branch-1.1', order: 7}},
			{imageLine: {type: LineType.HRLine}, container: {id: 'branch-1.1', order: 6}},
			{length: 171, container: {id: 'branch-1.1', order: 5}},
			{left: 169, top: 403, length: 91, position: 'h'},
			{left: 258, top: 149, length: 258},
			{left: 258, top: 149, length: 151, position: 'h'},
			{length: 53, container: {id: 'branch-1.2', order: 3}},
			{length: 172, container: {id: 'branch-1.2', order: 1}},
			{left: 408, top: 565, length: 1635, position: 'h'},
			{left: 2042, top: 565, length: 625},
			{left: 2042, top: 1190, length: 163, position: 'h'},
			{length: 139, container: {id: 'branch-1.3', order: 5}},
		]
	},
	{length: 48, container: {id: 'branch-1.3', order: 3}},
	{length: 28, container: {id: 'branch-1.3', order: 1}}, // splice
	// TSN-1 part end
	{length: 59, container: {id: 'branch-1.1', order: 3}},
	{length: 81, container: {id: 'branch-1.1', order: 1}}, // splice
]

const lines3 = [
	// TSN-1 part start
	{imageLine: {type: LineType.MVLine, height: 120, width: 145}, container: {id: 'branch-3.1', order: 8},
		addLines: [
			{length: 53, container: {id: 'branch-3.1', order: 7}},
			{imageLine: {type: LineType.HRLine}, container: {id: 'branch-3.1', order: 6}},
			{length: 172, container: {id: 'branch-3.1', order: 5}},
			{left: 2565, top: 399, length: 92, position: 'h'},
			{left: 2655, top: 149, length: 254},
			{left: 2655, top: 149, length: 150, position: 'h'},
			{length: 172, container: {id: 'branch-3.2', order: 1}},
			{length: 675, container: {id: 'branch-3.2', order: 3}},
			{left: 2693, top: 1184, length: 111, position: 'h'},
			{length: 95, container: {id: 'branch-3.3', order: 5}},
		]
	},
	{length: 70, container: {id: 'branch-3.3', order: 3}},
	{length: 45, container: {id: 'branch-3.3', order: 1}}, // splice
	// TSN-1 part end
	{length: 53, container: {id: 'branch-3.1', order: 3}},
	{length: 82, container: {id: 'branch-3.1', order: 1}}, // splice
]

const lines5 = [
	{length: 57, container: {id: 'branch-5.1', order: 3}},
	{length: 420, container: {id: 'branch-5.1', order: 5},
		addLines: [
			{left: 1847, top: 760, length: 240, position: 'h'},
			{length: 67, container: {id: 'branch-5.2', order: 1}},
			{imageLine: {type: LineType.TLine}, container: {id: 'branch-5.2', order: 2}},
			{length: 73, container: {id: 'additional-5', order: 3}, position: 'h'},
			{length: 28, container: {id: 'branch-5.2', order: 3}},
			{imageLine: {type: LineType.AGRLine, height: 90, width: 60}, container: {id: 'branch-5.2', order: 4}},
			{length: 180, container: {id: 'branch-5.2', order: 5}},
			{left: 1849, top: 1313, length: 1200, position: 'h'},
			{left: 3049, top: 1313, length: 430},
		]
	},
	{length: 40, container: {id: 'down-left-5-agr1', order: 1}},
	{length: 47, container: {id: 'down-left-5-agr1', order: 3},
		addLines: [
			{length: 36, container: {id: 'branch-5-agr1', order: 4}, position: 'h'},
			{length: 137, container: {id: 'down-right-5-agr1', order: 1}},
		]
	},
	{length: 143, container: {id: 'up-right-5-agr1', order: 1}}, // splice
	{length: 80, container: {id: 'branch-5.1', order: 1}}, // splice
]

const lines6 = [
	{length: 57, container: {id: 'branch-6.1', order: 3}},
	{length: 348, container: {id: 'branch-6.1', order: 5},
		addLines: [
			{left: 1410, top: 677, length: 435, position: 'h'},
			{length: 150, container: {id: 'branch-6.2', order: 1}},
			{imageLine: {type: LineType.TLine}, container: {id: 'branch-6.2', order: 2}},
			{length: 73, container: {id: 'additional-6', order: 3}, position: 'h'},
			{length: 28, container: {id: 'branch-6.2', order: 3}},
			{imageLine: {type: LineType.AGRLine, height: 90, width: 60}, container: {id: 'branch-6.2', order: 4}},
			{length: 235, container: {id: 'branch-6.2', order: 5}},
			{left: 1411, top: 1373, length: 437, position: 'h'},
			{left: 1849, top: 1373, length: 364},
		]
	},
	{length: 40, container: {id: 'down-left-6-agr2', order: 1}},
	{length: 47, container: {id: 'down-left-6-agr2', order: 3},
		addLines: [
			{length: 36, container: {id: 'branch-6-agr2', order: 4}, position: 'h'},
			{length: 137, container: {id: 'down-right-6-agr2', order: 1}},
		]
	},
	{length: 143, container: {id: 'up-right-6-agr2', order: 1}}, // splice
	{length: 72, container: {id: 'branch-6.1', order: 1}}, // splice
]

const lines7 = [
	{length: 118, container: {id: 'branch-7', order: 3},
		addLines: [
			{imageLine: {type: LineType.TLine}, container: {id: 'branch-7', order: 4}},
		]
	},
	{length: 64, container: {id: 'branch-7', order: 1}},
]

const lines8 = [
	{length: 68, container: {id: 'branch-8', order: 3},
		addLines: [
			{left: 1127, top: 290, length: 242, position: 'h'},
			{left: 1367, top: 110, length: 183}
		]
	}, // splice
	{length: 72, container: {id: 'branch-8', order: 1}}, // splice
]

const lines9 = [
	{length: 56, container: {id: 'branch-9', order: 3}},
	{length: 513, container: {id: 'branch-9', order: 5},
		addLines: [
			{imageLine: {type: LineType.TLine}, container: {id: 'branch-9', order: 6}},
			{length: 75, container: {id: 'additional-9', order: 3}, position: 'h'},
			{length: 25, container: {id: 'branch-9', order: 7}},
			{imageLine: {type: LineType.AGRLine}, container: {id: 'branch-9', order: 8}},
			{left: 886, top: 1150, length: 588},
		]
	},
	{length: 40, container: {id: 'down-left-9-agr3', order: 1}},
	{length: 47, container: {id: 'down-left-9-agr3', order: 3},
		addLines: [
			{length: 36, container: {id: 'branch-9-agr3', order: 4}, position: 'h'},
			{length: 137, container: {id: 'down-right-9-agr3', order: 1}},
		]
	},
	{length: 143, container: {id: 'up-right-9-agr3', order: 1}}, // splice
	{length: 64, container: {id: 'branch-9', order: 1}}, //splice
]

const lines10 = [
	{length: 118, container: {id: 'branch-10', order: 3},
		addLines: [
			{imageLine: {type: LineType.TLine}, container: {id: 'branch-10', order: 4}},
		]
	},
	{length: 64, container: {id: 'branch-10', order: 1}}, // splice
]

const lines111 = [
	{length: 137, container: {id: 'down-right-111', order: 1},
		addLines: [
			{length: 36, container: {id: 'branch-111', order: 4}, position: 'h'},
			{length: 47, container: {id: 'down-left-111', order: 3}}
		]
	},
	{length: 53, container: {id: 'up-left-111', order: 3},
		addLines: [
			{length: 40, container: {id: 'down-left-111', order: 1}},
			{left: 1010, top: 1712, length: 125, position: 'h'},
			{length: 178, container: {id: 'left-branch-111', order: 1}},
			{imageLine: {type: LineType.TriangleLine}, container: {id: 'left-branch-111', order: 2}}
		]
	},

	{length: 143, container: {id: 'up-right-111', order: 1}}, // splice
	{length: 141, container: {id: 'up-left-111', order: 1}}, // splice
]

const lines112 = [
	{length: 137, container: {id: 'down-right-112', order: 1},
		addLines: [
			{length: 36, container: {id: 'branch-112', order: 4}, position: 'h'},
			{length: 47, container: {id: 'down-left-112', order: 3}}
		]
	},
	{length: 53, container: {id: 'up-left-112', order: 3},
		addLines: [
			{length: 40, container: {id: 'down-left-112', order: 1}},
			{left: 1250, top: 1712, length: 125, position: 'h'},
			{length: 178, container: {id: 'left-branch-112', order: 1}},
			{imageLine: {type: LineType.TriangleLine}, container: {id: 'left-branch-112', order: 2}}
		]
	},

	{length: 143, container: {id: 'up-right-112', order: 1}}, // splice
	{length: 141, container: {id: 'up-left-112', order: 1}}, // splice
]

const lines113 = [
	{length: 137, container: {id: 'down-right-113', order: 1},
		addLines: [
			{length: 36, container: {id: 'branch-113', order: 4}, position: 'h'},
			{length: 47, container: {id: 'down-left-113', order: 3}}
		]
	},
	{length: 53, container: {id: 'up-left-113', order: 3},
		addLines: [
			{length: 40, container: {id: 'down-left-113', order: 1}},
			{left: 1490, top: 1712, length: 125, position: 'h'},
			{length: 178, container: {id: 'left-branch-113', order: 1}},
			{imageLine: {type: LineType.TriangleLine}, container: {id: 'left-branch-113', order: 2}}
		]
	},

	{length: 143, container: {id: 'up-right-113', order: 1}}, // splice
	{length: 141, container: {id: 'up-left-113', order: 1}}, // splice
]

const lines114 = [
	{length: 137, container: {id: 'down-right-114', order: 1},
		addLines: [
			{length: 36, container: {id: 'branch-114', order: 4}, position: 'h'},
			{length: 47, container: {id: 'down-left-114', order: 3}}
		]
	},
	{length: 53, container: {id: 'up-left-114', order: 3},
		addLines: [
			{length: 40, container: {id: 'down-left-114', order: 1}},
			{left: 2209, top: 1712, length: 125, position: 'h'},
			{length: 178, container: {id: 'left-branch-114', order: 1}},
			{imageLine: {type: LineType.TriangleLine}, container: {id: 'left-branch-114', order: 2}}
		]
	},

	{length: 143, container: {id: 'up-right-114', order: 1}}, // splice
	{length: 141, container: {id: 'up-left-114', order: 1}}, // splice
]

const lines115 = [
	{length: 137, container: {id: 'down-right-115', order: 1},
		addLines: [
			{length: 36, container: {id: 'branch-115', order: 4}, position: 'h'},
			{length: 47, container: {id: 'down-left-115', order: 3}}
		]
	},
	{length: 53, container: {id: 'up-left-115', order: 3},
		addLines: [
			{length: 40, container: {id: 'down-left-115', order: 1}},
			{left: 2448, top: 1712, length: 125, position: 'h'},
			{length: 178, container: {id: 'left-branch-115', order: 1}},
			{imageLine: {type: LineType.TriangleLine}, container: {id: 'left-branch-115', order: 2}}
		]
	},

	{length: 143, container: {id: 'up-right-115', order: 1}}, // splice
	{length: 141, container: {id: 'up-left-115', order: 1}}, // splice
]

const lines116 = [
	{length: 137, container: {id: 'down-right-116', order: 1},
		addLines: [
			{length: 36, container: {id: 'branch-116', order: 4}, position: 'h'},
			{length: 47, container: {id: 'down-left-116', order: 3}}
		]
	},
	{length: 53, container: {id: 'up-left-116', order: 3},
		addLines: [
			{length: 40, container: {id: 'down-left-116', order: 1}},
			{left: 2688, top: 1712, length: 125, position: 'h'},
			{length: 178, container: {id: 'left-branch-116', order: 1}},
			{imageLine: {type: LineType.TriangleLine}, container: {id: 'left-branch-116', order: 2}}
		]
	},

	{length: 143, container: {id: 'up-right-116', order: 1}}, // splice
	{length: 141, container: {id: 'up-left-116', order: 1}}, // splice
]

const linesBackup = [
	{length: 137, container: {id: 'down-right-backupBranch', order: 1},
		addLines: [
			{length: 36, container: {id: 'backupBranch', order: 4}, position: 'h'},
			{length: 47, container: {id: 'down-left-backupBranch', order: 3}},
		]
	},
	{length: 143, container: {id: 'up-right-backupBranch', order: 1}}, // splice
	{length: 233, container: {id: 'up-left-backupBranch', order: 1},
		addLines: [
			{length: 40, container: {id: 'down-left-backupBranch', order: 1}},
		]
	}, // splice


]

const linesSHSN = [
	{length: 40, container: {id: 'branch-SHSN', order: 3},
		addLines: [
			{left: 2334, top: 1015, length: 232, position: 'h'},
			{left: 2563, top: 886, length: 130}
		]
	}, // splice
	{length: 47, container: {id: 'branch-SHSN', order: 1}},
]

const additionalLines = [
	// 5
	{length: 60, container: {id: 'additional-5', order: 1}, position: 'h', isBlue: true}, // splice
	// 6
	{length: 65, container: {id: 'additional-6', order: 1}, position: 'h', isBlue: true}, // splice
	// 9
	{length: 92, container: {id: 'additional-9', order: 1}, position: 'h', isBlue: true}, // splice
	// 111
	{length: 73, container: {id: 'additional-211', order: 1}, isBlue: true}, // splice
	{length: 47, container: {id: 'additional-211', order: 3},
		addLines: [{imageLine: {type: LineType.TriangleLine}, isBlue: true, container: {id: 'additional-211', order: 4}}], isBlue: true
	},
	// 112
	{length: 73, container: {id: 'additional-212', order: 1}, isBlue: true}, // splice
	{length: 47, container: {id: 'additional-212', order: 3},
		addLines: [{imageLine: {type: LineType.TriangleLine}, isBlue: true, container: {id: 'additional-212', order: 4}}], isBlue: true
	},
	// 113
	{length: 73, container: {id: 'additional-213', order: 1}, isBlue: true}, // splice
	{length: 47, container: {id: 'additional-213', order: 3},
		addLines: [	{imageLine: {type: LineType.TriangleLine}, isBlue: true, container: {id: 'additional-213', order: 4}}], isBlue: true
	},
	// 114
	{length: 73, container: {id: 'additional-214', order: 1}, isBlue: true}, // splice
	{length: 47, container: {id: 'additional-214', order: 3},
		addLines: [	{imageLine: {type: LineType.TriangleLine}, isBlue: true, container: {id: 'additional-214', order: 4}}], isBlue: true
	},
	// 115
	{length: 73, container: {id: 'additional-215', order: 1}, isBlue: true}, // splice
	{length: 47, container: {id: 'additional-215', order: 3},
		addLines: [	{imageLine: {type: LineType.TriangleLine}, isBlue: true, container: {id: 'additional-215', order: 4}}], isBlue: true
	},
	// 116
	{length: 73, container: {id: 'additional-216', order: 1}, isBlue: true}, // splice
	{length: 47, container: {id: 'additional-216', order: 3},
		addLines: [	{imageLine: {type: LineType.TriangleLine}, isBlue: true, container: {id: 'additional-216', order: 4}}], isBlue: true
	},
]

other[1].addLines = [
	lines1.splice(-1, 1)[0],
	lines8.splice(-1, 1)[0],
	lines9.splice(-1,1)[0],
	lines10.splice(-1, 1)[0],
]
other[2].addLines = [
	lines0.splice(-1, 1)[0],
	lines3.splice(-1, 1)[0],
	lines5.splice(-1, 1)[0],
	lines6.splice(-1, 1)[0],
	lines7.splice(-1, 1)[0],
	lines8.splice(-1, 1)[0],
]
other[3].addLines = [
	{left: 469, top: 806, length: 667, isBlue: true},
	{left: 469, top: 806, length: 1139, position: 'h', isBlue: true},
	{left: 669, top: 806, length: 199, isBlue: true},
	{left: 1193, top: 806, length: 199, isBlue: true},
	{left: 1607, top: 806, length: 199, isBlue: true},
	additionalLines.splice(13, 1)[0],
	additionalLines.splice(11, 1)[0],
	additionalLines.splice(9, 1)[0],
	additionalLines.splice(7, 1)[0],
	additionalLines.splice(5, 1)[0],
	additionalLines.splice(3, 1)[0],
	additionalLines.splice(2, 1)[0],
	additionalLines.splice(1, 1)[0],
	additionalLines.splice(0, 1)[0],
]
other[4].addLines = [
	lines111.splice(-1, 1)[0],
	lines112.splice(-1, 1)[0],
	lines113.splice(-1, 1)[0],
	lines114.splice(-1, 1)[0],
	lines115.splice(-1, 1)[0],
	lines116.splice(-1, 1)[0],
	linesBackup.splice(-1, 1)[0],
]
other[5].addLines = [
	lines111.splice(-1, 1)[0],
	lines112.splice(-1, 1)[0],
	lines113.splice(-1, 1)[0],
	lines114.splice(-1, 1)[0],
	lines115.splice(-1, 1)[0],
	lines116.splice(-1, 1)[0],
	lines5.splice(-1, 1)[0],
	lines6.splice(-1, 1)[0],
	lines9.splice(-1, 1)[0],
	linesBackup.splice(-1, 1)[0],
]
other[6].addLines = [
	lines1.splice(-2, 1)[0],
	linesSHSN.splice(-1, 1)[0],
]
other[7].addLines = [
	linesSHSN.splice(-1, 1)[0],
	lines3.splice(-2, 1)[0],

]

const branch0 = [
	{type: SwitchType.HRSwitch, id: 'hr0', name: 'ШР0', head: {group: other, number: 3},
		tail: {group: lines0, number: 1}, container: {id: 'branch-0.1', order: 2}, isOnlySchemas: true,
	},
	{type: SwitchType.MVSwitch, id: 'vm0', name: 'МВ0', head: {group: lines0, number: 1},
		tail: {group: lines0, number: 2}, container: {id: 'branch-0.1', order: 4}, isOnlySchemas: true,
	},
]

const branch1 = [
	{type: SwitchType.TSNSwitch, id: 'tsn2', name: 'ТСН-1', head: {group: other, number: 1},
		tail: {group: lines1, number: 1}, container: {id: 'branch-1.2', order: 2}, isOnlySchemas: true
	},
	{type: SwitchType.HRSwitch, id: 'p2', name: 'П1', head: {group: lines1, number: 1},
		tail: {group: lines1, number: 2}, container: {id: 'branch-1.3', order: 4}, isOnlySchemas: true,
	},
	{type: SwitchType.HRSwitch, id: 'r32', name: 'Р31', head: {group: lines1, number: 2},
		tail: {group: other, number: 7}, container: {id: 'branch-1.3', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.OneWayDuplexSwitch,
	},
	{type: SwitchType.MVSwitch, id: 'vm1', name: 'МВ1', head: {group: lines1, number: 1},
		tail: {group: lines1, number: 3}, container: {id: 'branch-1.1', order: 4}, isOnlySchemas: true,
		addType: SwitchAddType.DuplexSwitch,
	},
	{type: SwitchType.HRSwitch, id: 'shr1', name: 'ШР1', head: {group: lines1, number: 3},
		tail: {group: other, number: 3}, container: {id: 'branch-1.1', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.OneWayDuplexSwitch,
	},
]

const branch3 = [
	// TSN-1 part start
	{type: SwitchType.TSNSwitch, id: 'tsn1', name: 'ТСН-2', head: {group: other, number: 1},
		tail: {group: lines3, number: 1}, container: {id: 'branch-3.2', order: 2}, isOnlySchemas: true
	},
	{type: SwitchType.HRSwitch, id: 'p1', name: 'П2', head: {group: lines3, number: 1},
		tail: {group: lines3, number: 2}, container: {id: 'branch-3.3', order: 4}, isOnlySchemas: true,
	},
	{type: SwitchType.HRSwitch, id: 'r31', name: 'Р32', head: {group: lines3, number: 2},
		tail: {group: other, number: 8}, container: {id: 'branch-3.3', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.OneWayDuplexSwitch,
	},
	// TSN-1 part end
	{type: SwitchType.MVSwitch, id: 'vm3', name: 'МВ3', head: {group: lines3, number: 1},
		tail: {group: lines3, number: 3}, container: {id: 'branch-3.1', order: 4}, isOnlySchemas: true,
		addType: SwitchAddType.DuplexSwitch,
	},
	{type: SwitchType.HRSwitch, id: 'shr3', name: 'ШР3', head: {group: lines3, number: 3},
		tail: {group: other, number: 3}, container: {id: 'branch-3.1', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.OneWayDuplexSwitch,
	},
]

const branch5 = [
	{type: SwitchType.HRSwitch, id: 'shr5', name: 'ШР5', head: {group: other, number: 2},
		tail: {group: lines5, number: 1}, container: {id: 'branch-5.1', order: 2}, isOnlySchemas: true,
	},
	{type: SwitchType.MVSwitch, id: 'vm5', name: 'МВ5', head: {group: lines5, number: 1},
		tail: {group: lines5, number: 2}, container: {id: 'branch-5.1', order: 4}, isOnlySchemas: true,
	},
	{type: SwitchType.HR2Switch, id: 'shr_b5', name: 'ШР', head: {group: lines5, number: 2},
		tail: {group: lines5, number: 3}, container: {id: 'branch-5-agr1', order: 2}, isOnlySchemas: true,
		logName: 'ШР КА№1', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'ka1', name: 'KA №1', head: {group: lines5, number: 3},
		tail: {group: other, number: 6}, container: {id: 'down-left-5-agr1', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.GroupSwitch, group: '2', depLine: {group: lines5, number: 4}
	},
]

const branch6 = [
	{type: SwitchType.HRSwitch, id: 'shr6', name: 'ШР6', head: {group: other, number: 2},
		tail: {group: lines6, number: 1}, container: {id: 'branch-6.1', order: 2}, isOnlySchemas: true,
	},
	{type: SwitchType.MVSwitch, id: 'vm6', name: 'МВ6', head: {group: lines6, number: 1},
		tail: {group: lines6, number: 2}, container: {id: 'branch-6.1', order: 4}, isOnlySchemas: true,
	},
	{type: SwitchType.HR2Switch, id: 'shr_b6', name: 'ШР', head: {group: lines6, number: 2},
		tail: {group: lines6, number: 3}, container: {id: 'branch-6-agr2', order: 2}, isOnlySchemas: true,
		logName: 'ШР КА№2', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'ka2', name: 'KA №2', head: {group: lines6, number: 3},
		tail: {group: other, number: 6}, container: {id: 'down-left-6-agr2', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.GroupSwitch, group: '2', depLine: {group: lines6, number: 4}
	},
]

const branch7 = [
	{type: SwitchType.HRSwitch, id: 'shr7', name: 'ШР7', head: {group: other, number: 1},
		tail: {group: other, number: 3}, container: {id: 'branch-7', order: 2}, isOnlySchemas: true,
		depLine: {group: lines7, number: 1}
	},
]

const branch8 = [
	{type: SwitchType.HRSwitch, id: 'shr8', name: 'ШР8', head: {group: other, number: 2},
		tail: {group: other, number: 3}, container: {id: 'branch-8', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.AlarmDuplexSwitch,
	},
]

const branch9 = [
	{type: SwitchType.HRSwitch, id: 'shr9', name: 'ШР9', head: {group: other, number: 2},
		tail: {group: lines9, number: 1}, container: {id: 'branch-9', order: 2}, isOnlySchemas: true,
	},
	{type: SwitchType.MVSwitch, id: 'vm9', name: 'МВ9', head: {group: lines9, number: 1},
		tail: {group: lines9, number: 2}, container: {id: 'branch-9', order: 4}, isOnlySchemas: true,
	},
	{type: SwitchType.HR2Switch, id: 'shr_b9', name: 'ШР', head: {group: lines9, number: 2},
		tail: {group: lines9, number: 3}, container: {id: 'branch-9-agr3', order: 2}, isOnlySchemas: true,
		logName: 'ШР КА№3', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'ka3', name: 'KA №3', head: {group: lines9, number: 3},
		tail: {group: other, number: 6}, container: {id: 'down-left-9-agr3', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.GroupSwitch, group: '2', depLine: {group: lines9, number: 4}
	},
]

const branch10 = [
	{type: SwitchType.HRSwitch, id: 'shr10', name: 'ШР10', head: {group: other, number: 1},
		tail: {group: other, number: 2}, container: {id: 'branch-10', order: 2}, isOnlySchemas: true,
		depLine: {group: lines10, number: 1}
	},
]

const branch111 = [
	{type: SwitchType.HR2Switch, id: 'r2-211', name: 'Р2', head: {group: other, number: 6},
		tail: {group: lines111, number: 1}, container: {id: 'branch-111', order: 2}, isOnlySchemas: true,
		logName: 'Р2 ЛВ211', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'lv211', name: 'ЛВ211', head: {group: lines111, number: 1},
		tail: {group: lines111, number: 2}, container: {id: 'down-left-111', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.GroupSwitch, group: '211'
	},
	{type: SwitchType.HRSwitch, id: 'r1-211', name: 'Р1', head: {group: other, number: 5},
		tail: {group: lines111, number: 2}, container: {id: 'up-left-111', order: 2}, isOnlySchemas: true,
		logName: 'Р1 ЛВ211', addType: SwitchAddType.GroupSwitch, group: '211'
	},
]

const branch112 = [
	{type: SwitchType.HR2Switch, id: 'r2-212', name: 'Р2', head: {group: other, number: 6},
		tail: {group: lines112, number: 1}, container: {id: 'branch-112', order: 2}, isOnlySchemas: true,
		logName: 'Р2 ЛВ212', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'lv212', name: 'ЛВ212', head: {group: lines112, number: 1},
		tail: {group: lines112, number: 2}, container: {id: 'down-left-112', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.GroupSwitch, group: '212'
	},
	{type: SwitchType.HRSwitch, id: 'r1-212', name: 'Р1', head: {group: other, number: 5},
		tail: {group: lines112, number: 2}, container: {id: 'up-left-112', order: 2}, isOnlySchemas: true,
		logName: 'Р1 ЛВ212', addType: SwitchAddType.GroupSwitch, group: '212'
	},
]

const branch113 = [
	{type: SwitchType.HR2Switch, id: 'r2-213', name: 'Р2', head: {group: other, number: 6},
		tail: {group: lines113, number: 1}, container: {id: 'branch-113', order: 2}, isOnlySchemas: true,
		logName: 'Р2 ЛВ213', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'lv213', name: 'ЛВ213', head: {group: lines113, number: 1},
		tail: {group: lines113, number: 2}, container: {id: 'down-left-113', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.GroupSwitch, group: '213'
	},
	{type: SwitchType.HRSwitch, id: 'r1-213', name: 'Р1', head: {group: other, number: 5},
		tail: {group: lines113, number: 2}, container: {id: 'up-left-113', order: 2}, isOnlySchemas: true,
		logName: 'Р1 ЛВ213', addType: SwitchAddType.GroupSwitch, group: '213'
	},
]

const branch114 = [
	{type: SwitchType.HR2Switch, id: 'r2-214', name: 'Р2', head: {group: other, number: 6},
		tail: {group: lines114, number: 1}, container: {id: 'branch-114', order: 2}, isOnlySchemas: true,
		logName: 'Р2 ЛВ214', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'lv214', name: 'ЛВ214', head: {group: lines114, number: 1},
		tail: {group: lines114, number: 2}, container: {id: 'down-left-114', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.GroupSwitch, group: '214'
	},
	{type: SwitchType.HRSwitch, id: 'r1-214', name: 'Р1', head: {group: other, number: 5},
		tail: {group: lines114, number: 2}, container: {id: 'up-left-114', order: 2}, isOnlySchemas: true,
		logName: 'Р1 ЛВ214', addType: SwitchAddType.GroupSwitch, group: '214'
	},
]

const branch115 = [
	{type: SwitchType.HR2Switch, id: 'r2-215', name: 'Р2', head: {group: other, number: 6},
		tail: {group: lines115, number: 1}, container: {id: 'branch-115', order: 2}, isOnlySchemas: true,
		logName: 'Р2 ЛВ215', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'lv215', name: 'ЛВ215', head: {group: lines115, number: 1},
		tail: {group: lines115, number: 2}, container: {id: 'down-left-115', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.GroupSwitch, group: '215'
	},
	{type: SwitchType.HRSwitch, id: 'r1-215', name: 'Р1', head: {group: other, number: 5},
		tail: {group: lines115, number: 2}, container: {id: 'up-left-115', order: 2}, isOnlySchemas: true,
		logName: 'Р1 ЛВ215', addType: SwitchAddType.GroupSwitch, group: '215'
	},
]

const branch116 = [
	{type: SwitchType.HR2Switch, id: 'r2-216', name: 'Р2', head: {group: other, number: 6},
		tail: {group: lines116, number: 1}, container: {id: 'branch-116', order: 2}, isOnlySchemas: true,
		logName: 'Р2 ЛВ216', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'lv216', name: 'ЛВ216', head: {group: lines116, number: 1},
		tail: {group: lines116, number: 2}, container: {id: 'down-left-116', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.GroupSwitch, group: '116'
	},
	{type: SwitchType.HRSwitch, id: 'r1-216', name: 'Р1', head: {group: other, number: 5},
		tail: {group: lines116, number: 2}, container: {id: 'up-left-116', order: 2}, isOnlySchemas: true,
		logName: 'Р1 ЛВ216', addType: SwitchAddType.GroupSwitch, group: '116'
	},
]

const backupBranch = [
	{type: SwitchType.HR2Switch, id: 'shr-bu', name: 'ШР', head: {group: other, number: 6},
		tail: {group: linesBackup, number: 1}, container: {id: 'backupBranch', order: 2}, isOnlySchemas: true,
		logName: 'Запасний ШР', distance: 34
	},
	{type: SwitchType.KASwitch, id: 'zv-bu', name: 'ЗВ', head: {group: linesBackup, number: 1},
		tail: {group: other, number: 5}, container: {id: 'down-left-backupBranch', order: 2}, isOnlySchemas: true,
		logName: 'Запасний ЗВ'
	},
]

const branchSHSN = [
	{type: SwitchType.HRSwitch, id: 'r33', name: 'Р33', head: {group: other, number: 7},
		tail: {group: other, number: 8}, container: {id: 'branch-SHSN', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.AlarmDuplexSwitch
	},
]

const additionalBranch = [
	// 5
	{type: SwitchType.HRSwitch, id: 'rlv01-5', name: 'РЛВ01', head: {group: other, number: 1},
		tail: {group: other, number: 4}, container: {id: 'additional-5', order: 2}, isOnlySchemas: true,
		logName: 'РЛВ01 КА№1', addType: SwitchAddType.GroupSwitch, group: '-600V', position: 'h', isBlue: true
	},
	// 6
	{type: SwitchType.HRSwitch, id: 'rlv01-6', name: 'РЛВ01', head: {group: other, number: 1},
		tail: {group: other, number: 4}, container: {id: 'additional-6', order: 2}, isOnlySchemas: true,
		logName: 'РЛВ01 КА№2', addType: SwitchAddType.GroupSwitch, group: '-600V', position: 'h', isBlue: true
	},
	// 9
	{type: SwitchType.HRSwitch, id: 'rlv01-9', name: 'РЛВ01', head: {group: other, number: 1},
		tail: {group: other, number: 4}, container: {id: 'additional-9', order: 2}, isOnlySchemas: true,
		logName: 'РЛВ01 КА№3', addType: SwitchAddType.GroupSwitch, group: '-600V', position: 'h', isBlue: true
	},
	//
	{type: SwitchType.HRSwitch, id: 'r211', name: 'Р211', head: {group: other, number: 4},
		tail: {group: additionalLines, number: 1}, container: {id: 'additional-211', order: 2}, isOnlySchemas: true,
		isBlue: true
	},
	{type: SwitchType.HRSwitch, id: 'r212', name: 'Р212', head: {group: other, number: 4},
		tail: {group: additionalLines, number: 2}, container: {id: 'additional-212', order: 2}, isOnlySchemas: true,
		isBlue: true
	},
	{type: SwitchType.HRSwitch, id: 'r213', name: 'Р213', head: {group: other, number: 4},
		tail: {group: additionalLines, number: 3}, container: {id: 'additional-213', order: 2}, isOnlySchemas: true,
		isBlue: true
	},
	{type: SwitchType.HRSwitch, id: 'r214', name: 'Р214', head: {group: other, number: 4},
		tail: {group: additionalLines, number: 4}, container: {id: 'additional-214', order: 2}, isOnlySchemas: true,
		isBlue: true
	},
	{type: SwitchType.HRSwitch, id: 'r215', name: 'Р215', head: {group: other, number: 4},
		tail: {group: additionalLines, number: 5}, container: {id: 'additional-215', order: 2}, isOnlySchemas: true,
		isBlue: true
	},
	{type: SwitchType.HRSwitch, id: 'r216', name: 'Р216', head: {group: other, number: 4},
		tail: {group: additionalLines, number: 6}, container: {id: 'additional-216', order: 2}, isOnlySchemas: true,
		isBlue: true
	}
]

const containers = {
	'branch-0.1': {left: 2325, top: 110, height: 610, width: 1},

	'branch-1.1': {left: 168, top: 110, height: 640, width: 1},
	'branch-1.2': {left: 409, top: 149, height: 450, width: 1},
	'branch-1.3': {left: 2205, top: 886, height: 320, width: 1},


	'branch-SHSN': {left: 2334, top: 886, height: 150, width: 1},

	'branch-3.1': {left: 2565, top: 110, height: 630, width: 1},
	'branch-3.2': {left: 2805, top: 149, height: 1050, width: 1},
	'branch-3.3': {left: 2693, top: 886, height: 310, width: 1},

	'branch-5.1': {left: 2086, top: 110, height: 680, width: 1},
	'branch-5.2': {left: 1847, top: 760, height: 580, width: 1},

	'branch-6.1': {left: 1846, top: 110, height: 600, width: 1},
	'branch-6.2': {left: 1411, top: 681, height: 700, width: 1},

	'branch-7': {left: 1606, top: 110, height: 446, width: 1},

	'branch-8': {left: 1127, top: 110, height: 200, width: 1},

	'branch-9': {left: 887, top: 110, height: 1300, width: 1},

	'branch-10': {left: 647, top: 110, height: 446, width: 1},

	'additional-5': {left: 669, top: 1006, height: 1, width: 185, position: 'h'},
	'additional-6': {left: 1193, top: 1006, height: 1, width: 190, position: 'h'},
	'additional-9': {left: 1607, top: 1006, height: 1, width: 220, position: 'h'},
	'additional-211': {left: 110, top: 1505, height: 200, width: 1},
	'additional-212': {left: 229, top: 1505, height: 200, width: 1},
	'additional-213': {left: 349, top: 1505, height: 200, width: 1},
	'additional-214': {left: 469, top: 1505, height: 200, width: 1},
	'additional-215': {left: 589, top: 1505, height: 200, width: 1},
	'additional-216': {left: 709, top: 1505, height: 200, width: 1},


	'branch-111': {left: 1149, top: 1505, height: 450, width: 1},
	'up-double-111': {height: 232, width: 34,
		double: {leftCont: 'up-left-111', rightCont: 'up-right-111'}, container: {id: 'branch-111', order: 1}
	},
	'up-left-111': {height: 232, width: 1},
	'up-right-111': {height: 143, width: 1},
	'down-double-111': {height: 137, width: 34,
		double: {leftCont: 'down-left-111', rightCont: 'down-right-111'}, container: {id: 'branch-111', order: 3}
	},
	'down-left-111': {height: 137, width: 1},
	'down-right-111': {height: 137, width: 1},
	'left-branch-111': {left: 1010, top: 1712, height: 210, width: 1},

	'branch-112': {left: 1390, top: 1505, height: 450, width: 1},
	'up-double-112': {height: 232, width: 34,
		double: {leftCont: 'up-left-112', rightCont: 'up-right-112'}, container: {id: 'branch-112', order: 1}
	},
	'up-left-112': {height: 232, width: 1},
	'up-right-112': {height: 143, width: 1},
	'down-double-112': {height: 137, width: 34,
		double: {leftCont: 'down-left-112', rightCont: 'down-right-112'}, container: {id: 'branch-112', order: 3}
	},
	'down-left-112': {height: 137, width: 1},
	'down-right-112': {height: 137, width: 1},
	'left-branch-112': {left: 1250, top: 1712, height: 210, width: 1},

	'branch-113': {left: 1629, top: 1505, height: 450, width: 1},
	'up-double-113': {height: 232, width: 34,
		double: {leftCont: 'up-left-113', rightCont: 'up-right-113'}, container: {id: 'branch-113', order: 1}
	},
	'up-left-113': {height: 232, width: 1},
	'up-right-113': {height: 143, width: 1},
	'down-double-113': {height: 137, width: 34,
		double: {leftCont: 'down-left-113', rightCont: 'down-right-113'}, container: {id: 'branch-113', order: 3}
	},
	'down-left-113': {height: 137, width: 1},
	'down-right-113': {height: 137, width: 1},
	'left-branch-113': {left: 1490, top: 1712, height: 210, width: 1},

	'branch-114': {left: 2348, top: 1505, height: 450, width: 1},
	'up-double-114': {height: 232, width: 34,
		double: {leftCont: 'up-left-114', rightCont: 'up-right-114'}, container: {id: 'branch-114', order: 1}
	},
	'up-left-114': {height: 232, width: 1},
	'up-right-114': {height: 143, width: 1},
	'down-double-114': {height: 137, width: 34,
		double: {leftCont: 'down-left-114', rightCont: 'down-right-114'}, container: {id: 'branch-114', order: 3}
	},
	'down-left-114': {height: 137, width: 1},
	'down-right-114': {height: 137, width: 1},
	'left-branch-114': {left: 2209, top: 1712, height: 210, width: 1},

	'branch-115': {left: 2587, top: 1505, height: 450, width: 1},
	'up-double-115': {height: 232, width: 34,
		double: {leftCont: 'up-left-115', rightCont: 'up-right-115'}, container: {id: 'branch-115', order: 1}
	},
	'up-left-115': {height: 232, width: 1},
	'up-right-115': {height: 143, width: 1},
	'down-double-115': {height: 137, width: 34,
		double: {leftCont: 'down-left-115', rightCont: 'down-right-115'}, container: {id: 'branch-115', order: 3}
	},
	'down-left-115': {height: 137, width: 1},
	'down-right-115': {height: 137, width: 1},
	'left-branch-115': {left: 2448, top: 1712, height: 210, width: 1},

	'branch-116': {left: 2826, top: 1505, height: 450, width: 1},
	'up-double-116': {height: 232, width: 34,
		double: {leftCont: 'up-left-116', rightCont: 'up-right-116'}, container: {id: 'branch-116', order: 1}
	},
	'up-left-116': {height: 232, width: 1},
	'up-right-116': {height: 143, width: 1},
	'down-double-116': {height: 137, width: 34,
		double: {leftCont: 'down-left-116', rightCont: 'down-right-116'}, container: {id: 'branch-116', order: 3}
	},
	'down-left-116': {height: 137, width: 1},
	'down-right-116': {height: 137, width: 1},
	'left-branch-116': {left: 2688, top: 1712, height: 210, width: 1},

	'branch-9-agr3': {left: 904, top: 1505, height: 450, width: 1},
	'up-double-9-agr3': {height: 232, width: 34,
		double: {leftCont: 'up-left-9-agr3', rightCont: 'up-right-9-agr3'}, container: {id: 'branch-9-agr3', order: 1}
	},
	'up-left-9-agr3': {height: 232, width: 1},
	'up-right-9-agr3': {height: 143, width: 1},
	'down-double-9-agr3': {height: 137, width: 34,
		double: {leftCont: 'down-left-9-agr3', rightCont: 'down-right-9-agr3'}, container: {id: 'branch-9-agr3', order: 3}
	},
	'down-left-9-agr3': {height: 137, width: 1},
	'down-right-9-agr3': {height: 137, width: 1},

	'branch-6-agr2': {left: 1867, top: 1503, height: 450, width: 1},
	'up-double-6-agr2': {height: 232, width: 34,
		double: {leftCont: 'up-left-6-agr2', rightCont: 'up-right-6-agr2'}, container: {id: 'branch-6-agr2', order: 1}
	},
	'up-left-6-agr2': {height: 232, width: 1},
	'up-right-6-agr2': {height: 143, width: 1},
	'down-double-6-agr2': {height: 137, width: 34,
		double: {leftCont: 'down-left-6-agr2', rightCont: 'down-right-6-agr2'}, container: {id: 'branch-6-agr2', order: 3}
	},
	'down-left-6-agr2': {height: 137, width: 1},
	'down-right-6-agr2': {height: 137, width: 1},

	'backupBranch': {left: 2108, top: 1503, height: 450, width: 1},
	'up-double-backupBranch': {height: 232, width: 34,
		double: {leftCont: 'up-left-backupBranch', rightCont: 'up-right-backupBranch'}, container: {id: 'backupBranch', order: 1}
	},
	'up-left-backupBranch': {height: 232, width: 1},
	'up-right-backupBranch': {height: 143, width: 1},
	'down-double-backupBranch': {height: 137, width: 34,
		double: {leftCont: 'down-left-backupBranch', rightCont: 'down-right-backupBranch'}, container: {id: 'backupBranch', order: 3}
	},
	'down-left-backupBranch': {height: 137, width: 1},
	'down-right-backupBranch': {height: 137, width: 1},

	'branch-5-agr1': {left: 3067, top: 1503, height: 450, width: 1},
	'up-double-5-agr1': {height: 232, width: 34,
		double: {leftCont: 'up-left-5-agr1', rightCont: 'up-right-5-agr1'}, container: {id: 'branch-5-agr1', order: 1}
	},
	'up-left-5-agr1': {height: 232, width: 1},
	'up-right-5-agr1': {height: 143, width: 1},
	'down-double-5-agr1': {height: 137, width: 34,
		double: {leftCont: 'down-left-5-agr1', rightCont: 'down-right-5-agr1'}, container: {id: 'branch-5-agr1', order: 3}
	},
	'down-left-5-agr1': {height: 137, width: 1},
	'down-right-5-agr1': {height: 137, width: 1},
}

const text = [
	{text: '10кВ', left: 87, top: 38, fontSize: 1},
	{text: 'РУ-10кВ СШ №1', left: 530, top: 23, fontSize: 1.2},
	{text: 'РУ-10кВ СШ №2', left: 2061, top: 23, fontSize: 1.2},
	{text: '10кВ', left: 2817, top: 38, fontSize: 1},
	{text: 1, left: 143, top: 121},
	{text: 2, left: 381, top: 121},
	{text: 10, left: 611, top: 121},
	{text: 9, left: 861, top: 121},
	{text: 8, left: 1101, top: 121},
	{text: 7, left: 1580, top: 121},
	{text: 6, left: 1819, top: 121},
	{text: 5, left: 2059, top: 121},
	{text: 0, left: 2299, top: 121},
	{text: 3, left: 2539, top: 121},
	{text: 4, left: 2778, top: 121},
	{text: '- 600B', left: 666, top: 1423, fontSize: 1.2},
	{text: 211, left: 60, top: 1514},
	{text: 212, left: 180, top: 1514},
	{text: 213, left: 300, top: 1514},
	{text: 214, left: 419, top: 1514},
	{text: 215, left: 539, top: 1514},
	{text: 216, left: 659, top: 1514},
	{text: 'Лінія 211', left: 54, top: 1701, fontSize: 0.8},
	{text: 'Лінія 212', left: 174, top: 1701, fontSize: 0.8},
	{text: 'Лінія 213', left: 293, top: 1701, fontSize: 0.8},
	{text: 'Лінія 214', left: 413, top: 1701, fontSize: 0.8},
	{text: 'Лінія 215', left: 533, top: 1701, fontSize: 0.8},
	{text: 'Лінія 216', left: 653, top: 1701, fontSize: 0.8},
	{text: 'Агрегат №3', left: 844, top: 1936},
	{text: 'Пивзавод-вул. В.Пугачова', left: 985, top: 1936, fontSize: 0.8, angle: -15},
	{text: 'Керченська-вул.Козацька', left: 1232, top: 1936, fontSize: 0.8, angle: -15},
	{text: 'вул.Козацька-Пивзавод', left: 1481, top: 1936, fontSize: 0.8, angle: -15},
	{text: 'Агрегат №2', left: 1803, top: 1936},
	{text: 'Запасний БА', left: 2033, top: 1936},
	{text: 'вул.Київська-КрАЗ,ДЕПО', left: 2188, top: 1936, fontSize: 0.8, angle: -15},
	{text: 'зуп.В.Пугачова-Мрія', left: 2433, top: 1936, fontSize: 0.8, angle: -15},
	{text: 'зуп.В.Пугачова-Автопарк, ДЕПО', left: 2634, top: 1936, fontSize: 0.8, angle: -15},
	{text: 'Агрегат №1', left: 3001, top: 1936},
	{text: 'Запасна', left: 2826, top: 1429, fontSize: 1.2},
	{text: 'шина +600В', left: 3076, top: 1429, fontSize: 1.2},
	{text: 'Робоча', left: 2850, top: 1516, fontSize: 1.2},
	{text: 'шина +600В', left: 3076, top: 1516, fontSize: 1.2},
	{text: '0.4кВ СШ№1', left: 2127, top: 810, fontSize: 1},
	{text: 'ЩСН', left: 2401, top: 774, fontSize: 1.2},
	{text: 'СШ№2 0.4кВ', left: 2583, top: 810, fontSize: 1},
	{text: 'Ввід №1', left: 123, top: 620},
	{text: 'Від РП-7', left: 118, top: 655},
	{text: 'ком. №6 РЕС', left: 106, top: 690},
	{text: 'Ввід №2', left: 2517, top: 620},
	{text: 'Від РП-7', left: 2513, top: 655},
	{text: 'ком. №7 РЕС', left: 2508, top: 690},
	{text: 'Резерв', left: 2286, top: 640},
]

let config = {
	switches: [
		branch0, branch1, branch3, branch5, branch6, branch7, branch8, branch9, branch10, branch111,
		branch112, branch113, branch114, branch115, branch116, backupBranch, branchSHSN, additionalBranch,
	],
	lines: {
		other, lines0, lines1, lines3, lines5, lines6, lines7, lines8, lines9, lines10, lines111,
		lines112, lines113, lines114, lines115, lines116, linesBackup,linesSHSN, additionalLines,
	},
	containers,
	text
}

config = configGenerator(TPS_ID, config)
// console.log(`config tps ${TPS_ID}`, config)

const TPS2 = {
	id: TPS_ID,
	size: {width: 3205, height: 2100, minHeight: 800},
	image: backgroundImage,
	isExist: false,
	lines: Object.values(config.lines),
	switches: [config.switches],
	containers: config.containers,
	activeLines: [other[0]],
	text: config.text
}

export default TPS2
