const initialState = {
	warningHandler: null,
	errorHandler: null,
}

export const HomePageActionTypes = {
	SET_WARNING_HANDLER: 'SET_WARNING_HANDLER_HomePageActionTypes',
	SET_ERROR_HANDLER: 'SET_ERROR_HANDLER_HomePageActionTypes',
}

export const homePageReducer = (state = initialState, action) => {
	switch (action.type) {
		case HomePageActionTypes.SET_WARNING_HANDLER:
			return {...state, warningHandler: action.payload}
		case HomePageActionTypes.SET_ERROR_HANDLER:
			return {...state, errorHandler: action.payload}
		default:
			return state
	}
}
