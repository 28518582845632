import React, {useEffect} from 'react';
import classes from './HomePage.module.css';
import Card from '../../components/TPS/home-card/Card';
import {useFunctionInterval} from '../../hooks/useFunctionInterval';
import {useLoading} from '../../hooks/useLoading';
import {useSelector} from 'react-redux';
import {useActions} from '../../hooks/useActions';
import {useModal} from '../../hooks/useModal';
import {useWarning} from '../../hooks/useWarning';
import {Typography} from '@mui/material';


function HomePage() {
	const {loader, setLoading} = useLoading('white', 'Завантаження...')
	const {list, isLoading} = useSelector(store => store.tps)
	const modal = useModal('sm', 'Ок')
	const {warningHandler, errorHandler} = useWarning(modal)
	const {setHomePageWarningHandler, setHomePageErrorHandler} = useActions()
	const {fetchTpsList} = useActions()

	useFunctionInterval(fetchTpsList, 2000)

	useEffect(() => {
		if (!isLoading) {
			setLoading(false)
		}
		// eslint-disable-next-line
	}, [isLoading])

	useEffect(() => {
		setHomePageWarningHandler(warningHandler)
		setHomePageErrorHandler(errorHandler)
		// eslint-disable-next-line
	}, [])

	return (
		<div className={classes.container} data-cy='home-cont'>
			<div className={classes.wrapper}>
				{
					loader || (
						list.length
							? list.map(item =>
								<Card key={item.id} element={item}/>
							)
							: (<Typography variant={"h5"} sx={{marginTop: 4, color: 'white'}}>
								Немає доступних ТПС
							</Typography>)
					)

				}
			</div>
			{modal.window}
		</div>
	)
}

export default HomePage;
