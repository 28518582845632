module.exports = {
	develop: false,
	storageKey: 'userData',
	lineWidth: 2,
	background: 'grey',
	tps: ['TPS1', 'TPS2', 'TPS3', 'TPS4', 'TPS5', 'TPS6', 'TPS7', 'TPS8', 'TPS9', 'TP187'],
	time: {
		format: 'D.M.Y H:m:s',
		zone: '+02',
	},
	colors: {
		red: 'rgb(255, 32, 32)',
		green: 'rgb(22, 255, 22)',
		black: 'rgb(0, 0, 0)',
		blue: 'rgb(50, 150, 255)'
	},
}
