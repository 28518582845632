import React from 'react';
import classes from './ValidateCode.module.css'
import {Typography} from "@mui/material";
import Input from "../../UI/input/Input";


function ValidateCode({formHandler}) {
	return (
		<div className={classes.container}>
			<Typography className={classes.title}>
				Введіть код з додатку Google Authenticator:
			</Typography>
			<Input
				label='код'
				name='pin'
				onChange={formHandler}
			/>
		</div>
	)
}

export default ValidateCode;
