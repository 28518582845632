import {Switch} from "../Switch";
import classes from "./TSNSwitch.module.css";
import {lineWidth, colors} from "../../../../config/default";


export class TSNSwitch extends Switch {
	#DOM = {elements: {}, topSticks: {}, bottomSticks: {}}

	constructor(className, tpsId, id, name, left, top, head, tail, height, width) {
		super(className, tpsId, id, name, left, top, head, tail)
		this.__setSize(height ?? 191, width ?? 109)
	}

	__createElement() {
		const container = document.createElement('div')
		container.classList.add(classes.container)

		const topCircle = document.createElement('div')
		topCircle.classList.add(classes.circle)
		topCircle.style.borderWidth = lineWidth + 'px'
		topCircle.style.width = `calc(100% - ${lineWidth}px)`
		container.insertAdjacentElement('beforeend', topCircle)
		this.#DOM.elements.topCircle = topCircle

		const bottomCircle = topCircle.cloneNode(true)
		bottomCircle.classList.add(classes['bottom-circle'])
		container.insertAdjacentElement('beforeend', bottomCircle)
		this.#DOM.elements.bottomCircle = bottomCircle

		const stick = document.createElement('div')
		stick.classList.add(classes.stick)
		stick.style.width = lineWidth + 'px'
		this.#DOM.topSticks = {left: stick.cloneNode(), center: stick.cloneNode(), right:stick.cloneNode()}
		this.#DOM.topSticks.left.classList.add(classes['left-stick'])
		this.#DOM.topSticks.right.classList.add(classes['right-stick'])
		this.#DOM.topSticks.center.style.bottom = lineWidth + 'px'
		this.#DOM.topSticks.center.style.height = `calc(${this.#DOM.topSticks.center.style.height} + ${lineWidth * 2}px)`
		Object.entries(this.#DOM.topSticks).forEach(item => {
			this.#DOM.bottomSticks[item[0]] = item[1].cloneNode()
			topCircle.insertAdjacentElement('beforeend', item[1])
			bottomCircle.insertAdjacentElement('beforeend', this.#DOM.bottomSticks[item[0]])
		})

		return container
	}

	__setOnState() {
		Object.values(this.#DOM.elements).forEach(item => item.style.borderColor = colors.red)
		Object.values(this.#DOM.topSticks).forEach(item => item.style.background = colors.red)
		Object.values(this.#DOM.bottomSticks).forEach(item => item.style.background = colors.red)
	}

	__setOffState() {
		Object.values(this.#DOM.elements).forEach(item => item.style.borderColor = colors.green)
		Object.values(this.#DOM.topSticks).forEach(item => item.style.background = colors.green)
		Object.values(this.#DOM.bottomSticks).forEach(item => item.style.background = colors.green)
	}

	__setRepairState() {
		Object.values(this.#DOM.elements).forEach(item => item.style.borderColor = colors.black)
		Object.values(this.#DOM.topSticks).forEach(item => item.style.background = colors.black)
		Object.values(this.#DOM.bottomSticks).forEach(item => item.style.background = colors.black)
	}

	__setSize(height, width) {
		if (height / width > 2 || height / width < 1.7) {
			throw new Error(`Invalid aspect ration: ${height / width}! Necessary 1.7 < aspect ration < 2.`)
		}
		super.__setSize(height, width)
	}
}
