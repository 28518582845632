import classes from "./UserChangePass.module.css";
import {Button, Typography} from "@mui/material";
import Loader from "../../../components/UI/loader/Loader";
import React, {useRef, useState} from "react";
import {useHttp} from "../../../hooks/useHttp";
import {useModal} from "../../../hooks/useModal";
import Input from "../../../components/UI/input/Input";

import {useSelector} from "react-redux";

function UserChangePass() {
	const {userData} = useSelector(store => store.auth)
	const [loading] = useState(false)
	const form = useRef({oldPass: null, newPass: null})
	const {post} = useHttp()
	const modal = useModal()
	const okModal = useModal('sm', 'OK')
	okModal.title = modal.title = 'Зміна паролю'

	const formHandler = (event) => {
		form.current = {...form.current, [event.target.name]: event.target.value}
	}

	const createModal = async () => {
		modal.content = (
			<>
				<Typography variant='body1' sx={{fontSize: '1.2rem'}}>
					Для зміни пароля введіть старий та новий паролі:
				</Typography>
				<Typography sx={{margin: '1rem 0 0.7rem'}}>
					Поточний пароль користувача
				</Typography>
				<Input
					name='oldPass'
					type='password'
					onChange={formHandler}
				/>
				<Typography sx={{margin: '1.2rem 0 0.7rem'}}>
					Новий пароль
				</Typography>
				<Input
					name='newPass'
					type='password'
					onChange={formHandler}
				/>
			</>
		)

		modal.confirm = () => editConfirm({
			...form.current
		});
		modal.open();
	}

	const checkOldPass = async () => {
		const {id} = userData
		const {oldPass} = form.current
		const {response} = await post('/api/user/check-pass/', {userId: id, password: oldPass})
		return response;
	}

	const editConfirm = async () => {

		const {id} = userData
		const {newPass} = form.current
		const fields = {password: newPass}
		const isValid = await checkOldPass()
		if (isValid === true) {
			okModal.content = (
				<>
					<div className={classes.green}>
						<Typography sx={{margin: '1rem 0 0.7rem'}}>
							ПАРОЛЬ УСПІШНО ЗМІНЕНО
						</Typography>
					</div>
				</>
			)
			await post('/api/user/edit', {id, fields})
		} else {
			okModal.content = (
				<>
					<div className={classes.red}>
						<Typography sx={{margin: '1rem 0 0.7rem'}}>
							ПОМИЛКА
						</Typography>
					</div>
				</>
			)

		}
		okModal.open()
	}

	return (
		<div className={classes.container}>
			<div className={classes['state-handler']}>
				<Typography variant='h6'>
					Пароль
				</Typography>
				<Button
					onClick={createModal}
					variant="contained"
				>Змінити</Button>
			</div>
			{modal.window}
			{okModal.window}
			{!loading ||
				<div className={classes.loading}><Loader/></div>
			}
		</div>
	)
}

export default UserChangePass;
