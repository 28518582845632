import {Line} from "./Line";
import {isString, round} from "../../../utils/utils";
import {createLineName} from "../../../utils/createName";


export class ImageLine extends Line {
	#size = {
		height: 0,
		width: 0,
		heightInPercent: 0,
		widthInPercent: 0,
	}
	#nameCorrection = {top: 1, left: 1}
	#name

	constructor(left, top, height, width, position, name, nameCorrection) {
		super(left, top, 0, position)
		this.__setSize(height, width)
		this.name = name ?? ''
		this.nameCorrection = nameCorrection
	}

	__createLineContainer() {
		const container = document.createElement('div')
		container.className = 'line'
		container.dataset.cy = 'iln-cont'
		container.style.left = round(this.left * 100 / Line.SIZE.width, 3) + '%'
		container.style.top = round(this.top * 100 / Line.SIZE.height, 3) + '%'
		this.#size.widthInPercent = round(this.#size.width * 100 / Line.SIZE.width, 3)
		this.#size.heightInPercent = round(this.#size.height * 100 / Line.SIZE.height, 3)
		container.style.width = this.#size.widthInPercent + '%'
		container.style.height = this.#size.heightInPercent + '%'
		container.insertAdjacentElement('beforeend', this.__createElement())
		createLineName(this.name, container)
		return container
	}

	__createElement() {
		throw new Error('Not init function __createElement!')
	}

	__setOnState() {
		throw new Error('Not init function: __setOnState!')
	}

	__setOffState() {
		throw new Error('Not init function: __setOffState!')
	}

	__setRepairState() {
		throw new Error('Not init function: __setRepairState!')
	}

	addToContainer(container, height, width) {
		if (typeof height !== 'number' || typeof width !== 'number') {
			throw new Error('Invalid height or width!')
		}
		if (!this.DOMContainer?.style) {
			return
		}
		const itemHeight = 100 / height * this.#size.heightInPercent
		const itemWidth = 100 / width * this.#size.widthInPercent
		this.DOMContainer.style.height = itemHeight + '%'
		this.DOMContainer.style.width = itemWidth + '%'
		this.DOMContainer.style.position = 'relative'
		this.DOMContainer.style.left = 0
		this.DOMContainer.style.top = 0
		this.isInContainer = true
		container.insertAdjacentElement("beforeend", this.DOMContainer)
	}

	__setSize(height, width) {
		if (typeof height === 'number' && height > 0) {
			this.#size.height = height
		}
		if (typeof width === 'number' && width > 0) {
			this.#size.width = width
		}
	}

	set nameCorrection(value) {
		this.#nameCorrection = {...this.#nameCorrection, ...value}
	}

	get nameCorrection() {
		return this.#nameCorrection
	}

	set name(value) {
		if (isString(value)) {
			this.#name = value
		}
	}

	get name() {
		return this.#name
	}
}
