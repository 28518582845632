import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import HomeIcon from '@mui/icons-material/Home';
import AccountManagementPage from '../pages/account-management/AccountManagementPage';
import UserSettingPage from "../pages/user-setting/UserSettingPage";
import TpsPage from '../pages/tps/TpsPage';
import HomePage from '../pages/home/HomePage';
import LogsPage from '../pages/logs/LogsPage';
import ErrorPage from '../pages/error/ErrorPage';


//	if the route has title, it is displayed in the sidebar

const iconStyle = {color: 'white'}
export const routes = [
	{
		title: 'Домашня сторінка',
		icon: <HomeIcon fontSize='large' style={iconStyle} />,
		id: 'home',
		path: '/',
		element: <HomePage />
	},
	{
		title: 'Архів подій',
		icon: <LibraryBooksIcon sx={{fontSize: 31, padding: '2px'}} style={iconStyle} />,
		id: 'logs',
		path: '/logs',
		element: <LogsPage />
	},
	{
		title: null,
		icon: null,
		id: 'tps',
		path: '/tps/:id',
		element: <TpsPage />
	},
	{
		title: null,
		icon: null,
		id: 'manage-account',
		path: '/account-management',
		element: <AccountManagementPage />
	},
	{
		title: null,
		icon: null,
		id: 'error',
		path: '/error/:error',
		element: <ErrorPage />
	},
	{
		title: null,
		icon: null,
		id: 'user-setting',
		path: '/user-setting',
		element: <UserSettingPage />
	},
]

export const defaultPage = routes[0].element
