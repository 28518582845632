const initialState = {
	show: false,
	menuHandler: () => {},
}

export const SidebarActionTypes = {
	SHOW: 'SHOW_SidebarActionTypes',
	HIDE: 'HIDE_SidebarActionTypes',
	SET_MENU_HANDLER: 'SET_MENU_HANDLER_SidebarActionTypes',
}

export const sidebarReducer = (state = initialState, action) => {
	switch (action.type) {
		case SidebarActionTypes.SHOW:
			return {...state, show: true}
		case SidebarActionTypes.HIDE:
			return {...state, show: false}
		case SidebarActionTypes.SET_MENU_HANDLER:
			return {...state, menuHandler: action.payload}
		default:
			return state
	}
}
