import {isInteger, isString, round} from "../../../utils/utils";
import {createSwitchName} from "../../../utils/createName";
import {post} from "../../../utils/http";
import config from "../../../config/default";


const REQUEST_TYPE = {
	'x': 'set-device-x',
	'y': 'set-device-y',
}

export class Switch {
	static CONTAINER
	static SIZE
	static TPSID
	static ALL = {}
	static FUNC = () => {}
	static #REQUEST_TYPE = REQUEST_TYPE
	static ERROR
	#size = {
		height: 0,
		width: 0,
		heightInPercent: 0,
		widthInPercent: 0,
	}
	#DOM = {container: false, error: false, warning: false}
	#type = REQUEST_TYPE['y']
	#isInContainer = false
	#isInteractive = true
	#warningList = []
	#errorList = []
	#depLines = []
	#position = 'v'
	#className
	#logName
	#state
	#name
	#head
	#tail
	#left
	#top
	#id

	constructor(className, tpsId, id, name, left, top, head, tail) {
		this.#setClassName(className)
		this.name = name
		this.#setId(tpsId, id)
		this.left = left ?? 0
		this.top = top ?? 0
		this.head = head
		this.#tail = tail
	}

	generation() {
		this.#createSwitchContainer()
		const element = this.__createElement()
		if (!element) {
			throw new Error('Invalid created element!')
		}
		this.#DOM.container.insertAdjacentElement('afterbegin', element)
		this.#state = 0
		this.__setOffState()
		this.#addEvent()
	}

	#createSwitchContainer() {
		if (!Switch.SIZE || !Switch.CONTAINER) {
			throw new Error('Class has no size or container!')
		}
		const container = document.createElement('div')
		container.id = this.#id
		container.className = 'switch'
		container.dataset.cy = 'sw-cont'
		container.style.left = round(this.left * 100 / Switch.SIZE.width, 3) + '%'
		container.style.top = round(this.top * 100 / Switch.SIZE.height, 3) + '%'
		this.#size.widthInPercent = round(this.#size.width * 100 / Switch.SIZE.width, 3)
		this.#size.heightInPercent = round(this.#size.height * 100 / Switch.SIZE.height, 3)
		container.style.width = this.#size.widthInPercent + '%'
		container.style.height = this.#size.heightInPercent + '%'

		createSwitchName(this.#name, container, this.isOnlySchemas() || !this.isInteractive())
		this.#createWarning(container)
		if (!this.#isInContainer) {
			Switch.CONTAINER.insertAdjacentElement('beforeend', container)
		}
		this.#DOM.container = container
	}

	__createElement() {
		throw new Error('Not init function: __createElement!')
	}

	#addEvent() {
		this.#DOM.container.addEventListener('click', Switch.FUNC.bind(this))
	}

	#createWarning(DOMElement) {
		const warning = document.createElement('img')
		warning.src = require('../../../assets/icons/warning.png')
		warning.classList.add('hide-warn', 'switch-warn')
		warning.style.left = -25 + 'px'
		warning.style.top = -20 + 'px'
		this.#DOM.warning = warning
		DOMElement.insertAdjacentElement('beforeend', warning)
		const error = document.createElement('img')
		error.src = require('../../../assets/icons/error.png')
		error.classList.add('hide-warn', 'switch-err')
		error.style.right = -20 + 'px'
		error.style.bottom = -20 + 'px'
		this.#DOM.error = error
		DOMElement.insertAdjacentElement('beforeend', error)
	}

	on() {
		if (this.#state === 1) return
		this.state = 1
		this.__setOnState()
		this.update()
	}

	off() {
		if (this.#state === 0) return
		this.state = 0
		this.__setOffState()
		this.update()
	}

	repair() {
		if (this.#state === 2) return
		this.state = 2
		this.__setRepairState()
		this.update()
	}

	__setOnState() {
		throw new Error('Not init function: __setOnState!')
	}

	__setOffState() {
		throw new Error('Not init function: __setOffState!')
	}

	__setRepairState() {
		throw new Error('Not init function: __setRepairState!')
	}

	update() {
		if (this.#state === 1) {
			this.updateDepend('on')
			if (this.#head.state === 1) {
				this.#tail.on()
			} else if (this.#head.state === 0) {
				this.#tail.off()
			} else if (this.#head.state === 2) {
				this.#tail.repair()
			}
		} else if (this.#state === 0) {
			if (this.#head.state === 1 || this.#head.state === 0) {
				this.#tail.off()
				this.updateDepend('off')
			} else if (this.#head.state === 2) {
				this.#tail.repair()
				this.updateDepend('repair')
			}
		} else if (this.#state === 2) {
			this.updateDepend('repair')
			this.#tail.repair()
		}
	}

	updateDepend(command) {
		switch (command) {
			case 'on':
				command = (item) => item.on()
				break
			case 'off':
				command = (item) => item.off()
				break
			case 'repair':
				command = (item) => item.repair()
				break
			default:
				return
		}
		this.#depLines.forEach(command)
	}

	async requestToServer(state, startLoadFunc, message) {
		if (state === 'on') {
			state = 1
		} else if (state === 'off') {
			state = 0
		} else if (state === 'repair') {
			state = 2
		} else {
			throw new Error('Incorrect switch state: ' + state)
		}
		const body = {tpsId: Switch.TPSID, devId: this.#id, devState: state, devMes: message || ''}
		startLoadFunc()
		await post.call(this, `/api/tps/${this.#type}`, body)
	}

	addToContainer(container, height, width) {
		if (typeof height !== 'number' || typeof width !== 'number') {
			throw new Error('Invalid height or width!')
		}
		if (!this.#DOM?.container?.style) {
			return
		}
		const itemHeight = 100 / height * this.#size.heightInPercent
		const itemWidth = 100 / width * this.#size.widthInPercent
		this.#DOM.container.style.height = itemHeight + '%'
		this.#DOM.container.style.width = itemWidth + '%'
		this.#DOM.container.style.position = 'relative'
		this.#DOM.container.style.left = 0
		this.#DOM.container.style.top = 0
		this.#isInContainer = true
		container.insertAdjacentElement("beforeend", this.#DOM.container)
	}

	addWarning(value) {
		this.#warningList.push(value)
		if (this.#DOM.warning) {
			this.#DOM.warning?.classList.remove('hide-warn')
		}
	}

	addError(value) {
		this.#errorList.push(value)
		if (this.#DOM.error) {
			this.#DOM.error?.classList.remove('hide-warn')
		}
	}

	resetWarning() {
		this.#warningList = []
		if (this.#DOM.warning) {
			this.#DOM.warning?.classList.add('hide-warn')
		}
	}

	resetError() {
		this.#errorList = []
		if (this.#DOM.error) {
			this.#DOM.error.classList.add('hide-warn')
		}
	}

	__setSize(height, width) {
		if (!isInteger(height) || height <= 0) {
			throw new Error(`Invalid height: ${height}! Necessary more then 0.`)
		}
		if (!isInteger(width) || width <= 0) {
			throw new Error(`Invalid width: ${width}! Necessary more then 0.`)
		}
		this.size.height = height
		this.size.width = width
	}

	setDepLine(value) {
		this.#depLines.push(value)
	}

	setOnlyOnSchemasSwitch() {
		this.type = 'x'
	}

	setNotInteractiveSwitch() {
		this.#isInteractive = false
		this.type = 'x'
	}

	isInteractive() {
		return this.#isInteractive
	}

	isOnlySchemas() {
		return (this.#type === Switch.#REQUEST_TYPE['x']) && this.isInteractive()
	}

	isStateOn() {
		return this.#state === 1
	}

	isStateRepair() {
		return this.#state === 2
	}

	get warningList() {
		return this.#warningList
	}

	get errorList() {
		return this.#errorList
	}

	set head(value) {
		this.#head = value
		this.#head.addSwitch(this)
	}

	get head() {
		return this.#head
	}

	get tail() {
		return this.#tail
	}

	#setId(tpsId, value) {
		if ((typeof value !== 'number' && typeof value !== 'string') || !value || value === '') {
			throw new Error(`ID is not a number or string!`)
		}
		if (!Switch.ALL.hasOwnProperty(tpsId)) {
			Switch.ALL[tpsId] = {}
		}
		if (Switch.ALL[tpsId][value]) {
			throw new Error(`Switch "${this.#name}" has not unique ID "${value}"!`)
		}
		Switch.ALL[tpsId][value] = this
		this.#id = value
	}

	get id() {
		return this.#id
	}

	set name(value) {
		if (!isString(value)) {
			throw new Error(`Name is not a string!`)
		}
		this.#name = value
	}

	get name() {
		return this.#name
	}

	set position(value) {
		if (value !== 'v' && value !== 'h') {
			throw new Error(`Invalid position type in HRSwitch!`)
		}
		this.#position = value
	}

	get position() {
		return this.#position
	}

	set logName(value) {
		if (!isString(value)) {
			throw new Error(`Invalid log name value in ${this.name}!`)
		}
		this.#logName = value
	}

	get logName() {
		return this.#logName
	}

	set left(value) {
		if (!isInteger(value)) {
			throw new Error(`Left is not a integer number!`)
		}
		this.#left = value
	}

	get left() {
		return this.#left
	}

	set top(value) {
		if (!isInteger(value)) {
			throw new Error(`Top is not a integer number!`)
		}
		this.#top = value
	}

	get top() {
		return this.#top
	}

	set state(value) {
		if (!isInteger(value)) {
			throw new Error(`State is not a integer number!`)
		}
		if (value < 0) {
			throw new Error(`State is less than 0!`)
		}
		this.#state = value
	}

	get state() {
		return this.#state
	}

	set type(value) {
		if (Switch.#REQUEST_TYPE.hasOwnProperty(value)) {
			return this.#type = Switch.#REQUEST_TYPE[value]
		}
		throw new Error('Invalid value for switch type: '+ value)
	}

	get size() {
		return this.#size
	}

	get DOMContainer() {
		return this.#DOM.container
	}

	#setClassName(value) {
		if (!isString(value)) {
			throw new Error(`Class name is not a string!`)
		}
		this.#className = value
	}

	get className() {
		return this.#className
	}

	get isSwitch() {
		return true
	}

	static async generateSwitchNames() {
		const allTps = {}
		for (const item of config.tps) {
			let tps = await import(`../../../config/tps/${item}`)
			if (!tps || !tps.hasOwnProperty('default')) {
				continue
			}
			tps = tps.default
			if (!tps.hasOwnProperty('id')) {
				continue
			}
			allTps[tps.id] = {}
			const switches = tps.switches
			if (!Array.isArray(switches)) {
				continue
			}
			switches.flat().forEach(item => {
				allTps[tps.id][item.id] = item.logName ?? item.name
			})
		}
		return allTps
	}
}
