import {TpsActionTypes} from '../../reduces/other/tpsReducer';
import {ScadaActionTypes} from '../../reduces/other/scadaReducer';
import {get} from '../../../utils/http';


export const fetchTpsList = () => {
	return async (dispatch) => {
		dispatch({type: TpsActionTypes.SET_LOADING_ERROR, payload: null})
		try {
			const {data} = await get('/api/tps/list')
			dispatch({type: TpsActionTypes.SET_TPS_LIST, payload: data})
		} catch {
			dispatch({type: TpsActionTypes.SET_LOADING_ERROR, payload: 'Помилка загрузки!'})
		}
	}
}

export const setActiveTps = (payload) => {
	payload = payload === 'string' ? parseInt(payload) : payload
	return async (dispatch) => {
		dispatch({type: ScadaActionTypes.SET_ACTIVE_CONFIG, payload})
		dispatch({type: TpsActionTypes.SET_ACTIVE_TPS, payload})
	}
}

export const setIconData = (tpsId, data) => ({type: TpsActionTypes.SET_ICON_DATA, payload: {tpsId, data}})
export const setLogData = (tpsId, data) => ({type: TpsActionTypes.SET_LOG_DATA, payload: {tpsId, data}})
export const setTpsAlarms = (type, data) => ({type: TpsActionTypes.SET_ALARMS, payload: {type, data}})
export const clearTpsAlarms = (type) => ({type: TpsActionTypes.CLEAR_ALARMS, payload: {type}})
export const enableRefresh = () => ({type: TpsActionTypes.SET_REFRESH_ICON, payload: true})
export const disableRefresh = () => ({type: TpsActionTypes.SET_REFRESH_ICON, payload: false})
