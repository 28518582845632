import {combineReducers} from 'redux';
import {sidebarReducer} from './other/sidebarReducer';
import {tpsReducer} from './other/tpsReducer';
import {homePageReducer} from './other/homePageReducer';
import {scadaReducer} from './other/scadaReducer';
import {authReducer} from './other/authReducer';
import {protectionModeReducer} from './other/protectionModeReducer';

export const rootReducer = combineReducers({
	sidebar: sidebarReducer,
	tps: tpsReducer,
	homePage: homePageReducer,
	scada: scadaReducer,
	auth: authReducer,
	protectionMode: protectionModeReducer,
})
