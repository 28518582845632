import {ImageLine} from "../ImageLine";
import classes from "../../switches/HRSwitch/HRSwitch.module.css";
import {lineWidth, colors} from "../../../../config/default";


export class HRLine extends ImageLine {
	#DOM = {}

	constructor(left, top, height, width, position, name, nameCorrection) {
		super(left, top, height ?? 45, width ?? 45, position, name, nameCorrection)
	}

	__createElement() {
		const container = document.createElement('div')
		container.classList.add(classes.container)
		if (this.position === 'h') {
			container.classList.add(classes.horizontal, 'hor-name')
		}

		const horLine = document.createElement('div')
		horLine.classList.add(classes['hor-line'])
		horLine.style.height = lineWidth + 'px'
		container.insertAdjacentElement('beforeend', horLine)
		this.#DOM.horLine = horLine

		const vertLine = document.createElement('div')
		vertLine.classList.add(classes['vert-line'])
		vertLine.style.width = lineWidth + 'px'
		container.insertAdjacentElement('beforeend', vertLine)
		this.#DOM.vertLine = vertLine

		return container
	}

	__setOnState() {
		Object.values(this.#DOM).forEach(item => item.style.background = colors.red)
		this.#DOM.vertLine.style.transform = `rotate(0)`
	}

	__setOffState() {
		Object.values(this.#DOM).forEach(item => item.style.background = colors.green)
		this.#DOM.vertLine.style.transform = `rotate(-35deg)`
	}

	__setRepairState() {
		Object.values(this.#DOM).forEach(item => item.style.background = colors.black)
		this.#DOM.vertLine.style.transform = `rotate(0)`
	}
}
