import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";


export const useCreateUser = (rows, createUserHandler) => {
	const [newRows, setNewRows] = useState(rows)

	const createUserButton = (
		<Button
			className='bg-success'
			style={{color: 'white'}}
			onClick={createUserHandler}
		><AddIcon /></Button>
	)

	useEffect(() => {
		if (rows.length) {
			const newRows = [...rows, {id: 1, action: createUserButton}]
			setNewRows(newRows)
		}
	}, [rows])

	return {newRows}
}
