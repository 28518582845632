import React, {useCallback, useState} from 'react';
import classes from './TpsButtonMenu.module.css'
import ChargingStationOutlinedIcon from '@mui/icons-material/ChargingStationOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import {Menu, MenuItem} from '@mui/material';
import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import {useOpenTps} from '../../../hooks/useOpenTps';
import {useSelector} from 'react-redux';


function TpsButtonMenu({list, activeTps}) {
	const {menuHandler} = useSelector(store => store.sidebar)
	const {alarmsArr} = useSelector(store => store.tps)
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const {openTps} = useOpenTps()

	function handleClick(event) {
		setAnchorEl(event.currentTarget)
	}

	function handleClose() {
		setAnchorEl(null)
	}

	const toTps = useCallback((id) => {
		handleClose()
		menuHandler()
		openTps(id)
		// eslint-disable-next-line
	}, [menuHandler])

	return (
		<div>
			<Button
				id="basic-button"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				sx={{color: 'black'}}
			>
				<ChargingStationOutlinedIcon/>
				<Typography
					variant={"subtitle1"}
					sx={{height: '1.5rem'}}
				>ТПС</Typography>
				{
					anchorEl
						? <ArrowDropUpOutlinedIcon/>
						: <ArrowDropDownOutlinedIcon/>
				}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{'aria-labelledby': 'basic-button'}}
			>
				{
					list.map((item) => {
						const isAlarm = alarmsArr.hasOwnProperty(item.id)
						const className = [
							(activeTps === item.id ? classes.active : classes.inActive),
							(isAlarm ? classes.alarm : '')
						].join(' ')

						return (
							<MenuItem
								key={item.id}
								onClick={toTps.bind(undefined, item.id)}
								className={className}
							>
								{item.name}
							</MenuItem>
						)
					})
				}
			</Menu>
		</div>
	)
}

export default TpsButtonMenu;
