import React, {useEffect, useRef, useState} from 'react';
import classes from './TwoFactorAuth.module.css';
import {Switch, Typography} from "@mui/material";
import {useModal} from "../../../hooks/useModal";
import CreateQrCode from "../../../components/qr-code/QrCode";
import ValidateCode from "../../../components/two-factor-auth/validate-code/ValidateCode";
import {useHttp} from "../../../hooks/useHttp";
import Loader from "../../../components/UI/loader/Loader";
import Input from "../../../components/UI/input/Input";


function TwoFactorAuth() {
	const [active, setActive] = useState(false)
	const [loading, setLoading] = useState(false)
	const form = useRef({pin: null, password: null})
	const {post, get} = useHttp()
	const modal = useModal()
	const validModal = useModal()
	const delModal = useModal('sm', 'Ok')
	delModal.title = validModal.title = modal.title = 'Двоетапна авторизація'

	const formHandler = (event) => {
		const name = event.target.name
		let value = event.target.value
		if (name === 'pin') {
			value = Number(value)
		}
		form.current = {...form.current, [name]: value}
	}

	const statusHandler = async (event) => {
		const state = event.target.checked
		if (state) {
			await createQrCode()
		} else {
			await destroyAuth()
		}
	}

	const createQrCode = async () => {
		modal.content = <CreateQrCode/>
		modal.confirm = validateCode
		modal.open()
	}

	const validateCode = () => {
		validModal.content = <ValidateCode formHandler={formHandler}/>
		validModal.confirm = async () => {
			setLoading(true)
			const {response, error} = await post('/api/user/two-factor/validate', form.current)
			setLoading(false)
			if (response && !error) {
				modal.content = 'Двоетапна авторизація успішно підключена!'
				get('/api/user/two-factor/save')
				setActive(true)
				modal.confirm = validModal.close
			} else {
				modal.content = 'Неправильний код! Спробуйте ще раз!'
			}
			modal.open()
		}
		validModal.open()
	}

	const destroyAuth = async () => {
		modal.content = (
			<>
				<Typography variant='body1' sx={{fontSize: '1.2rem'}}>
					Для відключення двоетапної авторизації введіть:
				</Typography>
				<Typography sx={{margin: '1rem 0 0.7rem'}}>
					Пароль користувача
				</Typography>
				<Input
					name='password'
					label='пароль'
					type='password'
					onChange={formHandler}
				/>
				<Typography sx={{margin: '1.2rem 0 0.7rem'}}>
					Код з додатку Google Authenticator
				</Typography>
				<Input
					name='pin'
					label='код'
					onChange={formHandler}
				/>
			</>
		)
		modal.confirm = async () => {
			setLoading(true)
			const {response, error} = await post('/api/user/two-factor/delete', form.current)
			setLoading(false)
			if (response && !error) {
				delModal.content = 'Двоетапна авторизація успішно відключена!'
				setActive(false)
				delModal.confirm = modal.close
			} else {
				delModal.content = 'Неправильний пароль або код! Спробуйте ще раз!'
				delModal.confirm = modal.open
			}
			delModal.open()
		}
		modal.open()
	}

	async function startRequest() {
		setLoading(true)
		await get('/api/user/two-factor/get')
			.then(({response}) => {
				setActive(response)
				setLoading(false)
			})
	}

	useEffect(() => {
		startRequest()
		// eslint-disable-next-line
	}, [])

	return (
		<div className={classes.container}>
			<div className={classes['state-handler']}>
				<Typography variant='h6'>
					Двоетапна аутентифікація
				</Typography>
				<Switch
					checked={active}
					onChange={statusHandler}
					inputProps={{'aria-label': 'controlled'}}
				/>
			</div>
			{modal.window}
			{validModal.window}
			{delModal.window}
			{!loading ||
				<div className={classes.loading}><Loader/></div>
			}
		</div>
	)
}

export default TwoFactorAuth;
