import classes from "./AGRLine.module.css";
import {lineWidth, colors} from "../../../../config/default";
import {ImageLine} from "../ImageLine";
import {TriangleLine} from "../TriangleLine/TriangleLine";


export class AGRLine extends ImageLine {
	#DOM = {}

	constructor(left, top, height, width, position, name, nameCorrection) {
		super(left, top, height ?? 100, width ?? 68, position, name, nameCorrection)
	}

	__createElement() {
		const container = document.createElement('div')
		container.classList.add(classes.container)
		container.style.borderWidth = lineWidth + 'px'
		container.style.height = `calc(100% - ${lineWidth * 2}px)`
		container.style.width = `calc(100% - ${lineWidth * 2}px)`

		const vertLineUp = document.createElement('div')
		vertLineUp.classList.add(classes['vert-line'])
		vertLineUp.style.width = lineWidth + 'px'

		const triangle = document.createElement('div')
		triangle.classList.add(classes.triangle)
		const triangleLine = new TriangleLine()
		triangleLine.generation()
		triangle.insertAdjacentElement('beforeend', triangleLine.container)

		const horLine = document.createElement('div')
		horLine.classList.add(classes['hor-line'])
		horLine.style.height = lineWidth + 'px'
		const vertLineDown = vertLineUp.cloneNode()

		container.insertAdjacentElement('beforeend', vertLineUp)
		container.insertAdjacentElement('beforeend', triangle)
		container.insertAdjacentElement('beforeend', horLine)
		container.insertAdjacentElement('beforeend', vertLineDown)
		this.#DOM = {container, triangleLine, lines: [vertLineUp, horLine, vertLineDown]}
		return container
	}

	__setOnState() {
		this.#DOM.container.style.borderColor = colors.red
		this.#DOM.lines.forEach(item => item.style.background = colors.red)
		this.#DOM.triangleLine.__setOnState()
	}

	__setOffState() {
		this.#DOM.container.style.borderColor = colors.green
		this.#DOM.lines.forEach(item => item.style.background = colors.green)
		this.#DOM.triangleLine.__setOffState()
	}

	__setRepairState() {
		this.#DOM.container.style.borderColor = colors.black
		this.#DOM.lines.forEach(item => item.style.background = colors.black)
		this.#DOM.triangleLine.__setRepairState()
	}

	get container () {
		return this.#DOM.container
	}
}
