import {Switch} from "../Switch";
import {DuplexSwitch} from "../DuplexSwitch/DuplexSwitch";


export function AlarmDuplexSwitch(instance) {
	instance = DuplexSwitch(instance)
	const oldRequestToServer = instance.requestToServer.bind(instance)
	instance.requestToServer = async function requestToServer(state, startLoadFunc, message) {
		if (state === 'on' && this.head.state === 1 && this.tail.state === 1) {
			this.error = Switch.ERROR
			return this.error(
				'Увага! Даний перемикач замкне джерела струму! Можливе тільки короткочасне включення! Включити перемикач?',
				oldRequestToServer.bind(this, state, startLoadFunc, message)
			)
		}
		await oldRequestToServer(state, startLoadFunc, message)
	}
	return instance
}
