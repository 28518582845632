export const configureLogsArr = (logs) => {
	return logs.map((item, ind) => {
		return {
			id: ind,
			station: item[0],
			timeCreate: item[1],
			user: item[2],
			switch: item[3],
			action: item[4],
			comment: item[5],
			status: item[6],
			type: item[8],
			state: item[9],
		}}
	)
}
