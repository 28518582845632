import TPS1 from '../../../config/tps/TPS1';
import TPS2 from '../../../config/tps/TPS2';
import TPS3 from "../../../config/tps/TPS3";
import TPS4 from "../../../config/tps/TPS4";
import TPS5 from "../../../config/tps/TPS5";
import TPS6 from "../../../config/tps/TPS6";
import TPS7 from "../../../config/tps/TPS7";
import TPS8 from "../../../config/tps/TPS8";
import TPS9 from "../../../config/tps/TPS9";
import TP187 from "../../../config/tps/TP187";


const initialState = {
	allSwitches: {},
	configs: {
		'1': {...TPS1},
		'2': {...TPS2},
		'3': {...TPS3},
		'4': {...TPS4},
		'5': {...TPS5},
		'6': {...TPS6},
		'7': {...TPS7},
		'8': {...TPS8},
		'9': {...TPS9},
		'187': {...TP187},
	},
	aggregatesCoord: {
		1: {ka1: {left: '13.2%', top: '18%'}, ka2: {left: '20.4%', top: '18%'}, ka3: {left: '49.1%', top: '18%'}},
		5: {ka1: {left: '26%', top: '18%'}, ka2: {left: '89.5%', top: '18%'}}
	},
	activeConfig: null,
	isGenerated: false,
}

export const ScadaActionTypes = {
	SET_ALL_SWITCHES: 'SET_ALL_SWITCHES_ScadaActionTypes',
	SET_ACTIVE_CONFIG: 'SET_ACTIVE_CONFIG_ScadaActionTypes',
	GENERATE_TRUE: 'GENERATE_TRUE_ScadaActionTypes',
	GENERATE_FALSE: 'GENERATE_FALSE_ScadaActionTypes',
}

export const scadaReducer = (state = initialState, action) => {
	switch (action.type) {
		case ScadaActionTypes.SET_ALL_SWITCHES:
			return {...state, allSwitches: {...action.payload}}
		case ScadaActionTypes.SET_ACTIVE_CONFIG:
			const configKeys = Object.keys(state.configs)
			const isValidKey = configKeys.find(item => parseInt(item) === action.payload)
			if (isValidKey) {
				return {...state, activeConfig: state.configs[action.payload]}
			} else {
				return {...state, activeConfig: null}
			}
		case ScadaActionTypes.GENERATE_TRUE:
			return {...state, isGenerated: true}
		case ScadaActionTypes.GENERATE_FALSE:
			return {...state, isGenerated: false}
		default:
			return state
	}
}
