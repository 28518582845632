const initialState = {
	stateList: {},
	alarmStateList: {},
	errorList: {},
}

export const ProtectionModeActionTypes = {
	SET_ACTIVE_STATE: 'SET_ACTIVE_STATE_ProtectionModeActionTypes',
	SET_INACTIVE_STATE: 'SET_INACTIVE_STATE_ProtectionModeActionTypes',
	SET_ACTIVE_ALARM_STATE: 'SET_ACTIVE_ALARM_STATE_ProtectionModeActionTypes',
	SET_INACTIVE_ALARM_STATE: 'SET_INACTIVE_ALARM_STATE_ProtectionModeActionTypes',
	SET_LOADING_ERROR: 'SET_LOADING_ERROR_ProtectionModeActionTypes',
}

export const protectionModeReducer = (state = initialState, action) => {
	switch (action.type) {
		case ProtectionModeActionTypes.SET_ACTIVE_STATE:
			return {...state, stateList: {...state.stateList, [action.payload]: true}}
		case ProtectionModeActionTypes.SET_INACTIVE_STATE:
			return {...state, stateList: {...state.stateList, [action.payload]: false}}
		case ProtectionModeActionTypes.SET_ACTIVE_ALARM_STATE:
			return {...state, alarmStateList: {...state.alarmStateList, [action.payload]: true}}
		case ProtectionModeActionTypes.SET_INACTIVE_ALARM_STATE:
			return {...state, alarmStateList: {...state.alarmStateList, [action.payload]: false}}
		case ProtectionModeActionTypes.SET_LOADING_ERROR:
			return {...state, errorList: {...state.errorList, [action.payload.tpsId]: action.payload.value}}
		default:
			return state
	}
}
