import EditIcon from '@mui/icons-material/Edit'
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import Button from '@mui/material/Button';


const editButton = (<Button className='edit-btn bg-primary'><EditIcon /></Button>)
const activeUserButton = (<Button className='access-btn bg-success'><AccountCircleOutlinedIcon /></Button>)
const inactiveUserButton = (<Button className='access-btn bg-danger'><NoAccountsOutlinedIcon /></Button>)

export const configureUserData = (data, roles) => {
	return data.map(item => {
		return {
			...item,
			roleTitle: roles[item.role] ?? item.role,
			status: parseInt(item.status),
			statusIcon: item.status ? activeUserButton : inactiveUserButton,
			action: editButton,
		}
	})
}
