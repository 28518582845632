import React, {useEffect, useState} from 'react';
import classes from './AccountManagementPage.module.css';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useModal} from '../../hooks/useModal';
import {useHttp} from '../../hooks/useHttp';
import UserTable from '../../components/tables/users-tables/UsersTable';
import {useFunctionInterval} from '../../hooks/useFunctionInterval';
import {configureUserState} from '../../utils/configureUserState';
import {configureUserData} from '../../utils/configureUserData';
import {useManageUsers} from "../../hooks/useManageUsers";
import {useCreateUser} from "../../hooks/useCreateUser";


function AccountManagementPage() {
	const {isAdmin} = useSelector(store => store.auth)
	const navigate = useNavigate()
	const modal = useModal()
	const {get} = useHttp()
	const [rows, setRows] = useState([])
	const [roles, setRoles] = useState({
		admin: 'Адміністратор',
		operator: 'Оператор',
		observer: 'Спостерігач'
	})
	const {setRoleOptions, setExistingTps, createUserHandler} = useManageUsers(modal, rows, getAllUsers)
	const {newRows} = useCreateUser(rows, createUserHandler)

	const checkUsersStatus = async () => {
		const {response} = await get('/api/user/all-users-state')
		setRows(prev => configureUserState(prev, response))
	}

	async function getRoles() {
		return new Promise(async resolve => {
			const {response} = await get('/api/user/roles')
			const newRoles = roles
			const roleOptions = response.reduce((acc, item) => {
				if (!newRoles.hasOwnProperty(item)) {
					newRoles[item] = item
				}
				acc.push({value: item, title: newRoles[item]})
				return acc
			}, [])
			await setRoleOptions(roleOptions)
			await setRoles(newRoles)
			resolve()
		})
	}

	async function getAllUsers() {
		const {response: tpsList} = await get('/api/tps/list/sort-by-id')
		setExistingTps(tpsList)
		const {response: allUsers} = await get('/api/user/all-users')
		const configureAllUsers = configureUserData(allUsers, roles)
		setRows(configureAllUsers)
		await checkUsersStatus()
	}

	useFunctionInterval(checkUsersStatus, 2000)

	useEffect(() => {
		getRoles()
			.then(getAllUsers)
		// eslint-disable-next-line
	}, [])

	if (!isAdmin) return navigate('/')

	return (
		<div className={classes.container}>
			<UserTable rows={newRows} />
			{modal.window}
		</div>
	)
}

export default AccountManagementPage;
