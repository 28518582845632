import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import {red} from '@mui/material/colors';
import {IconButton, Typography} from '@mui/material';


function Modal({size, show, close, title, content, confirm, deny, oneButton, isRedTitle}) {
	const titleStyle = {color: 'white', fontSize: '1.5rem'}
	if (isRedTitle) {
		titleStyle.backgroundColor = red[400]
	} else {
		titleStyle.backgroundColor = '#2196F3'
	}

	function confirmHandle() {
		confirm()
		close()
	}

	function denyHandle() {
		deny()
		close()
	}

	return (
		<div>
			<Dialog
				open={show}
				onClose={close}
				fullWidth={true}
				maxWidth={size}
				aria-labelledby="alert-dialog-title"
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={titleStyle}
				>
					{title}
					<IconButton
						aria-label="close"
						onClick={close}
						sx={{position: 'absolute', right: 8, top: 8, color: 'white'}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				<DialogContent dividers>
					{
						typeof content === 'string'
							? (<Typography variant={"h6"} sx={{color: 'black'}}>
									{content}
								</Typography>)
							: content
					}

				</DialogContent>

				<DialogActions>
					{
						!oneButton &&
						<Button
							onClick={denyHandle}
							className='btn-danger'
						>
							Відміна
						</Button>
					}
					<Button
						onClick={confirmHandle}
						className={oneButton ? 'btn-primary' : 'btn-success'}
					>
						{oneButton || 'Підтвердити'}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default Modal;