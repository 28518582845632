import React, {useEffect, useState} from 'react';
import classes from './QrCode.module.css';
import {Typography} from "@mui/material";
import {useHttp} from "../../hooks/useHttp";
import Loader from "../UI/loader/Loader";


function CreateQrCode() {
	const {get} = useHttp()
	const [qrRequest, setQrRequest] = useState(false)
	const [imgSrc, setImgSrc] = useState('')

	async function createQrCode() {
		await setImgSrc('')
		await get('/api/user/two-factor/create')
			.then(res => setImgSrc(res.response))
			.then(() => setQrRequest(true))
	}

	useEffect(() => {
		createQrCode()
		// eslint-disable-next-line
	}, [])

	return (
		<div className={classes.container}>
			<Typography className={classes.title}>
				QR-код для підключення телефону:
			</Typography>
			<div className={classes.image}>
				{
					qrRequest
						? <img src={imgSrc} alt='qr' border={0}/>
						: <Loader />
				}
			</div>
			<Typography className={classes.title2}>
				Відскануйте QR-код у додатку Google Authenticator та натисніть "Підтвердити"
			</Typography>
		</div>
	)
}

export default CreateQrCode;
