import React from 'react';
import {Tooltip} from "@mui/material";
import classes from './TpsIcon.module.css';


function TpsIcon({title = '', isActive, onIcon, offIcon, onClick = () => {}, dataCy = 'ib-icon'}) {
	return (
		<Tooltip title={title} arrow>
			<div
				className={classes.container}
				style={onClick ? {cursor: 'pointer'} : {}}
				data-cy={dataCy}
			>
				{isActive ? onIcon : offIcon}
				<div className={classes['on-click']} onClick={onClick}/>
			</div>
		</Tooltip>
	)
}

export default TpsIcon;
