import {DuplexSwitch} from "../DuplexSwitch/DuplexSwitch";


export function OneWayDuplexSwitch(instance) {
	instance = DuplexSwitch(instance)
	instance.update = update.bind(instance)
	return instance
}

function update() {
	if (this.state === 1) {
		// ON

		if (this.active) {
			this.active = false
			// BUTTON

			if (this.head.state === 1 && this.tail.state === 1) {
				// ON HEAD -><- TAIL

				this.tail.on()
			}
			else if (this.head.state === 1 && this.tail.state === 0) {
				// ON HEAD -> TAIL

				this.direction = true
				this.tail.on()
			}
		} else {
			// WITHOUT BUTTON

			if (this.head.state === 1 && this.tail.state === 0) {
				// ON HEAD -> TAIL

				this.direction = true
				this.tail.on()
			}
			else if (this.head.state === 0 && (this.tail.state === 1 && this.lastTailState === 0)) {
				// ON HEAD <- TAIL

				this.direction = false
				// this.head.on()
			}
			else if ((this.head.state === 0 && this.lastHeadState === 1) && this.tail.state === 1) {
				// OFF HEAD -> TAIL

				if (this.direction) {
					this.direction = null
					this.tail.off()
				}
			}
			else if (this.head.state === 1 && (this.tail.state === 0 && this.lastTailState === 1)) {
				// OFF HEAD <- TAIL

				if (this.direction) {
					this.direction = null
					this.tail.on()
				}
				else if (this.direction === false) {
					this.direction = null
					this.head.off()
				}
				else {
					this.direction = true
					this.tail.on()
				}
			}
		}
		this.updateDepend('on')
	} else if (this.state === 0 || this.state === 2) {
		// OFF or REPAIR

		this.updateDepend('off')
		if (this.active) {
			this.active = false
			// BUTTON

			if (this.head.state === 1 && this.tail.state === 1) {
				if (this.direction) {
					// OFF HEAD <- TAIL

					this.direction = null
					this.tail.off()
				}
				else if (this.direction === false) {
					// OFF HEAD -> TAIL

					this.direction = null
					this.head.off()
				}
			}
		}
	}
	this.active = false
	this.lastHeadState = this.head.state
	this.lastTailState = this.tail.state
}
