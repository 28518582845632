import {useCallback, useEffect, useState} from 'react';
import {storageKey} from '../config/default';


export const useAuth = () => {
	const [ready, setReady] = useState(false)
	const [userData, setUserData] = useState(null)
	const [isAdmin, setIsAdmin] = useState(null)

	function setUser(userData) {
		setUserData(userData)
		setIsAdmin(userData.role === 'admin')
	}

	const login = useCallback((userData) => {
		setUser(userData)
		sessionStorage.removeItem(storageKey)
		sessionStorage.setItem(storageKey, JSON.stringify(userData))
	}, [])

	const logout = useCallback((error = false) => {
		setUserData(null)
		setIsAdmin(null)
		sessionStorage.removeItem(storageKey)
		if (typeof error === 'string') {
			window.location.replace(`/error/${error}`)
		} else {
			window.location.replace('/')
		}
	}, [])

	useEffect(() => {
		let userData = sessionStorage.getItem(storageKey)
		if (userData) {
			userData = JSON.parse(userData)
			if (userData?.name && userData?.token) {
				setUser(userData)
			}
		}
		setReady(true)
	}, [login])

	return {login, logout, userData, ready, isAdmin}
}
