import {useRef} from "react";
import {useHttp} from "./useHttp";
import {isObject} from "../utils/utils";
import {useActions} from "./useActions";
import _ from 'lodash';


export const useAlarm = () => {
	const {setTpsAlarms, clearTpsAlarms} = useActions()
	const lastAlarms = useRef({})
	const {get} = useHttp()

	async function getAlarmInfo() {
		const {response: alarmInfo, error} = await get(`/api/tps/alarm/info`)
        if (error || !isObject(alarmInfo)) return

		if (!_.isEqual(alarmInfo, lastAlarms.current)) {
			lastAlarms.current = alarmInfo
			const alarmObject = {...alarmInfo}
			for (const [key, value] of Object.entries(alarmObject)) {
				alarmObject[key] = value.reduce((acc, item) => {
					acc[item] = true
					return acc
				}, {})
			}
			clearTpsAlarms('object')
			clearTpsAlarms('array')
			setTpsAlarms('object', alarmObject)
			setTpsAlarms('array', alarmInfo)
		}
	}

	return {getAlarmInfo}
}
