import {configGenerator, SwitchType, SwitchAddType, LineType} from "../../components/scada-elements/configGenerator";


const TPS_ID = 5

const other = [
	{length: 0}, // alwaysOn
	{left: 12, top: 68, imageLine: {type: LineType.TireLine, height: 35, width: 1503},
		points: [88, 349, 612, 871, 1131, 1394]
	}, // SH1_6KV
	{left: 787, top: 1649, imageLine: {type: LineType.TireLine, height: 35, width: 1952},
		points: [48, 309, 571, 896, 1221, 1549, 1875]
	}, // WORK_600V
	{left: 695, top: 1551, imageLine: {type: LineType.TireLine, height: 35, width: 2044},
		points: [29, 848, 1174, 1500, 1830]
	}, // BACKUP_600V
	{left: 2043, top: 911, imageLine: {type: LineType.TireLine, height: 35, width: 328}, points: [154]}, // SHSN_220V
	{left: 12, top: 1583, imageLine: {type: LineType.TireLine, height: 36, width: 470},
		points: [88, 219, 349], isBlue: true
	}, // -600V
]

const lines1 = [
	{length: 49, container: {id: 'branch1', order: 3}},
	{imageLine: {type: LineType.TripleLine}, container: {id: 'branch1', order: 4}},
	{length: 88, container: {id: 'branch1', order: 1}}, // splice
]

const lines2 = [
	{length: 57, container: {id: 'branch2.1', order: 7},
		addLines: [
			{imageLine: {type: LineType.MVLine, height: 100, width: 165}, container: {id: 'branch2.1', order: 8}},
			{length: 56, container: {id: 'branch2.4', order: 1}, position: 'h'}, // right
		]
	},
	{length: 186, container: {id: 'branch2.1', order: 5}},
	{length: 57, container: {id: 'branch2.1', order: 3},
		addLines: [
			{length: 56, container: {id: 'branch2.2', order: 1}, position: 'h'}, // right
		]
	},
	{length: 23, container: {id: 'branch2.2', order: 3}, position: 'h',
		addLines: [
			{length: 25, container: {id: 'branch2.3', order: 1}},
			{imageLine: {type: LineType.TripleLine}, container: {id: 'branch2.3', order: 2}},
		]
	}, // right
	{length: 23, container: {id: 'branch2.4', order: 3}, position: 'h',
		addLines: [
			{length: 25, container: {id: 'branch2.5', order: 1}},
			{imageLine: {type: LineType.TripleLine}, container: {id: 'branch2.5', order: 2}},
		]
	}, // right
	{length: 88, container: {id: 'branch2.1', order: 1}}, // splice
]

const lines3 = [
	{length: 141, container: {id: 'branch3.1', order: 3},
		addLines: [
			{imageLine: {type: LineType.TLine, height: 232, width: 132}, container: {id: 'branch3.1', order: 4}},
			{length: 56, container: {id: 'branch3.2', order: 1}, position: 'h'}, // right
		]
	},
	{length: 23, container: {id: 'branch3.2', order: 3}, position: 'h',
		addLines: [
			{length: 25, container: {id: 'branch3.3', order: 1}},
			{imageLine: {type: LineType.TripleLine}, container: {id: 'branch3.3', order: 2}},
		]
	}, // right
	{left: 0, top: 0, length: 0},
	{length: 88, container: {id: 'branch3.1', order: 1}}, // splice
]

const lines4 = [
	{length: 57, container: {id: 'branch4.1', order: 3},
		addLines: [
			{length: 56, container: {id: 'branch4.4', order: 1}, position: 'h'}, // right
		]
	},
	{length: 185, container: {id: 'branch4.1', order: 5}},
	{length: 315, container: {id: 'branch4.1', order: 7},
		addLines: [
			{imageLine: {type: LineType.TLine, height: 230, width: 133}, container: {id: 'branch4.1', order: 8}},
			{length: 30, container: {id: 'branch4.1', order: 9}},
			{imageLine: {type: LineType.DoubleAGRLine}, container: {id: 'branch4.1', order: 10}},
			{length: 164, container: {id: 'branch4.1', order: 11}},
			{left: 891, top: 1474, length: 80, position: 'h'},
			{left: 969, top: 1474, length: 549},
			{left: 969, top: 2021, length: 135, position: 'h'},
			{length: 145, container: {id: 'branch4.2', order: 5}},
			{length: 76, container: {id: 'branch4.3', order: 3}, position: 'h', isBlue: true},
			{length: 56, container: {id: 'branch4.6', order: 1}, position: 'h'}, // right
		]
	},
	{length: 40, container: {id: 'branch4.2', order: 3}},
	{length: 23, container: {id: 'branch4.4', order: 3}, position: 'h',
		addLines: [
			{length: 25, container: {id: 'branch4.5', order: 1}},
			{imageLine: {type: LineType.TripleLine}, container: {id: 'branch4.5', order: 2}},
		]
	}, // right
	{length: 23, container: {id: 'branch4.6', order: 3}, position: 'h',
		addLines: [
			{length: 25, container: {id: 'branch4.7', order: 1}},
			{imageLine: {type: LineType.TripleLine}, container: {id: 'branch4.7', order: 2}},
		]
	}, // right
	{length: 342, container: {id: 'branch4.3', order: 1}, position: 'h', isBlue: true,
		addLines: [
			{left: 371, top: 1075, length: 511, isBlue: true},
		]
	}, // splice
	{length: 55, container: {id: 'branch4.2', order: 1}}, // splice
	{length: 88, container: {id: 'branch4.1', order: 1}}, // splice
]

const lines5 = [
	{length: 139, container: {id: 'branch5.1', order: 3},
		addLines: [
			{imageLine: {type: LineType.TLine, height: 230, width: 133}, container: {id: 'branch5.1', order: 4}},
			{length: 173, container: {id: 'branch5.1', order: 5}},
			{left: 1154, top: 774, length: 578, position: 'h'},
			{left: 1730, top: 774, length: 642},
			{left: 1730, top: 1413, length: 380, position: 'h'},
			{length: 124, container: {id: 'branch5.2', order: 7}},
			{length: 56, container: {id: 'branch5.3', order: 1}, position: 'h'}, // right
		]
	},
	{length: 39, container: {id: 'branch5.2', order: 5}},
	{length: 50, container: {id: 'branch5.2', order: 3}},
	{length: 23, container: {id: 'branch5.3', order: 3}, position: 'h',
		addLines: [
			{length: 25, container: {id: 'branch5.4', order: 1}},
			{imageLine: {type: LineType.TripleLine}, container: {id: 'branch5.4', order: 2}},
		]
	}, // right
	{length: 50, container: {id: 'branch5.2', order: 1},
		addLines: [{left: 2108, top: 1009, length: 105, position: 'h'}]
	}, // splice
	{length: 88, container: {id: 'branch5.1', order: 1}}, // splice
]

const lines6 = [
	{length: 57, container: {id: 'branch6.1', order: 3},
		addLines: [
			{length: 56, container: {id: 'branch6.4', order:1}, position: 'h'}, // right
		]
	},
	{length: 186, container: {id: 'branch6.1', order: 5}},
	{length: 57, container: {id: 'branch6.1', order: 7},
		addLines: [
			{imageLine: {type: LineType.MVLine, height: 100, width: 393}, container: {id: 'branch6.1', order: 8}},
			{left: 1938, top: 596, length: 242, position: 'h'},
			{left: 2177, top: 166, length: 433},
			{left: 2177, top: 166, length: 282, position: 'h'},
			{length: 25, container: {id: 'branch6.2', order: 1}},
		]
	},
	{length: 133, container: {id: 'branch6.2', order: 3},
		addLines: [
			{imageLine: {type: LineType.TLine, height: 230, width: 133}, container: {id: 'branch6.2', order: 4}},
			{length: 817, container: {id: 'branch6.2', order: 5}},
			{left: 2304, top: 1413, length: 157, position: 'h'},
			{length: 124, container: {id: 'branch6.3', order: 7}},
		]
	},
	{length: 39, container: {id: 'branch6.3', order: 5}},
	{length: 50, container: {id: 'branch6.3', order: 3}},
	{length: 23, container: {id: 'branch6.4', order: 3}, position: 'h',
		addLines: [
			{length: 25, container: {id: 'branch6.5', order: 1}},
			{imageLine: {type: LineType.TripleLine}, container: {id: 'branch6.5', order: 2}},
		]
	}, // right
	{length: 50, container: {id: 'branch6.3', order: 1},
		addLines: [{left: 2208, top: 1009, length: 96, position: 'h'}]
	}, // splice
	{length: 88, container: {id: 'branch6.1', order: 1}}, // splice
]

const lines9 = [
	{length: 57, container: {id: 'branch9.1', order: 3},
		addLines: [
			{length: 56, container: {id: 'branch9.5', order:1}, position: 'h'}, // right
		]
	},
	{length: 186, container: {id: 'branch9.1', order: 5}},
	{length: 238, container: {id: 'branch9.1', order: 7},
		addLines: [
			{left: 1502, top: 808, length: 1220, position: 'h'},
			{length: 78, container: {id: 'branch9.2', order: 1}},
			{imageLine: {type: LineType.TLine, height: 230, width: 133}, container: {id: 'branch9.2', order: 2}},
			{length: 30, container: {id: 'branch9.2', order: 3}},
			{imageLine: {type: LineType.DoubleAGRLine, height: 165, width: 165}, container: {id: 'branch9.2', order: 4}},
			{length: 164, container: {id: 'branch9.2', order: 5}},
			{left: 1230, top: 1474, length: 275, position: 'h'},
			{left: 1230, top: 1474, length: 549},
			{left: 1230, top: 2023, length: 141, position: 'h'},
			{length: 145, container: {id: 'branch9.3', order: 5}},
			{length: 78, container: {id: 'branch9.4', order: 3}, position: 'h', isBlue: true},
			{length: 56, container: {id: 'branch9.7', order:1}, position: 'h'}, // right
		]
	},
	{length: 40, container: {id: 'branch9.3', order: 3}},
	{length: 23, container: {id: 'branch9.5', order: 3}, position: 'h',
		addLines: [
			{length: 25, container: {id: 'branch9.6', order: 1}},
			{imageLine: {type: LineType.TripleLine}, container: {id: 'branch9.6', order: 2}},
		]
	}, // right
	{length: 23, container: {id: 'branch9.7', order: 3}, position: 'h',
		addLines: [
			{length: 25, container: {id: 'branch9.8', order: 1}},
			{imageLine: {type: LineType.TripleLine}, container: {id: 'branch9.8', order: 2}},
		]
	}, // right
	{length: 59, container: {id: 'branch9.4', order: 1}, position: 'h', isBlue: true,
		addLines: [
			{left: 1264, top: 847, length: 230, isBlue: true},
			{left: 240, top: 847, length: 1025, position: 'h', isBlue: true},
			{left: 240, top: 847, length: 737, isBlue: true},
		]
	}, // splice
	{length: 55, container: {id: 'branch9.3', order: 1}}, // splice
	{length: 88, container: {id: 'branch9.1', order: 1}}, // splice
]

const lines51x = [
	{length: 88, container: {id: 'branch51x.1', order: 1}, isBlue: true}, // splice
	{length: 88, container: {id: 'branch51x.2', order: 1}, isBlue: true}, // splice
	{length: 88, container: {id: 'branch51x.3', order: 1}, isBlue: true}, // splice
	{length: 59, container: {id: 'branch51x.1', order: 3}, isBlue: true},
	{length: 59, container: {id: 'branch51x.2', order: 3}, isBlue: true},
	{length: 59, container: {id: 'branch51x.3', order: 3}, isBlue: true},
]

const linesReserveAV = [
	{length: 50, container: {id: 'reserveAV.1', order: 3}},
	{length: 136, container: {id: 'reserveAV.1', order: 5},
		addLines: [
			{left: 734, top: 2021, length: 113, position: 'h'},
			{length: 58, container: {id: 'reserveAV.2', order: 3}},
		]
	},
	{length: 336, container: {id: 'reserveAV.2', order: 1}}, // splice
	{length: 55, container: {id: 'reserveAV.1', order: 1}}, // splice
]

const linesReserve = [
	{length: 50, container: {id: 'reserve.1', order: 3}},
	{length: 42, container: {id: 'reserve.1', order: 5}},
	// {length: 65, container: {id: 'reserve', order: 3}} // future,
	{left: 0, top: 0, length: 0},
	{length: 336, container: {id: 'reserve.2', order: 1}}, // splice
	{length: 55, container: {id: 'reserve.1', order: 1}}, // splice
]

const lines511 = [
	{length: 50, container: {id: 'branch511.1', order: 3}},
	{length: 42, container: {id: 'branch511.1', order: 5}},
	{length: 65, container: {id: 'branch511', order: 3}},
	{length: 336, container: {id: 'branch511.2', order: 1}}, // splice
	{length: 55, container: {id: 'branch511.1', order: 1}}, // splice
]

const lines512 = [
	{length: 50, container: {id: 'branch512.1', order: 3}},
	{length: 42, container: {id: 'branch512.1', order: 5}},
	{length: 65, container: {id: 'branch512', order: 3}},
	{length: 336, container: {id: 'branch512.2', order: 1}}, // splice
	{length: 55, container: {id: 'branch512.1', order: 1}}, // splice
]

const lines513 = [
	{length: 50, container: {id: 'branch513.1', order: 3}},
	{length: 42, container: {id: 'branch513.1', order: 5}},
	{length: 65, container: {id: 'branch513', order: 3}},
	{length: 336, container: {id: 'branch513.2', order: 1}}, // splice
	{length: 55, container: {id: 'branch513.1', order: 1}}, // splice
]

other[1].addLines = [
	lines1.splice(-1, 1)[0],
	lines2.splice(-1, 1)[0],
	lines3.splice(-1, 1)[0],
	lines4.splice(-1, 1)[0],
	lines5.splice(-1, 1)[0],
	{left: 1415, top: 103, length: 221},
	{left: 1415, top: 322, length: 265, position: 'h'},
	{left: 1676, top: 103, length: 221},
	{left: 1578, top: 68, imageLine: {type: LineType.TireLine, height: 35, width: 1274},
		points: [88, 349, 612, 871, 1131]
	}, // SH2_6KV
	lines6.splice(-1, 1)[0],
	lines9.splice(-1, 1)[0],
]
other[2].addLines = [
	lines4.splice(-1, 1)[0],
	lines9.splice(-1, 1)[0],
	linesReserveAV.splice(-1, 1)[0],
	linesReserve.splice(-1, 1)[0],
	lines511.splice(-1, 1)[0],
	lines512.splice(-1, 1)[0],
	lines513.splice(-1, 1)[0],
]
other[3].addLines = [
	linesReserveAV.splice(-1, 1)[0],
	linesReserve.splice(-1, 1)[0],
	lines511.splice(-1, 1)[0],
	lines512.splice(-1, 1)[0],
	lines513.splice(-1, 1)[0],
]
other[4].addLines = [
	{left: 2206, top: 946, length: 65},
	lines5.splice(-1, 1)[0],
	lines6.splice(-1, 1)[0],
]
other[5].addLines = [
	lines4.splice(-1, 1)[0],
	lines9.splice(-1, 1)[0],
	...lines51x.splice(0, 3),
]

const branch1 = [
	{type: SwitchType.HRSwitch, id: 'zn1', name: 'ЗН1', head: {group: other, number: 2},
		tail: {group: lines1, number: 1}, container: {id: 'branch1', order: 2}, isOnlySchemas: true,
	},
]

const branch2 = [
	{type: SwitchType.MVSwitch, id: 'lr2', name: 'ЛР2', head: {group: other, number: 1},
		tail: {group: lines2, number: 2}, container: {id: 'branch2.1', order: 6}, isOnlySchemas: true,
		depLine: {group: lines2, number: 1}
	},
	{type: SwitchType.MVSwitch, id: 'mv2', name: 'МВ2', head: {group: lines2, number: 2},
		tail: {group: lines2, number: 3}, container: {id: 'branch2.1', order: 4}
	},
	{type: SwitchType.HRSwitch, id: 'shr2', name: 'ШР2', head: {group: lines2, number: 3},
		tail: {group: other, number: 2}, container: {id: 'branch2.1', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.GroupSwitch, group: 'SH1_6KV'
	},
	// right
	{type: SwitchType.HRSwitch, id: 'znshr2', name: 'ЗНШР2', head: {group: lines2, number: 3}, position: 'h',
		tail: {group: lines2, number: 4}, container: {id: 'branch2.2', order: 2}, isOnlySchemas: true,
	},
	{type: SwitchType.HRSwitch, id: 'znlr2', name: 'ЗНЛР2', head: {group: lines2, number: 1}, position: 'h',
		tail: {group: lines2, number: 5}, container: {id: 'branch2.4', order: 2}, isOnlySchemas: true,
	},
	//
]

const branch3 = [
	{type: SwitchType.HRSwitch, id: 'shr3-3', name: 'ШР3', head: {group: other, number: 2},
		tail: {group: lines3, number: 3}, container: {id: 'branch3.1', order: 2}, isOnlySchemas: true,
		depLine: {group: lines3, number: 1}
	},
	// right
	{type: SwitchType.HRSwitch, id: 'znshr3', name: 'ЗНШР3', head: {group: lines3, number: 1}, position: 'h',
		tail: {group: lines3, number: 2}, container: {id: 'branch3.2', order: 2}, isOnlySchemas: true,
	},
	//
]

const branch4 = [
	// right
	{type: SwitchType.HRSwitch, id: 'znshr4', name: 'ЗНШР4', head: {group: lines4, number: 1}, position: 'h',
		tail: {group: lines4, number: 5}, container: {id: 'branch4.4', order: 2}, isOnlySchemas: true,
	},
	{type: SwitchType.HRSwitch, id: 'znlr4', name: 'ЗНЛР4', head: {group: lines4, number: 3}, position: 'h',
		tail: {group: lines4, number: 6}, container: {id: 'branch4.6', order: 2}, isOnlySchemas: true,
	},
	//
	{type: SwitchType.HRSwitch, id: 'shr4', name: 'ШР4', head: {group: other, number: 2},
		tail: {group: lines4, number: 1}, container: {id: 'branch4.1', order: 2}, isOnlySchemas: true,
	},
	{type: SwitchType.MVSwitch, id: 'mv4', name: 'МВ4', head: {group: lines4, number: 1},
		tail: {group: lines4, number: 2}, container: {id: 'branch4.1', order: 4}
	},
	{type: SwitchType.HRSwitch, id: 'lr4', name: 'ЛР4', head: {group: lines4, number: 2},
		tail: {group: lines4, number: 3}, container: {id: 'branch4.1', order: 6}, isOnlySchemas: true,
	},
	{type: SwitchType.HRSwitch, id: 'rvk1', name: 'РВК1', head: {group: other, number: 1}, position: 'h',
		tail: {group: other, number: 6}, container: {id: 'branch4.3', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.GroupSwitch, group: '-600V', isBlue: true
	},
	{type: SwitchType.KASwitch, id: 'ka1', name: 'KA №1', head: {group: lines4, number: 3},
		tail: {group: lines4, number: 4}, container: {id: 'branch4.2', order: 4}, isNotInteractive: true,
	},
	{type: SwitchType.HRSwitch, id: 'shr2-600', name: 'ШР2', logName: 'ШР2 (600В)', head: {group: lines4, number: 4},
		tail: {group: other, number: 3}, container: {id: 'branch4.2', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.GroupSwitch, group: 'WORK_600V'
	},
]

const branch5 = [
	// right
	{type: SwitchType.HRSwitch, id: 'znshr5', name: 'ЗНШР5', head: {group: lines5, number: 1}, position: 'h',
		tail: {group: lines5, number: 4}, container: {id: 'branch5.3', order: 2}, isOnlySchemas: true,
	},
	//
	{type: SwitchType.HRSwitch, id: 'shr5', name: 'ШР5', head: {group: other, number: 2},
		tail: {group: lines5, number: 1}, container: {id: 'branch5.1', order: 2}, isOnlySchemas: true
	},
	{type: SwitchType.KASwitch, id: 'av1', name: 'АВ1', head: {group: lines5, number: 1},
		tail: {group: lines5, number: 2}, container: {id: 'branch5.2', order: 6}, isOnlySchemas: true
	},
	{type: SwitchType.HRSwitch, id: 'r1', name: 'Р1', head: {group: lines5, number: 2},
		tail: {group: lines5, number: 3}, container: {id: 'branch5.2', order: 4}, isOnlySchemas: true,
	},
	{type: SwitchType.HRSwitch, id: 'k1', name: 'К1', head: {group: lines5, number: 3},
		tail: {group: other, number: 5}, container: {id: 'branch5.2', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.AlarmDuplexSwitch
	},
]

const branch6 = [
	{type: SwitchType.HRSwitch, id: 'shr6', name: 'ШР6', head: {group: lines6, number: 1},
		tail: {group: other, number: 2}, container: {id: 'branch6.1', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.GroupSwitch, group: 'SH1_6KV'
	},
	{type: SwitchType.MVSwitch, id: 'mv6', name: 'МВ6', head: {group: lines6, number: 2},
		tail: {group: lines6, number: 1}, container: {id: 'branch6.1', order: 4}
	},
	// right
	{type: SwitchType.HRSwitch, id: 'znshr6', name: 'ЗНШР6', head: {group: lines6, number: 1}, position: 'h',
		tail: {group: lines6, number: 7}, container: {id: 'branch6.4', order: 2}, isOnlySchemas: true,
	},
	//
	{type: SwitchType.HRSwitch, id: 'lr6', name: 'ЛР6', head: {group: other, number: 1},
		tail: {group: lines6, number: 2}, container: {id: 'branch6.1', order: 6}, isOnlySchemas: true,
		depLine: {group: lines6, number: 3}
	},
	{type: SwitchType.HRSwitch, id: 'shr8', name: 'ШР8', head: {group: lines6, number: 3},
		tail: {group: lines6, number: 4}, container: {id: 'branch6.2', order: 2}, isOnlySchemas: true
	},
	{type: SwitchType.KASwitch, id: 'av2', name: 'АВ2', head: {group: lines6, number: 4},
		tail: {group: lines6, number: 5}, container: {id: 'branch6.3', order: 6}, isOnlySchemas: true
	},
	{type: SwitchType.HRSwitch, id: 'r2', name: 'Р2', head: {group: lines6, number: 5},
		tail: {group: lines6, number: 6}, container: {id: 'branch6.3', order: 4}, isOnlySchemas: true,
	},
	{type: SwitchType.HRSwitch, id: 'k2', name: 'К2', head: {group: lines6, number: 6},
		tail: {group: other, number: 5}, container: {id: 'branch6.3', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.AlarmDuplexSwitch
	},
]

const branch9 = [
	// right
	{type: SwitchType.HRSwitch, id: 'znshr9', name: 'ЗНШР9', head: {group: lines9, number: 1}, position: 'h',
		tail: {group: lines9, number: 5}, container: {id: 'branch9.5', order: 2}, isOnlySchemas: true,
	},
	{type: SwitchType.HRSwitch, id: 'znlr9', name: 'ЗНЛР9', head: {group: lines9, number: 3}, position: 'h',
		tail: {group: lines9, number: 6}, container: {id: 'branch9.7', order: 2}, isOnlySchemas: true,
	},
	//
	{type: SwitchType.HRSwitch, id: 'shr9', name: 'ШР9', head: {group: other, number: 2},
		tail: {group: lines9, number: 1}, container: {id: 'branch9.1', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.OneWayDuplexSwitch
	},
	{type: SwitchType.MVSwitch, id: 'mv9', name: 'МВ9', head: {group: lines9, number: 1},
		tail: {group: lines9, number: 2}, container: {id: 'branch9.1', order: 4},
		addType: SwitchAddType.OneWayDuplexSwitch
	},
	{type: SwitchType.HRSwitch, id: 'lr9', name: 'ЛР9', head: {group: lines9, number: 2},
		tail: {group: lines9, number: 3}, container: {id: 'branch9.1', order: 6}, isOnlySchemas: true,
		addType: SwitchAddType.OneWayDuplexSwitch
	},
	{type: SwitchType.HRSwitch, id: 'rvk2', name: 'РВК2', head: {group: other, number: 1}, position: 'h',
		tail: {group: other, number: 6}, container: {id: 'branch9.4', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.GroupSwitch, group: '-600V', isBlue: true
	},
	{type: SwitchType.KASwitch, id: 'ka2', name: 'KA №2', head: {group: lines9, number: 3},
		tail: {group: lines9, number: 4}, container: {id: 'branch9.3', order: 4}, isNotInteractive: true,
	},
	{type: SwitchType.HRSwitch, id: 'shr3-600', name: 'ШР3', logName: 'ШР3 (600В)', head: {group: lines9, number: 4},
		tail: {group: other, number: 3}, container: {id: 'branch9.3', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.GroupSwitch, group: 'WORK_600V'
	},
]

const branch51x = [
	{type: SwitchType.HRSwitch, id: 'r511', name: 'Р511', head: {group: other, number: 6},
		tail: {group: lines51x, number: 1}, container: {id: 'branch51x.1', order: 2}, isOnlySchemas: true, isBlue: true
	},
	{type: SwitchType.HRSwitch, id: 'r512', name: 'Р512', head: {group: other, number: 6},
		tail: {group: lines51x, number: 2}, container: {id: 'branch51x.2', order: 2}, isOnlySchemas: true, isBlue: true
	},
	{type: SwitchType.HRSwitch, id: 'r513', name: 'Р513', head: {group: other, number: 6},
		tail: {group: lines51x, number: 3}, container: {id: 'branch51x.3', order: 2}, isOnlySchemas: true, isBlue: true
	},
]

const branchReserveAV = [
	{type: SwitchType.HRSwitch, id: 'shr1', name: 'ШР1', head: {group: other, number: 3},
		tail: {group: linesReserveAV, number: 1}, container: {id: 'reserveAV.1', order: 2}, isOnlySchemas: true,
	},
	{type: SwitchType.KASwitch, id: 'za', name: 'ЗА', head: {group: linesReserveAV, number: 1},
		tail: {group: linesReserveAV, number: 2}, container: {id: 'reserveAV.1', order: 4}
	},
	{type: SwitchType.HRSwitch, id: 'rzsh', name: 'РЗШ', head: {group: linesReserveAV, number: 2},
		tail: {group: other, number: 4}, container: {id: 'reserveAV.2', order: 2},
		addType: SwitchAddType.GroupSwitch, group: 'BACKUP_600V'
	},
]

const branchReserve = [
	{type: SwitchType.HRSwitch, id: 'shr4-rev', name: 'ШР4', head: {group: other, number: 3},
		tail: {group: linesReserve, number: 1}, container: {id: 'reserve.1', order: 2}, isOnlySchemas: true,
		logName: 'ШР4 (Рев)'
	},
	{type: SwitchType.KASwitch, id: 'lv514', name: 'ЛВ514', head: {group: linesReserve, number: 1},
		tail: {group: linesReserve, number: 2}, container: {id: 'reserve.1', order: 4}, isOnlySchemas: true,
	},
	{type: SwitchType.PZHSwitch, id: 'pzsh4', name: 'ПЗШ4', head: {group: other, number: 4},
		tail: {group: linesReserve, number: 3}, container: {id: 'reserve', order: 2}, isOnlySchemas: true,
		addType: SwitchAddType.DuplexSideHeadSwitch, rightHead: {group: linesReserve, number: 2}
	},
]

const branch511 = [
	{type: SwitchType.HRSwitch, id: 'shr5-rev', name: 'ШР5', head: {group: other, number: 3},
		tail: {group: lines511, number: 1}, container: {id: 'branch511.1', order: 2}, isOnlySchemas: true,
		logName: 'ШР5 (Роб)'
	},
	{type: SwitchType.KASwitch, id: 'lv511', name: 'ЛВ511', head: {group: lines511, number: 1},
		tail: {group: lines511, number: 2}, container: {id: 'branch511.1', order: 4}
	},
	{type: SwitchType.PZHSwitch, id: 'pzsh5', name: 'ПЗШ5', head: {group: other, number: 4},
		tail: {group: lines511, number: 3}, container: {id: 'branch511', order: 2},
		addType: SwitchAddType.DuplexSideHeadSwitch, rightHead: {group: lines511, number: 2}
	},
]

const branch512 = [
	{type: SwitchType.HRSwitch, id: 'shr6-rev', name: 'ШР6', head: {group: other, number: 3},
		tail: {group: lines512, number: 1}, container: {id: 'branch512.1', order: 2}, isOnlySchemas: true,
		logName: 'ШР6 (Роб)'
	},
	{type: SwitchType.KASwitch, id: 'lv512', name: 'ЛВ512', head: {group: lines512, number: 1},
		tail: {group: lines512, number: 2}, container: {id: 'branch512.1', order: 4}
	},
	{type: SwitchType.PZHSwitch, id: 'pzsh6', name: 'ПЗШ6', head: {group: other, number: 4},
		tail: {group: lines512, number: 3}, container: {id: 'branch512', order: 2},
		addType: SwitchAddType.DuplexSideHeadSwitch, rightHead: {group: lines512, number: 2}
	},
]

const branch513 = [
	{type: SwitchType.HRSwitch, id: 'shr7-rev', name: 'ШР7', head: {group: other, number: 3},
		tail: {group: lines513, number: 1}, container: {id: 'branch513.1', order: 2}, isOnlySchemas: true,
		logName: 'ШР7 (Роб)'
	},
	{type: SwitchType.KASwitch, id: 'lv513', name: 'ЛВ513', head: {group: lines513, number: 1},
		tail: {group: lines513, number: 2}, container: {id: 'branch513.1', order: 4}
	},
	{type: SwitchType.PZHSwitch, id: 'pzsh7', name: 'ПЗШ7', head: {group: other, number: 4},
		tail: {group: lines513, number: 3}, container: {id: 'branch513', order: 2},
		addType: SwitchAddType.DuplexSideHeadSwitch, rightHead: {group: lines513, number: 2}
	},
]

const containers =  {
	'branch1': {left: 110, top: 103, height: 248, width: 1},

	'branch2.1': {left: 371, top: 103, height: 646, width: 1},
	'branch2.2': {left: 373, top: 257, height: 1, width: 144, position: 'h'},
	'branch2.3': {left: 495, top: 257, height: 82, width: 1},
	'branch2.4': {left: 373, top: 596, height: 1, width: 144, position: 'h'},
	'branch2.5': {left: 495, top: 596, height: 82, width: 1},

	'branch3.1': {left: 632, top: 103, height: 522, width: 1},
	'branch3.2': {left: 634, top: 257, height: 1, width: 144, position: 'h'},
	'branch3.3': {left: 756, top: 257, height: 82, width: 1},

	'branch4.1': {left: 893, top: 103, height: 1393, width: 1},
	'branch4.2': {left: 1106, top: 1685, height: 358, width: 1},
	'branch4.3': {left: 371, top: 1075, height: 1, width: 482, position: 'h'},
	'branch4.4': {left: 895, top: 257, height: 1, width: 144, position: 'h'},
	'branch4.5': {left: 1017, top: 257, height: 82, width: 1},
	'branch4.6': {left: 895, top: 596, height: 1, width: 144, position: 'h'},
	'branch4.7': {left: 1017, top: 596, height: 82, width: 1},

	'branch5.1': {left: 1154, top: 103, height: 691, width: 1},
	'branch5.2': {left: 2108, top: 1009, height: 426, width: 1},
	'branch5.3': {left: 1156, top: 257, height: 1, width: 144, position: 'h'},
	'branch5.4': {left: 1278, top: 257, height: 82, width: 1},

	'branch6.1': {left: 1937, top: 103, height: 645, width: 1},
	'branch6.2': {left: 2459, top: 166, height: 1269, width: 1},
	'branch6.3': {left: 2304, top: 1009, height: 426, width: 1},
	'branch6.4': {left: 1939, top: 257, height: 1, width: 144, position: 'h'},
	'branch6.5': {left: 2061, top: 257, height: 82, width: 1},

	'branch9.1': {left: 2720, top: 103, height: 727, width: 1},
	'branch9.2': {left: 1502, top: 808, height: 688, width: 1},
	'branch9.3': {left: 1369, top: 1685, height: 359, width: 1},
	'branch9.4': {left: 1264, top: 1075, height: 1, width: 178, position: 'h'},
	'branch9.5': {left: 2722, top: 257, height: 1, width: 144, position: 'h'},
	'branch9.6': {left: 2844, top: 257, height: 82, width: 1},
	'branch9.7': {left: 2722, top: 596, height: 1, width: 144, position: 'h'},
	'branch9.8': {left: 2844, top: 596, height: 82, width: 1},

	'branch51x.1': {left: 110, top: 1619, height: 208, width: 1},
	'branch51x.2': {left: 240, top: 1619, height: 208, width: 1},
	'branch51x.3': {left: 371, top: 1619, height: 208, width: 1},
	'branch51x.4': {left: 501, top: 1619, height: 208, width: 1},

	'reserveAV.1': {left: 845, top: 1684, height: 359, width: 1},
	'reserveAV.2': {left: 734, top: 1586, height: 457, width: 1},

	'reserve': {left: 1622, top: 1586, height: 480, width: 1},
	'doubleReserve': {height: 336, width: 140,
		double: {leftCont: 'reserve.2', rightCont: 'reserve.1'}, container: {id: 'reserve', order: 1}
	},
	'reserve.1': {height: 238, width: 1},
	'reserve.2': {height: 336, width: 1},

	'branch511': {left: 1948, top: 1586, height: 480, width: 1},
	'doubleBranch511': {height: 336, width: 140,
		double: {leftCont: 'branch511.2', rightCont: 'branch511.1'}, container: {id: 'branch511', order: 1}
	},
	'branch511.1': {height: 238, width: 1},
	'branch511.2': {height: 336, width: 1},

	'branch512': {left: 2274, top: 1586, height: 480, width: 1},
	'doubleBranch512': {height: 336, width: 140,
		double: {leftCont: 'branch512.2', rightCont: 'branch512.1'}, container: {id: 'branch512', order: 1}
	},
	'branch512.1': {height: 238, width: 1},
	'branch512.2': {height: 336, width: 1},

	'branch513': {left: 2606, top: 1586, height: 480, width: 1},
	'doubleBranch513': {height: 336, width: 140,
		double: {leftCont: 'branch513.2', rightCont: 'branch513.1'}, container: {id: 'branch513', order: 1}
	},
	'branch513.1': {height: 238, width: 1},
	'branch513.2': {height: 336, width: 1},
}

const text = [
	{text: '6кВ', left: 32, top: 24, fontSize: 1},
	{text: 'РУ-6кВ СШ №1', left: 528, top: 8, fontSize: 1.2},
	{text: 'РУ-6кВ СШ №2', left: 2187, top: 8, fontSize: 1.2},
	{text: '6кВ', left: 2776, top: 24, fontSize: 1},
	{text: 1, left: 82, top: 111},
	{text: 2, left: 341, top: 111},
	{text: 3, left: 603, top: 111},
	{text: 3, left: 603, top: 111},
	{text: 4, left: 863, top: 111},
	{text: 5, left: 1125, top: 111},
	{text: 6, left: 1908, top: 111},
	{text: 7, left: 2169, top: 111},
	{text: 8, left: 2430, top: 111},
	{text: 9, left: 2691, top: 111},
	{text: '- 600В', left: 40, top: 1530, fontSize: 1.2},
	{text: 511, left: 56, top: 1628},
	{text: 512, left: 187, top: 1628},
	{text: 513, left: 317, top: 1628},
	{text: 1, left: 817, top: 1693},
	{text: 2, left: 1077, top: 1693},
	{text: 3, left: 1338, top: 1693},
	{text: 4, left: 1664, top: 1693},
	{text: 5, left: 1991, top: 1693},
	{text: 6, left: 2317, top: 1693},
	{text: 7, left: 2643, top: 1693},
	{text: 'Запасна', left: 2380, top: 1495, fontSize: 1.2},
	{text: 'шина +600В', left: 2545, top: 1495, fontSize: 1.2},
	{text: 'Робоча', left: 2393, top: 1594, fontSize: 1.2},
	{text: 'шина +600В', left: 2545, top: 1594, fontSize: 1.2},
	{text: 'Лінія 511', left: 49, top: 1832},
	{text: 'Лінія 512', left: 181, top: 1832},
	{text: 'Лінія 513', left: 311, top: 1832},
	{text: 'Запасний АВ', left: 722, top: 2086},
	{text: 'Агрегат №1', left: 978, top: 2086},
	{text: 'Агрегат №2', left: 1239, top: 2086},
	{text: 'Резерв', left: 1583, top: 2086},
	{text: 'Лінія що відходить', left: 1836, top: 2063},
	{text: '№ 511', left: 1912, top: 2096},
	{text: 'Лінія що відходить', left: 2154, top: 2063},
	{text: '№ 512', left: 2237, top: 2096},
	{text: 'Лінія що відходить', left: 2490, top: 2063},
	{text: '№ 513', left: 2563, top: 2096},
	{text: '~220В', left: 2043, top: 870, fontSize: 1},
	{text: 'ЩСН', left: 2154, top: 839, fontSize: 1.2},
	{text: 'Ввід №1', left: 309, top: 648, fontSize: 1},
	{text: 'с ТП-178', left: 306, top: 685, fontSize: 1},
	{text: 'Ввід №2', left: 1880, top: 640, fontSize: 1},
	{text: 'с ГРУ КрАЗ ком.№51', left: 1780, top: 680, fontSize: 1},
	{text: 'ТН-1', left: 604, top: 628, fontSize: 1},
	{text: 'ТCН1', left: 989, top: 386, fontSize: 1},
	{text: 'ТМ-25', left: 992, top: 428},
	{text: '25кВА', left: 994, top: 459},
	{text: 'ТCН2', left: 2290, top: 386, fontSize: 1},
	{text: 'ТМ-25', left: 2293, top: 428},
	{text: '25кВА', left: 2295, top: 459},
	{text: 'Т-1', left: 749, top: 899, fontSize: 1},
	{text: 'Т-2', left: 1358, top: 899, fontSize: 1},
	{text: 'Агр. №1', left: 688, top: 1191},
	{text: 'ВАКЛЕ 2000', left: 642, top: 1238},
	{text: 'Агр. №2', left: 1295, top: 1191},
	{text: 'ВАКЛЕ 2000', left: 1249, top: 1238},
]

let config = {
	switches: [
		branch1, branch2, branch3, branch4, branch5, branch6, branch9, branch51x, branchReserveAV, branchReserve,
		branch511, branch512, branch513
	],
	lines: {
		other, lines1, lines2, lines3, lines4, lines5, lines6, lines9, lines51x, linesReserveAV, linesReserve,
		lines511, lines512, lines513
	},
	containers,
	text
}

config = configGenerator(TPS_ID, config)
// console.log('config tps 5', config)

const TPS5 = {
	id: TPS_ID,
	size: {width: 2888, height: 2125, minHeight: 1000},
	// image: backgroundImage,
	isExist: true,
	lines: Object.values(config.lines),
	switches: [config.switches],
	containers: config.containers,
	activeLines: [other[0]],
	text: config.text
}

export default TPS5
