const switchGroups = {}

export function GroupSwitch(instance, group) {
	instance.generalState = generalState.bind(instance)
	instance.joinGroup = joinGroup.bind(instance)
	instance.update = update.bind(instance)
	instance.joinGroup(group)
	return instance
}

function joinGroup(group) {
	if (!switchGroups[group]) {
		switchGroups[group] = []
	}
	switchGroups[group].push(this)
	this.group = group
}

function generalState() {
	if (this.state === 1 && this.head.state === 1) {
		return 1
	}
	else return 0
}

function update() {
	const isActive = switchGroups[this.group].filter(item => item.generalState() === 1).length
	if (this.state === 1) {
		// ON

		if (this.head.state === 1) {
			this.updateDepend('on')
			this.tail.on()
		} else if (this.head.state === 0) {
			this.updateDepend('off')
			if (!isActive) {
				this.tail.off()
			}
		}
	} else if (this.state === 0) {
		// OFF

		if (this.head.state === 1 || this.head.state === 0) {
			this.updateDepend('off')
			if (!isActive) {
				this.tail.off()
			}
		}
	}
}
