import React, {useRef} from "react";
import classes from "../../pages/tps/TpsPage.module.css";
import Loader from "../../components/UI/loader/Loader";

export const useScadaLoading = (dataCy = 'loader') => {
	const loaderTimeout = useRef()
	const loading = useRef()
	const loader = useRef(
		<div ref={loading} className={classes.hide} data-cy={dataCy}>
			<div className={classes['loading-text']}/>
			<Loader color={'white'}/>
		</div>
	)

	function loadingHandler(text = false, disable = false) {
		if (disable) {
			loading.current?.classList.remove(classes.loading)
			return loading.current?.classList.add(classes.hide)
		}
		clearTimeout(loaderTimeout.current)
		loading.current.firstChild.innerText = text ?? ''
		loading.current?.classList.toggle(classes.loading)
		loading.current?.classList.toggle(classes.hide)
		if (loading.current?.classList[0] === classes.loading) {
			loaderTimeout.current = setTimeout(() => {
				loading.current?.classList?.remove(classes.loading)
				loading.current?.classList?.add(classes.hide)
			}, 5000)
		}
	}

	function startLoading() {
		if (loading.current?.classList[0] === classes.loading) {
			loadingHandler()
		}
	}

	return {loadingHandler, startLoading, scadaLoader: loader.current}
}
