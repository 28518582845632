import {useRef, useState} from 'react';
import Modal from '../components/UI/modal/Modal';

export const useModal = (size = 'sm', oneButton = false) => {
	const [showModal, setShowModal] = useState(false)
	const modal = useRef({
		title: '',
		content: '',
		confirm: () => {},
		deny: () => {},
		open: () => setShowModal(true),
		close: () => setShowModal(false),
		isRedTitle: false
	})
	modal.current.window =
		<Modal
			size={size}
			show={showModal}
			oneButton={oneButton}
			{...modal.current}
		/>
	return modal.current
}
