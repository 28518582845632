import {develop} from '../config/default'
import {GetMockData} from '../mock-data/get';
import {PostMockData} from '../mock-data/post';
import {getUserDataInHeadersForHttp} from '../utils/http';
import {useNavigate} from 'react-router-dom';
import {useAuth} from './useAuth';
import axios from 'axios';



export const useHttp = () => {
	const {logout} = useAuth()
	const navigate = useNavigate()

	const get = async (url, config = {}) => {
		const headers = getUserDataInHeadersForHttp()
		let response = null, error = null
		if (develop) {
			response = GetMockData[url]
		} else {
			config.headers = {...config.headers, ...headers}
			await axios.get(url, config)
				.then(res => response = res.data)
				.catch(err => {
					error = true
					switch (err.response?.status) {
						case 400:
							response = err.response?.data
							break;
						case 401:
							error = err.response?.data
							return logout(error)
						case 403:
							error = err.response?.data
							return navigate(`/error/${error}`)
						default:
							error = err.response?.data
					}
				})
		}
		return {response, error}
	}

	const post = async (url, body = {}, config = {}) => {
		const headers = getUserDataInHeadersForHttp()
		let response = null, error = null
		if (develop) {
			response = PostMockData[url](body)
		} else {
			config.headers = {...config.headers, ...headers}
			await axios.post(url, body, config)
				.then(res => response = res.data)
				.catch(err => {
					switch (err.response?.status) {
						case 400:
							response = err.response?.data
							error = true
							break;
						case 401:
							error = err.response?.data
							return logout(error)
						case 403:
							error = err.response?.data
							return navigate(`/error/${error}`)
						default:
							error = err.response?.data
					}
				})
		}
		return {response, error}
	}

	return {get, post}
}
