const iconStyle = {
	blue: {color: 'rgb(22, 139, 255)'},
	red: {color: 'rgb(255, 32, 32)'},
	green: {color: 'rgb(21, 235, 143)'},
	danger: {color: 'rgb(255, 58, 68)'},
}

const initialState = {
	list: [],
	alarmsArr: {},
	alarmsObj: {},
	isLoading: true,
	activeTps: null,
	loadingError: null,
	iconDataList: {},
	logDataList: {},
	controlIcons: {
		ty: {name: 'телекерування', title: 'Телекерування'},
		fire: {name: 'пожежа', title: 'Пожежа'},
		light: {name: 'світло', title: 'Світло'},
		heating: {name: 'опалення', title: 'Опалення'},
		person: {name: 'людина', title: 'Людина в підстанції'},
		error: {name: 'БСК несправність', title: 'БСК несправність'},
		lock: {name: 'двері шафи', title: 'Двері шафи'},
		wifi: {name: 'підстанція', title: "Зв'язок зі станцією"},
		temp: {name: 'температура', title: 'Температура'},
	},
	iconStyle,
	isRefreshState: false,
}

export const TpsActionTypes = {
	SET_TPS_LIST: 'SET_TPS_LIST_TpsActionTypes',
	SET_ACTIVE_TPS: 'SET_ACTIVE_TPS_TpsActionTypes',
	SET_LOADING_ERROR: 'SET_LOADING_ERROR_TpsActionTypes',
	SET_ICON_DATA: 'SET_ICON_DATA_TpsActionTypes',
	SET_LOG_DATA: 'SET_LOG_DATA_TpsActionTypes',
	SET_ALARMS: 'SET_ALARMS_TpsActionTypes',
	CLEAR_ALARMS: 'CLEAR_ALARMS_TpsActionTypes',
	SET_REFRESH_ICON: 'SET_REFRESH_ICON_TpsActionTypes',
}

export const tpsReducer = (state = initialState, action) => {
	switch (action.type) {
		case TpsActionTypes.SET_TPS_LIST:
			return {
				...state,
				list: [...action.payload.map(item => {
					return {...item, id: parseInt(item.id)}
				})],
				isLoading: false
			}
		case TpsActionTypes.SET_ACTIVE_TPS:
			return {...state, activeTps: action.payload}
		case TpsActionTypes.SET_LOADING_ERROR:
			return {...state, loadingError: action.payload}
		case TpsActionTypes.SET_ICON_DATA:
			return {...state, iconDataList: {...state.iconDataList, [action.payload.tpsId]: action.payload.data}}
		case TpsActionTypes.SET_LOG_DATA:
			return {...state, logDataList: {...state.logDataList, [action.payload.tpsId]: action.payload.data}}
		case TpsActionTypes.SET_ALARMS:
			if (action.payload.type === 'array') {
				return {...state, alarmsArr: {...action.payload.data}}
			} else if (action.payload.type === 'object') {
				return {...state, alarmsObj: {...action.payload.data}}
			}
			return state
		case TpsActionTypes.CLEAR_ALARMS:
			if (action.payload.type === 'array') {
				return {...state, alarmsArr: {}}
			} else if (action.payload.type === 'object') {
				return {...state, alarmsObj: {}}
			}
			return state
		case TpsActionTypes.SET_REFRESH_ICON:
			return {...state, isRefreshState: action.payload}
		default:
			return state
	}
}
