import {storageKey} from '../config/default';
import axios from 'axios';


export const getUserDataInHeadersForHttp = () => {
	let headers = {}
	try {
		const userData = JSON.parse(sessionStorage.getItem(storageKey))
		if (userData && userData?.token) {
			headers['Authorization'] = `Bearer ${userData.token}`
			headers['user-role'] = userData.role
		}
	} catch {}
	return headers
}

export const get = async (url, config = {}) => {
	const headers = getUserDataInHeadersForHttp()
	config.headers = {...config.headers, ...headers}
	return await axios.get(url, config)
}

export const post = async (url, body, config = {}) => {
	const headers = getUserDataInHeadersForHttp()
	config.headers = {...config.headers, ...headers}
	return await axios.post(url, body, config)
}
