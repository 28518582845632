import classes from './TextOnScheme.module.css'
import {availableTypes} from "../../../../utils/availableTypes";
import {round} from "../../../../utils/utils";


export class TextOnScheme {
	static CONTAINER = false
	static SIZE = false
	#fontSize = ''
	#text = ''
	#left = 0
	#top = 0
	#DOM = {}

	constructor(text, left, top, fontSize) {
		if (fontSize) {
			this.fontSize = fontSize
		}
		this.text = text
		this.left = left
		this.top = top
	}

	generate() {
		if (!TextOnScheme.SIZE || !TextOnScheme.CONTAINER) {
			throw new Error('Incorrect value SIZE or CONTAINER!')
		}
		const container = document.createElement('div')
		container.classList.add(classes.container)
		container.style.left = round(this.#left * 100 / TextOnScheme.SIZE.width, 3) + '%'
		container.style.top = round(this.#top * 100 / TextOnScheme.SIZE.height, 3) + '%'
		container.style.fontSize = this.#fontSize
		container.innerText = this.#text
		this.#DOM = container
		TextOnScheme.CONTAINER.insertAdjacentElement('beforeend', container)
	}

	set text(value) {
		if (!availableTypes(value, ['number', 'string'])) {
			throw new Error("Incorrect type for value 'text' value!")
		}
		return this.#text = value
	}

	set fontSize(value) {
		if (typeof value !== 'number') {
			throw new Error(`Incorrect type for value 'font size' in ${this.#text}!`)
		}
		if (value < 0) {
			throw new Error(`Incorrect 'font size' value in ${this.#text}!`)
		}
		return this.#fontSize = `${value}rem`
	}

	set left(value) {
		if (typeof value !== 'number') {
			throw new Error(`Incorrect type for value 'left' position in ${this.#text}!`)
		}
		if (value < 0) {
			throw new Error(`Incorrect 'left' position value - size < 0 in ${this.#text}!`)
		}
		return this.#left = value
	}

	set top(value) {
		if (typeof value !== 'number') {
			throw new Error(`Incorrect type for value 'top' position in ${this.#text}!`)
		}
		if (value < 0) {
			throw new Error(`Incorrect 'top' position value - size < 0 in ${this.#text}!`)
		}
		return this.#top = value
	}

	get DOMContainer() {
		return this.#DOM
	}
}
