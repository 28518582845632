import classes from "./DoubleContainer.module.css";
import {Container} from "../Container/Container";
import {round} from "../../../../utils/utils";
import {error} from "../../../../utils/utils";


export class DoubleContainer extends Container {
	static CONTAINER = null
	static SIZE = null
	#internalContainers = {left: null, right: null}
	#align

	constructor(id, left, top, height, width, position, align) {
		super(id, left, top, height, width, position)
		this.#setAlign(align ?? 'end')
	}

	generate() {
		if (!Container.SIZE || !Container.CONTAINER) {
			return error('Class has no size or container!')
		}
		const indentLeft = round(this.left * 100 / Container.SIZE.width, 3)
		const indentTop = round(this.top * 100 / Container.SIZE.height, 3)
		const height = round(this.height * 100 / Container.SIZE.height, 3)
		const width = round(this.width * 100 / Container.SIZE.width, 3)
		this.size = {heightInPercent: height, widthInPercent: width}
		const container = document.createElement('div')
		container.classList.add(classes.container)
		container.style.left = indentLeft + '%'
		container.style.top = indentTop + '%'
		container.style.height = height + '%'
		container.style.width = width + '%'
		container.style.alignItems = `flex-${this.#align}`
		Object.values(this.#internalContainers).forEach(item => {
			item.DOMContainer.style.position = 'static'
			item.DOMContainer.style.height = round(item.height * 100 / this.height, 3) + '%'
			item.DOMContainer.style.width = round(item.width * 100 / this.width, 3) + '%'
			container.insertAdjacentElement('beforeend', item.DOMContainer)
		})
		if (this.position === 'h') {
			container.classList.add(classes['horiz-cont'])
		}
		this.DOMContainer = container
		Container.CONTAINER.insertAdjacentElement('beforeend', container)
	}

	addContainer(value, side) {
		if (!this.#internalContainers.hasOwnProperty(side)) {
			return error('Invalid side!')
		}
		this.#internalContainers[side] = value
	}

	#setAlign(value) {
		if (value !== 'start' && value !== 'end') {
			return error('Invalid align value!')
		}
		this.#align = value
	}
}
