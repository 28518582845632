import React from 'react';
import {useSelector} from 'react-redux';
import classes from './TpsList.module.css';
import TpsButtonList from '../button-list/TpsButtonList';
import TpsButtonMenu from '../../dropdowns/tps-buttons-menu/TpsButtonMenu';


function TpsList() {
	const {list, activeTps} = useSelector(state => state.tps)
	return !list.length || (
		<>
			<div className={classes.container}>
				<TpsButtonList list={list} activeTps={activeTps}/>
			</div>
			<div className={classes.menu}>
				<TpsButtonMenu list={list} activeTps={activeTps}/>
			</div>
		</>
	)
}

export default TpsList;
