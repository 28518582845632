export function createSwitchName(text, DOMElement, isOnlySchemas = false) {
	if (!text) return
	const name = document.createElement('div')
	name.innerHTML = text
	name.classList.add('vert-name')
	if (isOnlySchemas) {
		name.classList.add('switch-name-only-scheme')
	} else {
		name.classList.add('switch-name')
	}
	DOMElement.insertAdjacentElement('beforeend', name)
	return name
}

export function createLineName(text, DOMElement) {
	if (!text) return
	const name = document.createElement('div')
	name.innerHTML = text
	name.classList.add('image-line-name', 'vert-name')
	DOMElement.insertAdjacentElement('beforeend', name)
	return name
}
