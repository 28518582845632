import React from 'react';
import classes from './LogsPage.module.css'
import LogsTable from '../../components/tables/logs-tables/LogsTable';


function LogsPage() {
	return (
		<div className={classes.container} data-cy='logs-cont'>
			<LogsTable/>
		</div>
	)
}

export default LogsPage;
