import backgroundImage from "../../assets/schemes/image-3.png";


const TPS3 = {
	id: 3,
	size: {width: 2891, height: 1774, minHeight: 800},
	image: backgroundImage,
	isExist: false,
	lines: [],
	switches: [],
	activeLines: []
}

export default TPS3
