import * as SidebarActionCreators from './other/sidebar';
import * as TpsActionCreators from './other/tps';
import * as ModalActionCreators from './other/homePage';
import * as ScadaActionCreators from './other/scada';
import * as AuthActionCreators from './other/auth';
import * as ProtectionModeActionCreators from './other/protectionMode';


const Actions = {
	...SidebarActionCreators,
	...TpsActionCreators,
	...ModalActionCreators,
	...ScadaActionCreators,
	...AuthActionCreators,
	...ProtectionModeActionCreators,
}

export default Actions
