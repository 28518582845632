export const tpsAllDevices1 = {
  switchData: {},
  iconData: {},
  logData: [],
}

export const tpsAllDevices2 = {
  switchData: {},
  iconData: {},
  logData: [],
}