import {useSelector} from "react-redux";
import {Typography} from "@mui/material";
import classes from "../components/scada/Scada.module.css";
import MySelect from "../components/UI/select/MySelect";
import {useActions} from "./useActions";
import {useRef} from "react";
import {useModal} from "./useModal";


export const useProtectionMode = () => {
	const modal = useModal()
	modal.title = 'Режим охорони'
	const {stateList, alarmStateList, errorList} = useSelector(store => store.protectionMode)
	const {activeTps} = useSelector(store => store.tps)
	const protModeState = stateList[activeTps]
	const protModeAlarmState = alarmStateList[activeTps]
	const error = errorList[activeTps]
	const {setProtectionModeStateToInactive, setProtectionModeStateToActive} = useActions()
	const selectState = useRef()
	const defOptions = [
		{value: true, title: 'Включити'},
		{value: false, title: 'Відключити'},
	]

	function selectHandler(event) {
		selectState.current = event.target.value
	}

	async function confirmState() {
		if (protModeState === selectState.current) {
			return
		}
		if (selectState.current) {
			await setProtectionModeStateToActive(activeTps)
		} else {
			await setProtectionModeStateToInactive(activeTps)
		}
	}

	function protModeHandler() {
		modal.content = error || (
			<>
				<Typography variant={"h6"} sx={{marginBottom: '1rem'}}>
					Статус: {protModeState ? 'Працює' : 'Вимкнено'}
				</Typography>
				<MySelect
					className={classes.select}
					defaultValue={protModeState}
					options={defOptions}
					onChange={selectHandler}
				/>
			</>
		)
		modal.confirm = confirmState
		modal.open()
	}

	return {protModeModal: modal, protModeHandler, protModeState, protModeAlarmState}
}
